import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { ReactSVG } from 'react-svg';
import reportIcon from './assets/report.svg';
import BandMediaHelpModal from '../BandMediaHelpModal/BandMediaHelpModal';
import BandMediaAPI from '../../api/BandMediaAPI';
import ToastMessage from '../ToastMessage/ToastMessage';
import contacts from '../../consts/contacts';
import './Slides.scss';

const SlideImageItem = ({items, containerClass, grownUpId, kidId}) => {
  const [reportModelOpen, setReportModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toastSuccess, setToastSuccess] = useState(false);
  const [displayToastMessage, setDisplayToastMessage] = useState(null);

  const keyId = uuidv4();
  const imageItem = items.filter((item) => Object.keys(item).includes('image_src'))[0];
  const imageCaption = items.filter((item) => Object.keys(item).includes('body-strong'))[0];
  const report_item = items.filter((item) => Object.keys(item).includes('report'))[0];
  const { report } = report_item;
 
  // REPORT OBJECT (layout required for api)
  const reportConfig = (comment) => {
    const config = {
        data: {
          band_media_discrepancy_report: {
            comment,
            band_media_submission_id: report,
            kid_id: kidId,
            grown_up_id: grownUpId
         }
       }
    };
    return config;
  }

  // UPDATE MESSAGE FROM REPORT
  const setToastType = (result) => {
    setDisplayToastMessage(true);
    setToastSuccess(result);
  }

  // CLOSE REPORT MODEL
  const resetModal = () => {
    setIsLoading(false);
    setReportModelOpen(false)
  }

  // TRIGGER REPORT API
  const reportMedia = (comment) => {
    const config = reportConfig(comment);
    BandMediaAPI.reportMediaDiscrepancy(config).then(() => {
      setToastType(true);
    }).catch(() => setToastType(false)).finally(() => resetModal());
  }

  // OPEN REPORT MODEL
  const triggerReport = () => {
    setReportModelOpen(true)
  }

  const renderToastMessage = () => {
    if (!displayToastMessage) { return null }
    let content = (
      <ToastMessage
        title="Something went wrong"
        body={`Please try again in a moment or contact us by phone on: ${contacts.SUPPORT_TEL_HUMANIZED}`}
        iconName="exclamation"
        iconColor="#942d29"
        backgroundColor="#ff8985"
        textColor="#521715"
      />
    )

    if (toastSuccess) {
      content = <ToastMessage title="Thanks for reporting" body="A member of staff will review your report shortly." />
    } 
    return (
      <div className={`${containerClass}__reportMessage`}>{content}</div>
    )
  }

  const renderImage = () => {
    if (!imageItem.image_src) return null;
    return (
      <div key={keyId} className={`${containerClass}__kidImage`}>
        <div className={`${containerClass}__kidImage__imageWrapper`}>
          <img className={`${containerClass}__kidImage__image`} src={imageItem.image_src} alt='slide_image' />
          <p className={`${containerClass}__kidImage__caption`}>{imageCaption && imageCaption['body-strong'] || ''}</p>
        </div>
        <button className={`${containerClass}__kidImage__button`} type='button' onClick={triggerReport}>
          <ReactSVG src={reportIcon} />
        </button>
      </div>
    )
  }

  return (
    <>
      {!toastSuccess && !displayToastMessage && renderImage()}
      <BandMediaHelpModal
        displayModal={reportModelOpen}
        closeModal={() => setReportModelOpen(false)}
        onReport={reportMedia}
        isLoading={isLoading}
        setLoadingState={setIsLoading}
      />
      {renderToastMessage()}
    </>
  )
}

export default SlideImageItem;

SlideImageItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired, 
  containerClass: PropTypes.string.isRequired,
  grownUpId: PropTypes.string.isRequired,
  kidId: PropTypes.string.isRequired
}
