import React from 'react';
import { Container } from 'semantic-ui-react';
import logo from '../../images/rocksteady_logo_500.png';
import SiteFooter from '../SiteFooter';
import SchoolSignInForm from './SchoolSignInForm';
import SchoolGetPassword from './SchoolGetPassword';
import SchoolSetPassword from './SchoolSetPassword';
import JWTManager from '../../utils/JWTManager';
import WindowUtils from '../../utils/WindowUtils';
import paths from '../../consts/paths';


// I think we've removed these schools user accounts from Abbey Road now,
// and if not, we should be in the near future.
// All schools login should be via the schools portal.
// These logins to Parent portal for Schools was a covid era hack
// that we should be deleting as soon as reasonably possible
class SchoolLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
  }

  initState = () => ({
    authorizationToken: JWTManager.getSchoolsToken() || null,
    signedIn: false
  });

  hasAuthenticated = () => {
    const { authorizationToken } = this.state;
    return authorizationToken !== null;
  };

  setJwtToken = (token) => {
    JWTManager.setSchoolsToken(token).then(
      this.setState({
        authorizationToken: token,
      }),
    );

    WindowUtils.replaceLocation(paths.SCHOOLS_ROOT);
  };

  signInForm = () => <SchoolSignInForm signedIn={false} onSigningIn={this.setJwtToken} />;

  // Attempting to setState explicitly here produces console warnings, but we don't need it,
  // because the state is reset automagically,
  // For the benefit of future maintainers, in effect this is where signedIn is set to false
  signOut = () => {
    JWTManager.removeSchoolsToken();
    localStorage.clear();

    WindowUtils.replaceLocation(paths.SCHOOLS_SIGN_IN);
  };

  setPassword = () => (
    <SchoolSetPassword onSigningIn={this.setJwtToken} signedIn={this.hasAuthenticated()} />
  );

  adminPage = (path) => {
    switch(path) {
      case paths.SCHOOLS_SIGN_IN:
        return this.signInForm();
      case paths.SCHOOLS_SIGN_OUT:
        this.signOut()
        return this.signInForm();
      case paths.SCHOOLS_FORGOTTEN_PASSWORD:
        return <SchoolGetPassword forgottenPassword />;
      case paths.SCHOOLS_SET_PASSWORD:
          return this.setPassword();
      default:
        return null;
    }
  }

  pageContents = () => {
    return (
      this.adminPage(window.location.pathname)
    )
  }

  header = () => (
    <div className='header-wrapper'>
      <Container className="app">
        <div className="site-header">
          <img src={logo} className="logo" alt="logo" />
          <div className="header-title">
            <div className="title-links">
              <a target='_blank' rel='noopener noreferrer' href="https://www.rocksteadymusicschool.com">
                Main Website
              </a>
            </div>
            <h4>Parent Portal</h4>
          </div>
        </div>
      </Container>
    </div>
  );

  render() {
    return (
      <>
        {this.header()}
        <Container className="site-container admin-app">
          {this.pageContents()}
        </Container>
        <SiteFooter />
      </>
    );
  }
}

export default SchoolLogin;
