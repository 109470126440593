import React, { Component } from 'react';
import { Segment, List, Icon } from 'semantic-ui-react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import paths from '../../consts/paths';
import KidAPI from '../../api/KidAPI';
import PageHeading from '../PageHeading/PageHeading';
import WindowUtils from '../../utils/WindowUtils';

class TimetableView extends Component {
  static propTypes = {
    attributes: PropTypes.object.isRequired,
    kidId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      timetable_data: []
    };
  }

  componentDidMount() {
    this.getRehearsalsAllDayEvents();
    WindowUtils.scrollToTop();
  }

  getRehearsalsAllDayEvents = () => {
    const { kidId } = this.props;
    KidAPI.getRehearsalsAllDayEvents(kidId).then(response => {
      const arr = this.orderObjects(response.data);
      this.setState({ timetable_data: arr });
    });
  };

  orderObjects = response => {
    response.sort(
      (a, b) =>
        new Moment(a.date).format('YYYYMMDD') -
        new Moment(b.date).format('YYYYMMDD')
    );
    return response;
  };

  rehearsalIcon = () => (
    <List.Icon
      name="music"
      size="large"
      verticalAlign="middle"
    />
  )

  allDayEventIcon = (isConcert) => (
    <List.Icon
      name={isConcert ? 'volume up' : 'info circle'}
      size="large"
      verticalAlign="middle"
    />
  )

  listItemIcon = (item) => (
    item.type === 'rehearsal' ? this.rehearsalIcon() : this.allDayEventIcon(item.concert)
  );

  listItemDateAndTime = (item) => (
    <p>
      {`${Moment(item.date).format('ddd DD MMMM YYYY')} ${item.time ? `- ${item.time}` : ''}`}
    </p>
  )

  rehearsalNote = () => (
    <p className="noteHeading">
      Lesson
    </p>
  )

  parentalAttendanceIndicator = (item) => {
    if (['school_only', 'concert_video'].includes(item.event_type)) {
      return (
        <p style={{ marginTop: 10 }}>
          <Icon name='close' color='red' />
          Parents cannot attend.
        </p>
      )
    }

    return (
      <p style={{ marginTop: 10 }}>
        <Icon name='checkmark' color='green' />
        Parents can attend.
      </p>
    );
  }

  concertNote = (item) => (
    <div>
      <p className="noteHeading" style={{ marginBottom: 0 }}>
        {item.event_type === 'concert_video' ? 'Concert Video Session' : 'Concert'}
      </p>
      {item.note}
      {this.parentalAttendanceIndicator(item)}
    </div>
  )

  allDayEventNote = (item) => {
    if (item.concert) { return this.concertNote(item) }
    return <p className="noteHeading">{item.note}</p>
  };

  listItemNote = (item) => (
    item.type === 'rehearsal' ? this.rehearsalNote() : this.allDayEventNote(item)
  )

  render() {
    const { timetable_data } = this.state;
    const { attributes } = this.props;
    return (
      <div className="page-container">
        <PageHeading
          heading={`Timetable for ${attributes.name}`}
          subHeading={(
            <span>
              Please
              <a
                href={paths.SUPPORT_REQUEST}
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                contact us
              </a>
              {' '}
              if you notice any errors in school holiday dates
            </span>
)}
        />
        <Segment>
          <List divided relaxed>
            {!timetable_data.length
              ? 'We have no Current Timetable Information for you'
              : timetable_data.map((item, index) => (
                <List.Item key={`tt${index + 1}`}>
                  {this.listItemIcon(item)}
                  <List.Content>
                    <List.Item>
                      {this.listItemDateAndTime(item)}
                    </List.Item>
                    <List.Item>
                      {this.listItemNote(item)}
                    </List.Item>
                  </List.Content>
                </List.Item>
                ))}
          </List>
        </Segment>
      </div>
    );
  }
}

export default TimetableView;
