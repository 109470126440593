import React from 'react';
import MediaQuery from 'react-responsive';
import { Message, Grid } from 'semantic-ui-react';
import paths from '../../../consts/paths';

const SignInPageMessage = () => (
  <>
    <Message className='padded'>
      <Message.Content>
        <Grid>
          <MediaQuery minWidth={769}>
            <Grid.Row columns={1}>
              <Grid.Column><h3>New to Rocksteady?</h3></Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <p>
                  <em>Backstage</em>
                  {' '}
                  is our Parent Portal for existing customers.
                  If this is the first time you’ve contacted us,
                  {' '}
                  <a
                    href="https://booking.rocksteadymusicschool.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    enrol your child
                  </a>
                  .
                </p>
              </Grid.Column>
              <Grid.Column>
                <a
                  className="ui blue button fluid"
                  href="https://booking.rocksteadymusicschool.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Enrol your Child
                </a>
              </Grid.Column>
            </Grid.Row>
          </MediaQuery>
          <Grid.Row columns={1}>
            <Grid.Column><h3>New to Backstage?</h3></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <p>
                If you are
                {' '}
                <em>enrolled</em>
                {' '}
                with us, have a
                {' '}
                <em>reserved</em>
                {' '}
                place
                or are on our
                {' '}
                <em>waiting list</em>
                , please
                {' '}
                <a
                  href={paths.SIGN_UP}
                >
                  create an account
                </a>
                .
              </p>
            </Grid.Column>
            <Grid.Column>
              <a href={paths.SIGN_UP} className="ui blue button fluid">
                Create an Account
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <h3>Accepting a Reserved Place?</h3>
        <p>
          If you already have a Backstage account,
          {' '}
          <strong>Sign In</strong>
          {' '}
          and complete the things that need your attention shown on the screen.
          <br />
          <br />
          If you are new to Backstage, please create an account first, using the
          {' '}
          <strong>&lsquo;Create an Account&lsquo;</strong>
          {' '}
          button above.
          <br />
          Then,
          {' '}
          <strong>Sign In</strong>
          {' '}
          and complete the things that need your attention shown on the screen.
        </p>

      </Message.Content>
    </Message>
  </>
);

export default SignInPageMessage;
