import React from 'react';
import { Form, Radio, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const MarketingField = (props) => {
  const { value, handleChange } = props;

  const valueNotSet = () => !value || value === 'not_set';

  const marketingPreferenceRequiredMessage = () => valueNotSet() && (
  <Message negative>
    <p>
      <strong>Required.</strong>
      {' '}
      Please let us know if we can keep in touch
    </p>
  </Message>
  );

  return (
    <div className="marketing-preferences">
      <p className="pseudo-label">
        Can we keep in touch?
        {' '}
        <a
          href="https://www.rocksteadymusicschool.com/terms-conditions/marketing-preferences-terms-conditions/"
          target="_blank"
          rel="noopener noreferrer"
          className="button ui right floated mini compact"
        >
          Terms
        </a>
      </p>
      {marketingPreferenceRequiredMessage()}
      <Form.Field required inline className="checkbox preference" key="comms_marketing_yes">
        <Radio
          label="Yes, please keep me informed about Rocksteady products such as holiday camps and events"
          name="comms_marketing"
          checked={value === 'yes'}
          onChange={handleChange}
          value="yes"
        />
      </Form.Field>
      <Form.Field required inline className="checkbox preference" key="comms_marketing_no">
        <Radio
          label="No, please do not let me know about Rocksteady products such as holiday camps and events"
          name="comms_marketing"
          checked={value === 'no'}
          onChange={handleChange}
          value="no"
        />
      </Form.Field>
    </div>
  );
};

MarketingField.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

MarketingField.defaultProps = {
  value: null,
};

export default MarketingField;
