/* eslint-disable react/no-danger */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Message, Image, Label } from 'semantic-ui-react';
import MediaContentCard from '../../MediaContentCard/MediaContentCard';
import LessonHelper from '../../../data/lessons/LessonHelper';
import DefaultTimelineImage from './default_timeline_image.png';
import ReactionIcon from './ReactionIcon';
import BandMediaHelpModal from '../../BandMediaHelpModal/BandMediaHelpModal';
import BandMediaAPI from '../../../api/BandMediaAPI';
import './TimelineEntry.css';
import playIcon from '../../../images/portal/play-icon.svg';
import TimelineAPI from '../../../api/TimelineAPI';
import paths from '../../../consts/paths';

const TimelineEntry = ({
  entry,
  borderColor,
  onThumbnailClick,
  kid,
  setToastType,
  fetchGrownUpRelationships
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [reactionPanelOpen, setReactionPanelOpen] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(
    entry.attributes.reaction === 'not_set' ? null : entry.attributes.reaction
  );
  const [isLoading, setIsLoading] = useState(false);
  const { category, created_at, vimeo_id, timeline_image_url, band_media_photo_url, video_title, body  } = entry.attributes;

  const showMediaDisclaimerOn = ['Round-up', 'Concert video published', 'Media'];
  const disclaimer = `Photos and videos of your child's band are stored safely and securely in your Backstage account for you and your child to enjoy. Please respect other children's privacy by not sharing anywhere else.`
  const showDisclaimer = category && showMediaDisclaimerOn.includes(category);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const resetModal = () => {
    setIsLoading(false);
    setModalOpen(false);
  }

  const reportConfig = (comment) => {
    const { attributes: { band_media_submission_id } } = entry;
    const { relationships: { grown_up: { data } } } = kid;

    const config = {
        data: {
          band_media_discrepancy_report: {
            comment,
            band_media_submission_id,
            kid_id: kid.id,
            grown_up_id: data.id
         }
       }
    };

    return config;
  }

  const reportMedia = (comment) => {
    const config = reportConfig(comment);
    BandMediaAPI.reportMediaDiscrepancy(config).then(() => {
      setToastType(true);
    }).catch(() => setToastType(false)).finally(() => resetModal());
  }

  const videoThumbnailUrl = (id) => `${LessonHelper.getThumbnailBaseUrl()}${id}.jpg`;

  const setDefaultTimelineImage = (e) => { e.target.src = DefaultTimelineImage; };

  const timelineVideoThumbnail = () => {
    const src = videoThumbnailUrl(vimeo_id);
    return (
      <div>
        <div className="child-video-entry-wrapper">
          <MediaContentCard imageSource={src} onClick={() => onThumbnailClick(entry)} />
          <p style={{ textAlign: 'center', color: '#0072CE' }}>
            {video_title}
          </p>
        </div>
      </div>
    );
  };

  const timelineImage = (linkHref, withPlayOverlay) => {
    const image = <Image src={timeline_image_url} alt="Timeline image" onError={setDefaultTimelineImage} />;
    let inner = image;
    let playOverlay;

    if (withPlayOverlay) { playOverlay = <Image src={playIcon} className="play-icon" />; }

    if (linkHref) {
      inner = (
        <a href={linkHref} className="round-up-poster-image">
          {image}
          {playOverlay}
        </a>
      );
    }

    return (
      <div>
        <div className="child-image-entry-wrapper">
          {inner}
        </div>
      </div>
    );
  }

  const bandMediaPhoto = () => (
    <div>
      <div className="child-image-entry-wrapper">
        <Image
          src={band_media_photo_url}
          alt="Timeline image"
          onError={setDefaultTimelineImage}
        />
      </div>
    </div>
  );

  const timelineMedia = () => {
    if (vimeo_id) { return (timelineVideoThumbnail()); }

    if (timeline_image_url) {
      const isRoundUp = category === 'Round-up';
      return (timelineImage(isRoundUp && paths.ROUND_UP(kid.id), isRoundUp));
    }
    if (band_media_photo_url) { return (bandMediaPhoto()); }
    return null;
  };

  const mediaAfterText = () => (
    <Grid.Column>
      <span dangerouslySetInnerHTML={{ __html: body.replace('INSERT_VIDEO', '') }} />
      {timelineMedia()}
    </Grid.Column>
  )

  const mediaBeforeText = () => (
    <Grid.Column>
      {timelineMedia()}
      <span dangerouslySetInnerHTML={{ __html: body.replace('INSERT_VIDEO', '') }} />
    </Grid.Column>
  )

  const mediaBetweenText = () => {
    const parts = body.split('INSERT_VIDEO');
    return (
      <Grid.Column>
        <span dangerouslySetInnerHTML={{ __html: parts[0] }} />
        {timelineMedia()}
        <span dangerouslySetInnerHTML={{ __html: parts[1] }} />
      </Grid.Column>
    );
  }

  const derivedLayout = () => {
    if (body.endsWith('INSERT_VIDEO')) {
      return mediaAfterText();
    }
    if (body.startsWith('INSERT_VIDEO')) {
      return mediaBeforeText();
    }
    return mediaBetweenText();
  }

  const generateEntry = () => {
    if (body.indexOf('INSERT_VIDEO') !== -1) {
      return derivedLayout();
    }
    return mediaAfterText();
  };

  const onReactionClick = name => {
    // Optimistically update the UI
    setSelectedReaction(name === selectedReaction ? null : name);
    setReactionPanelOpen(false);

    // Update the entry in the DB via the API and refetch the kid data
    TimelineAPI.updateTimelineEntryReaction(entry.id, {
      timeline_entry: {
        reaction: name === selectedReaction ? 'not_set' : name
      }
    }).then(() => fetchGrownUpRelationships()).catch(() =>{});
  };

  const reactionPanel = () => (
    <div className={`reaction-panel ${reactionPanelOpen ? 'show' : ''}`}>
      {['thumbs_up', 'heart', 'grin_hearts', 'grin_squint', 'hand_horns'].map(
        name => (
          <ReactionIcon
            key={name}
            name={name}
            onPress={() => onReactionClick(name)}
          />
        )
      )}
    </div>
  );

  const toggleReactionPanel = () => {
    setReactionPanelOpen(!reactionPanelOpen);
  };

  const reactionPanelBtn = () => (
    <button
      className="reaction-panel-button"
      type="button"
      onClick={toggleReactionPanel}
      style={{
        backgroundColor:
          reactionPanelOpen || selectedReaction ? '#0072ce' : 'white'
      }}
    >
      <ReactionIcon
        selected={selectedReaction ? true : reactionPanelOpen}
        name={selectedReaction || 'emoji'}
      />
    </button>
  );

  const mediaOptionButtons = () => {
    const { reactionable, band_media_submission_id } = entry.attributes;

    if (reactionable || band_media_submission_id) {
      return (
        <Fragment>
          {reactionable && (
            <Grid.Column width={13}>
              {reactionPanel()}
              {reactionPanelBtn()}
            </Grid.Column>
          )}
          {band_media_submission_id && (
            <Grid.Column width={3} textAlign='right'>
              <button
                className="help-button"
                type="button"
                onClick={openModal}
              >
                Help
              </button>
              <BandMediaHelpModal
                displayModal={modalOpen}
                closeModal={closeModal}
                onReport={reportMedia}
                isLoading={isLoading}
                setLoadingState={setIsLoading}
              />
            </Grid.Column>
          )}
        </Fragment>
      )
    }

    return null;
  }

  return (
    <Message className="child-entry">
      <Message.Content>
        <Grid padded>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={8}>
              <Label className="label" style={{ borderColor }}>
                {category}
              </Label>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right" style={{ color: 'grey' }}>
              {created_at}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {generateEntry(entry)}
          </Grid.Row>
          {showDisclaimer && (
            <Grid.Row>
              <Message negative>
                <Message.Content>{disclaimer}</Message.Content>
              </Message>
            </Grid.Row>
          )}
          <Grid.Row style={{ padding: 0 }} verticalAlign="middle">
            {mediaOptionButtons()}
          </Grid.Row>
        </Grid>
      </Message.Content>
    </Message>
  );
}

TimelineEntry.propTypes = {
  entry: PropTypes.object.isRequired,
  onThumbnailClick: PropTypes.func.isRequired,
  borderColor: PropTypes.string,
  kid: PropTypes.object.isRequired,
  setToastType: PropTypes.func.isRequired,
  fetchGrownUpRelationships: PropTypes.func.isRequired
};

TimelineEntry.defaultProps = {
  borderColor: null,
}

export default TimelineEntry;
