import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class SchoolAPI {
  getInstrumentChangeData = (params) => AuthenticatedHTTPWrapper.get({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools/instrument_change_data.json`,
    config: { params },
  });
}

export default new SchoolAPI();
