/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Form, Header, Message, Search, Segment } from 'semantic-ui-react';
import AddressAPI from '../../api/AddressAPI';

// styles
import './autocomplete-address-form.scss';

// This component expects addressProps as an array of objects for each address field/input,
// with each object requiring a value (string) and onChange (callback-func).
// It expects 5 fields to cater for 2 'initial' lines of an address, a town/city, county
// and postcode.
// It will call the onAddressSelect callback with the 5 fields.

class AutocompleteAddressForm extends Component {
  static propTypes = {
    addressProps: PropTypes.array.isRequired,
    onAddressSelect: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    this.API_BASE_URL = process.env.REACT_APP_ADDRESS_API_BASE_URL;
    this.API_TOKEN = process.env.REACT_APP_ADDRESS_API_TOKEN;
    this.MIN_SEARCH_CHARS = 4;
    this.FIELD_PLACEHOLDERS = [
      'Address Line 1',
      'Address Line 2',
      'Town or City',
      'County',
      'Postcode',
    ];
    this.state = {
      editingManually: false,
      addressSuggestions: [],
      addressFetchError: false,
      fetchingAddress: false,
      // We define and set a key on the Semantic UI search component so that,
      // when a result is selected, we can change the key, which will cause
      // a rerender and subsequent 'blurring' of the field for better UX.
      searchInputKey: 0,
      searchTerm: '',
    };
  }

  componentDidMount() {
    if (!this.API_BASE_URL || !this.API_TOKEN) this.enterManualEdit();
  }

  componentDidUpdate(_prevProps, prevState) {
    const { searchTerm } = this.state;
    const searchTermChanged = prevState.searchTerm !== searchTerm;
    if (searchTermChanged && this.minSearchCharsExist()) this.getSuggestions();

  }

  onErrorReceived = () => {
    this.setState({ addressFetchError: true, searchTerm: '', addressSuggestions: [] });
  }

  handleErrorDismiss = () => this.setState({ addressFetchError: false });

  handleSearchChange = (_event, { value }) => this.setState({ searchTerm: value });

  setFetchingResults = val => this.setState({ fetchingResults: val });

  setFetchingAddress = val => this.setState({ fetchingAddress: val });

  someFieldsHaveErrors = () => {
    const { addressProps } = this.props;
    return addressProps.some(({ error }) => error);
  }

  getSuggestions = () => {
    this.setFetchingResults(true);
    const { searchTerm } = this.state;
    AddressAPI.autoComplete(this.API_BASE_URL, searchTerm, this.API_TOKEN)
      .then(({ data }) => {
        const formattedSuggestions = this.formatSuggestions(data.suggestions);
        this.setState({ addressSuggestions: formattedSuggestions });
      })
      .catch(() => this.onErrorReceived())
      .finally(() => this.setFetchingResults(false));
  };

  getAddressFromId = (id) => {
    this.setFetchingAddress(true);
    AddressAPI.getByID(this.API_BASE_URL, id, this.API_TOKEN)
      .then(({ data }) => this.handleSettingFullAddress(data))
      .catch(() => this.onErrorReceived())
      .finally(() => this.setFetchingAddress(false));
  };

  formatSuggestions = suggestions =>
    suggestions.map(({ id, address }) => ({ id, title: address }));

  enterManualEdit = () => {
    this.setState({ editingManually: true, searchTerm: '', addressFetchError: false });
  };

  exitManualEdit = () => this.setState({ editingManually: false });

  minSearchCharsExist = () => {
    const { searchTerm } = this.state;
    return searchTerm.length >= this.MIN_SEARCH_CHARS;
  };

  handleSettingFullAddress = ({
    line_1, line_2, locality, town_or_city, county, postcode,
  }) => {
    const { onAddressSelect } = this.props;
    const { editingManually } = this.state;
    let consideredLine2;
    if (line_2) {
      consideredLine2 = line_2;
    } else if (locality) {
      consideredLine2 = locality;
    }
    onAddressSelect(line_1, consideredLine2, town_or_city, county, postcode);
    if (editingManually) this.exitManualEdit();
  };

  handleSelectedAddress = (_event, { result }) => {
    this.setState((prevState) => ({
      searchTerm: '',
      searchInputKey: prevState.searchInputKey + 1,
      addressSuggestions: [],
    }));
    this.getAddressFromId(result.id);
  };

  renderErrorMessage = () => (
    <Message info onDismiss={this.handleErrorDismiss} size="mini">
      An error has occurred preventing address searching.
      Please dismiss this error and try again, or edit manually.
    </Message>
  );

  renderAddressLineInputs = () => {
    const { addressProps } = this.props;
    const { editingManually } = this.state;
    return addressProps.map(({ value, onChange, error = false }, i) => (
      <Form.Field key={`address-field${i + 1}`}>
        <Form.Input
          fluid
          value={value || ''}
          onChange={onChange}
          error={editingManually && error}
          placeholder={this.FIELD_PLACEHOLDERS[i]}
          disabled={!editingManually}
        />
      </Form.Field>
    ));
  };

  renderSearchInput = () => {
    const {
      addressFetchError,
      addressSuggestions,
      editingManually,
      fetchingAddress,
      fetchingResults,
      searchInputKey,
      searchTerm,
    } = this.state;

    // We want to show address errors on the search field, rather than individual fields, when an error exists,
    // and we are NOT editing the fields manually. This is because the fields are disabled and have opacity,
    // so the red state is not clear, plus we want to encourage the users to use the search field to correct
    // address problems.
    const displayGlobalFieldError = this.someFieldsHaveErrors() && !editingManually && searchTerm.length === 0;

    return (
      <Form.Field error={displayGlobalFieldError}>
        <label htmlFor="address-search">Address</label>
        <Search
          id="address-search"
          key={searchInputKey}
          onFocus={this.exitManualEdit}
          fluid
          placeholder="Type the address/postcode to search..."
          value={searchTerm}
          onSearchChange={this.handleSearchChange}
          disabled={fetchingAddress || addressFetchError}
          minCharacters={this.MIN_SEARCH_CHARS}
          showNoResults={this.minSearchCharsExist()}
          loading={fetchingResults}
          results={addressSuggestions}
          onResultSelect={this.handleSelectedAddress}
          noResultsMessage="No addresses found."
        />
        <div>
          <p
            onClick={this.enterManualEdit}
            aria-hidden="true"
            className={`false-link${editingManually ? ' disabled' : ''}`}
          >
            <small>Edit Manually</small>
          </p>
        </div>
      </Form.Field>
    );
  }

  render() {
    const { addressFetchError, fetchingAddress } = this.state;

    return (
      <Segment
        id="address-autocomplete"
        loading={fetchingAddress}
      >
        {addressFetchError && this.renderErrorMessage()}
        {!!this.API_BASE_URL && !!this.API_TOKEN && this.renderSearchInput()}
        {(!this.API_BASE_URL || !this.API_TOKEN) && <Header as="h5" content="Address" />}
        {this.renderAddressLineInputs()}
      </Segment>
    );
  }
}

export default AutocompleteAddressForm;
