import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import './index.scss';
import BugsnagClient from './utils/BugsnagClient';
import AppView from './views/AppView';
import SomethingWentWrong from './components/SomethingWentWrong/SomethingWentWrong';
import CookiesUtils from './utils/CookiesUtils';

const ErrorBoundary = BugsnagClient.getPlugin('react');

// We don't use GA anymore, make sure we purge any leftover cookies
// when the user loads the page.
CookiesUtils.removeExistingGACookies();

ReactDOM.render(
  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
    <BrowserRouter>
      <AppView />
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById('root'),
);
