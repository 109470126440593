import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';

class RatingButtons extends Component {
  static propTypes = {
    rate: PropTypes.number,
    onSelectRate: PropTypes.func,
  };

  static defaultProps = {
    rate: null,
    onSelectRate: () => null,
  };

  buttonsValues = () => [1, 2, 3, 4, 5].map(i => ({ value: i }));

  render() {
    const { onSelectRate, rate } = this.props;
    return this.buttonsValues().map(button => (
      <span key={button.value} className="rating-button">
        <Radio
          label={button.value}
          disabled={button.disabled}
          onChange={() => onSelectRate(button.value)}
          checked={button.value === rate}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    ));
  }
}
export default RatingButtons;
