import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Redirect } from 'react-router-dom';
import paths from '../../consts/paths';

// This component acts as a wrapper around any kids app 'feature flag' dependent components,
// e.g. <RequireKidsAppPromoLive><PromoPage /></RequireKidsAppPromoLive>
// If the feature flag is false/falsy, we either redirect to the app root (if the user is attempting
// to view a kid's app related page), else simply return null.
//
export default function RequireKidsAppPromoLive({ children }) {
  const { pathname } = useLocation();

  if (process.env.REACT_APP_KIDS_APP_PROMO_LIVE !== '1') {
    if (pathname.startsWith(paths.KIDS_APP)) return <Redirect to={paths.ROOT} />;

    return null;
  }

  return children;
};

RequireKidsAppPromoLive.propTypes = {
  children: PropTypes.node.isRequired,
};
