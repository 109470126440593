import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class BandMediaAPI {
  reportMediaDiscrepancy = config => AuthenticatedHTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/band_media_discrepancy_reports.json`,
    config
  })
}

export default new BandMediaAPI();
