import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import paths from '../../consts/paths';
import StringUtils from '../../utils/StringUtils';
import WindowUtils from '../../utils/WindowUtils';
import KidAPI from '../../api/KidAPI';
import GrownUpAPI from '../../api/GrownUpAPI';
import GrownUpForm from '../GrownUpForm';
import KidRegistration from '../KidRegistration/KidRegistration';
import EnrolReservedKid from '../EnrolReservedKid';
import InstrumentChange from '../InstrumentChange';
import AvailableInstrumentChanges from '../AvailableInstrumentChanges';
import InstrumentDealsRentals from '../InstrumentDealsRentals';
import Settings from '../Settings';
import DirectDebits from '../DirectDebits/DirectDebits';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import FAQ from '../FAQ/FAQ';
import Welcome from '../Welcome';
import KidForm from '../KidForm/KidForm';
import TimetableView from '../TimetableView';
import BandLeaderProfile from '../BandLeaderProfile/BandLeaderProfile';
import CancellationForm from '../CancellationForm/CancellationForm';
import SongView from '../Song/SongView';
import NPS from '../Nps/Nps';
import NavMenu from '../NavMenu';
import CookieConsentModal from '../CookieConsentModal';
import EnrolmentStatus from '../EnrolmentStatus/EnrolmentStatus';
import Timeline from '../Timeline';
import KidRegistrationProvider from '../../contexts/KidRegistrationProvider';
import KidsAppPromo from '../KidsAppPromo/KidsAppPromo';
import KidsAppSubscribe from '../KidsAppSubscribe/KidsAppSubscribe';

class Home extends Component {
  static propTypes = {
    refreshGrownUp: PropTypes.func.isRequired,
    grownUpAttributes: PropTypes.object.isRequired,
    grownUpId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingMinimumData: true,
      showNPSThanksMessage: false,
      kids: [],
      reservedKids: [],
      slots: [],
      timetables: [],
      approvedInstrumentChanges: [],
      unapprovedInstrumentChanges: [],
    };
  }

  componentDidMount() {
    this.fetchGrownUpRelationships();
  }

  updateData = () => {
    this.fetchGrownUpRelationships();
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  setShowNPSThanksMessage = value => {
    this.setState({ showNPSThanksMessage: value });
  };

  requestSlots = kids => kids.map(kid => KidAPI.getSlots(kid.id));

  processKids = kids => {
    const reservedKids = [];
    const enrolledKids = [];

    kids.forEach(kid => {
      if (kid.attributes.status === "reserved") {
        reservedKids.push(kid)
      }

      if (kid.attributes.is_enrolled) {
        enrolledKids.push(kid);
      }
    })

    this.setState({reservedKids })

    return [...enrolledKids, ...reservedKids];
  }

  processTimetables = includedData => {
    const timetables = []

    includedData.forEach(item => {
      if (item.type === "timetable")
      timetables.push(item)
    })

    this.setState({timetables})
  }

  processSlots = slotData => {
    const approvedInstrumentChanges = [];
    const unapprovedInstrumentChanges = [];
    const slots = [];

    slotData.forEach(jsonSlot => {
      jsonSlot.data.data.forEach(slot => {
        if (slot.attributes.change_is_pending_approval) {
          unapprovedInstrumentChanges.push(slot)
        }
        if (slot.attributes.is_delayed_instrument_change) {
          approvedInstrumentChanges.push(slot)
        }

        slots.push(slot)
      })
    })

    this.setState({ slots, unapprovedInstrumentChanges, approvedInstrumentChanges })
  }

  fetchGrownUpRelationships = async () => {
    const {grownUpId, refreshGrownUp} = this.props;

    try {
      refreshGrownUp()
      const kidResponse = await GrownUpAPI.fetchKidsForGrownUp(grownUpId);
      const kids = kidResponse.data.data;
      const includedTimetableData = kidResponse.data.included;

      const enrolledAndReservedKids = this.processKids(kids)
      const slotData = await Promise.all(this.requestSlots(enrolledAndReservedKids));
      this.processSlots(slotData)

      this.processTimetables(includedTimetableData)

      this.setState({kids, loadingMinimumData: false })
    } catch (err) {
      if (!err.message.match(/401/)) {
        this.setState(() => {
          throw err;
        });
      }
    }
  };

  updateGrownUp = (id, params) =>
    new Promise((resolve, reject) => {
      const data = {
        grown_up: {
          ...params
        }
      };
      const { refreshGrownUp } = this.props;
      GrownUpAPI.updateGrownUp(id, data)
        .then(() => {
          refreshGrownUp().then(() => resolve());
        })
        .catch(error => {
          reject(error);
        });
    });

  allDetailsComplete = () => {
    const { grownUpAttributes } = this.props;
    if (grownUpAttributes) {
      const requiredFields = [
        'email',
        'phone',
        'name',
        'address_l1',
        'address_city',
        'address_postcode',
        'comms_marketing',
      ];
      return (
        !!grownUpAttributes &&
        requiredFields.every(
          field =>
            grownUpAttributes[field] && grownUpAttributes[field] !== 'not_set'
        )
      );
    }

    // All details may not be complete, but we don't want to give false negatives,
    // so we will say they are for the moment
    return true;
  };

  buildFormattedChanges = changes => {
    const formattedChanges = [];
    const { kids } = this.state;

    changes.forEach(change => {
      const { id } = change;
      const bandId = change.relationships.band.data.id
      const kidId = change.relationships.kid.data.id
      const kid = kids.find(kd => kd.id === kidId)

      const { name } = kid.attributes;
      const { instrument_type, blocked_until } = change.attributes;

      formattedChanges.push({
        id,
        bandId,
        kidId,
        name,
        instrument: instrument_type,
        date: blocked_until
      });
    });

    return formattedChanges;
  };

  slotsForKid = kid => {
    const {slots} = this.state;

    return slots.filter(slot => slot.relationships.kid.data.id === kid.id)
  }

  timetableForKid = kid => {
    const {timetables} = this.state;
    const timetableId = kid.relationships.timetable.data.id

    return timetables.find(tt => tt.id === timetableId)
  }

  instrumentForKid = kid => {
    const {slots} = this.state;

    const reservedSlot = slots.find(slot => slot.relationships.kid.data.id === kid.id)
    return reservedSlot.attributes.instrument_type
  }

  formattedUnapprovedInstrumentChanges = () => {
    const {unapprovedInstrumentChanges} = this.state;

    return this.buildFormattedChanges(unapprovedInstrumentChanges);
  }

  loadingOrComponentWithData = path => {
    const { loadingMinimumData } = this.state;

    if (loadingMinimumData) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }

    return this.componentThatNeedsData(path);
  };

  selectedKid = () => {
    const { kids } = this.state;

    const location = window.location.pathname.split('/').filter(Boolean);
    // path could be kid/:id or kid/:id/timetable or kid/:id/cancellation_form
    const last_chunk = location[location.length - 1];
    let kidId = 0
    if (last_chunk === 'timetable' || last_chunk === 'cancellation_form' || last_chunk === 'enrolment-status') {
      kidId = location[location.length - 2];
    } else {
      kidId = location[location.length - 1];
    }

    const kid = kids.find(kd => kd.id === kidId);
    return kid;
  };

  kidFromParams = () => {
    const { kids } = this.state;

    const urlParams = StringUtils.parseQueryString(WindowUtils.anyParams());
    const kidId = urlParams.id;

    const kid = kids.find(kd => kd.id === kidId);

    return kid
  }

  bandLeaderDetails = () => {
    const location = window.location.pathname.split('/').filter(Boolean);
    this.bandLeaderId = location[location.length - 2];
  };

  SongDetails = () => {
    const location = window.location.pathname.split('/').filter(Boolean);
    this.SongId = location[location.length - 1];
  };

  /** Not needed at the moment, to be reinstated when schools are unfrozen
   * Removing to improve coverage
  /*
  bandLeaderIDs = () => {
    const { timetables, enrolledKids } = this.state;
    if (!enrolledKids || !timetables) {
      return [];
    }

    // get the timetables for enrolled kids only
    const timetableIds = enrolledKids.map(kid => kid.relationships.timetable.data.id);

    if (!timetableIds) {
      return [];
    }

    const uniqueTimetableIds = timetableIds.filter(
      (a, b) => timetableIds.indexOf(a) === b
    );

    // get the ids of band leaders teaching there
    const bandLeaderIDs = uniqueTimetableIds.map(timetableId => {
      const timetable = timetables.find(tt => tt.id === timetableId);
      if (!timetable) {
        return null;
      }
      const timetableAttributes = timetable.attributes;
      const { band_leader_id: bandLeaderId } = timetableAttributes;
      return bandLeaderId;
    });
    return bandLeaderIDs.toArray();
  };
  */

  kidComponent = path => {
    const {grownUpId} = this.props
    const kidIndex = path.indexOf("/kid")

    if (kidIndex >= 0) {
      const { grownUpAttributes } = this.props;
      const kid = this.selectedKid();

      if (!kid) {
        return null;
      }

      const kidTimetableIndex = path.indexOf("timetable")
      if (kidTimetableIndex >= 0) {

        return (
          <TimetableView kidId={kid.id} attributes={kid.attributes} />
        );
      }

      const kidCancellationIndex = path.indexOf("cancellation")

      if (kidCancellationIndex >= 0) {
        return (
          <CancellationForm
            kid={kid}
            grownUpId={grownUpId}
            grownUpName={grownUpAttributes.name}
            refreshAllTheThings={this.fetchGrownUpRelationships}
          />
        )
      }

      const kidEnrolmentStatusIndex = path.indexOf("enrolment-status")

      if (kidEnrolmentStatusIndex >= 0) {
        return (
          <EnrolmentStatus
            key={kid.id}
            attributes={kid.attributes}
            id={kid.id}
          />
        )
      }

      return (
        <KidForm
          key={kid.id}
          attributes={kid.attributes}
          id={kid.id}
          timetableAttributes={this.timetableForKid(kid).attributes}
          refreshAllTheThings={this.fetchGrownUpRelationships}
        />
      );
    }

    return false
  }

  componentThatNeedsData = path => {
    const { grownUpId, grownUpAttributes } = this.props;
    const { kids,
            reservedKids,
            showNPSThanksMessage,
            approvedInstrumentChanges,
          } = this.state;

    const lastChar = path.slice(-1)

    let safePath = path
    if (lastChar === "/" && path.length > 1) {
      safePath = path.slice(0, -1);
    }

    const kidView = this.kidComponent(safePath)
    if (kidView) {
      return kidView
    }

    const songPath = /song\/\d+$/.test(safePath)
    if (songPath) {
      this.SongDetails();
      return <SongView key={this.SongId} id={this.SongId} />;
    }

    const staffProfilePath = /band_leader\/\d+\/staff_profile$/.test(safePath)
    if (staffProfilePath) {
      this.bandLeaderDetails();
      return <BandLeaderProfile bandLeaderId={this.bandLeaderId} />;
    }

    const timelineIndex = safePath.indexOf("timeline")
    if (timelineIndex >= 0) {
      return (
        <Timeline
          kid={this.selectedKid()}
          fetchGrownUpRelationships={this.fetchGrownUpRelationships}
        />
      )
    }

    switch (safePath) {
      case paths.ROOT:
        return (
          <Welcome
            grownUpAttributes={grownUpAttributes}
            // grownUpId={grownUpId}
            reservedKids={reservedKids}
            unapprovedInstrumentChanges={this.formattedUnapprovedInstrumentChanges()}
            detailsMissing={!this.allDetailsComplete()}
            showNPSThanksMessage={showNPSThanksMessage}
            setShowNPSThanksMessage={this.setShowNPSThanksMessage}
            kids={kids}
            approvedInstrumentChanges={approvedInstrumentChanges}
          />
        );
      case paths.ENROL_RESERVED_KID:
        return (
          <EnrolReservedKid
            kid={this.kidFromParams()}
            school={this.timetableForKid(this.kidFromParams()).attributes.display_name}
            instrument={this.instrumentForKid(this.kidFromParams())}
            grownUpAttributes={grownUpAttributes}
            grownUpId={grownUpId}
            allDetailsComplete={this.allDetailsComplete()}
            refreshAllTheThings={this.fetchGrownUpRelationships}
          />
        );
      case paths.INSTRUMENT_CHANGE:
        return (
          <InstrumentChange
            kid={this.kidFromParams()}
            kidSlots={this.slotsForKid(this.kidFromParams())}
            refreshAllTheThings={this.fetchGrownUpRelationships}
          />
        );
      case paths.AVAILABLE_INSTRUMENT_CHANGES:
        return (
          <AvailableInstrumentChanges
            kid={this.kidFromParams()}
            slots={this.slotsForKid(this.kidFromParams())}
            refreshAllTheThings={this.fetchGrownUpRelationships}
          />
        );
      case paths.ENROL:
        return (
          <KidRegistrationProvider
            grownUp={{ id: grownUpId, attributes: grownUpAttributes }}
            onDataInvalidation={this.fetchGrownUpRelationships}
          >
            <KidRegistration />
          </KidRegistrationProvider>
        );
      case paths.MY_DETAILS:
        return (
          <GrownUpForm
            handleFormSubmit={this.updateGrownUp}
            grownUpId={grownUpId}
            grownUpObject={grownUpAttributes}
          />
        );
      case paths.DIRECT_DEBITS:
        return (
          <DirectDebits
            grownUpAttributes={grownUpAttributes}
            grownUpId={grownUpId}
            refreshAllTheThings={this.fetchGrownUpRelationships}
          />
        );
      case paths.FEEDBACK:
        return (
          <NPS
            grownUpName={grownUpAttributes.name}
            grownUpId={grownUpId}
            grownUpAttributes={grownUpAttributes}
          />
        );
      case paths.SETTINGS:
        return (
          <Settings />
        )
      case paths.INSTRUMENT_DEALS_RENTALS:
        return (<InstrumentDealsRentals />)
      case paths.TERMS_AND_CONDITIONS:
        return (
          <TermsAndConditions />
        )
      case paths.FAQ:
        return (
          <FAQ />
        )
      case paths.KIDS_APP:
        return <KidsAppPromo />
      case paths.KIDS_APP_SUBSCRIBE:
        return <KidsAppSubscribe />
      default:
        return null;
    }
  };


  pageWithoutNav = () => {
    return (
      <Grid.Row columns={1}>
        <Grid.Column width={16}>
          {this.loadingOrComponentWithData(window.location.pathname)}
        </Grid.Column>
      </Grid.Row>
    )
  }

  pageContent = () => {
    const { unapprovedInstrumentChanges, reservedKids, kids } = this.state

    return (
      <Grid.Row>
        <MediaQuery minWidth={769}>
          <Grid.Column width={4} className="menu-v1">
            <NavMenu
              allDetailsComplete={this.allDetailsComplete()}
              reservedKidsCount={reservedKids.length}
              unapprovedInstrumentChangesCount={unapprovedInstrumentChanges.length}
              kids={kids}
            />
            <br />
          </Grid.Column>
        </MediaQuery>
        <MediaQuery minWidth={769}>
          <Grid.Column width={12}>
            {this.loadingOrComponentWithData(window.location.pathname)}
          </Grid.Column>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <Grid.Column width={16}>
            {this.loadingOrComponentWithData(window.location.pathname)}
          </Grid.Column>
        </MediaQuery>
      </Grid.Row>
    )
  }

  render() {
    return (
      <Grid relaxed stackable padded className="main-page">
        {this.pageContent()}
        <CookieConsentModal />
      </Grid>
    )
  }
}

export default Home;
