import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class TimelineAPI {
  markAsSeen = data => AuthenticatedHTTPWrapper.patch({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/timeline/mark_as_seen.json`,
    data,
  });

  updateTimelineEntryReaction = (id, data) =>
    AuthenticatedHTTPWrapper.patch({
      url: `${
        process.env.REACT_APP_ABBEY_ROAD_URL
      }/api/timeline_entries/${id}.json`,
      data
    });
}

export default new TimelineAPI();
