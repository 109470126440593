import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import WindowUtils from '../../utils/WindowUtils';
import SongAPI from '../../api/SongAPI';

class SongView extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      song: {}
    };
  }

  componentDidMount() {
    this.getSong();
    WindowUtils.scrollToTop();
  }

  getSong = async () => {
    const { id } = this.props;
    const song = await SongAPI.getSong(id);
    this.setState({ song: song.data.data.attributes });
  };

  render() {
    const { song } = this.state;
    return (
      <div>
        <h1>
          {song.title}
          {' '}
          by
          {' '}
          {song.artist}
        </h1>
        <Table celled unstackable compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Lyrics</Table.HeaderCell>
              <Table.HeaderCell>Chords</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell verticalAlign="top">
                {ReactHtmlParser(song.formatted_lyrics)}
              </Table.Cell>
              <Table.Cell verticalAlign="top">
                {ReactHtmlParser(song.formatted_chords)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default SongView;
