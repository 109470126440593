import React from 'react';
import PropTypes from 'prop-types';
import './Stepper.scss';

const Stepper = ({activeStep, stepCount, triggerChangeFunc, colorOption, disabledNavigation}) => {
  const steps = [];

  let containerClass = 'Stepper';
  switch (colorOption) {
    case 1:
      containerClass = 'Stepper';
      break;
    case 2:
      containerClass = 'StepperColorOption2';
      break;
    case 3:
      containerClass = 'StepperColorOption3';
      break;
    default:
      return containerClass;
  }

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < stepCount; i++) {
    const isActive = activeStep === i;

    if (disabledNavigation) {
      steps.push(
        <span
          className={isActive ? `${containerClass}__activeStep` : `${containerClass}__step`} 
          type='button'
          key={i}
        />
      )
    } else {
      steps.push(
        <button
          className={isActive ? `${containerClass}__activeStep` : `${containerClass}__step`} 
          onClick={() => triggerChangeFunc(i)}
          onKeyDown={() => triggerChangeFunc(i)}
          type='button'
          key={i}
        />
      )
    }
  }

  return (
    <div className={containerClass}>
      {steps.map((step) => {
        return step;
      })}
    </div>
  )
}

export default Stepper;

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
  triggerChangeFunc: PropTypes.func.isRequired,
  colorOption: PropTypes.number,
  disabledNavigation: PropTypes.bool
}

Stepper.defaultProps = {
  colorOption: 1,
  disabledNavigation: true
}
