import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';
import Instrument from './Instrument';

const WAITLIST_COMMIT_STATEMENT = "If you don't want to try another instrument right now, join the waitlist here. You can update your instrument preferences any time later."

const INSTRUMENT_DESCRIPTIONS = Object.freeze({
  vocals: 'Lead the band from the front, with the most amazing instrument of all.',
  keyboard: 'Bring classical musical technique to the joyous world of your rock and pop band.',
  guitar: 'Give the band a musical edge by playing this iconic rock instrument.',
  drums: 'Keep the band in time, and lead the rhythm and groove of every song.',
});

export default function InstrumentChoiceCard({ instrumentName, upSellFrom, onSelect }) {
  const {
    kid: { onWaitlistFor, justJoiningWaitlist },
    timetable: { availablePlacesCountsFor, placesAvailable },
  } = useKidRegistration();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const instrumentIsAvailable = !!availablePlacesCountsFor(instrumentName);

  const buttonAtBottom = isMobile && (upSellFrom || (placesAvailable && onWaitlistFor(instrumentName)));

  const joiningWaitlistWithUpsellAvailable = onWaitlistFor(instrumentName) && placesAvailable && justJoiningWaitlist;

  const checkbox = () => (
    <div className="max-half-width">
      <Checkbox
        checked={onWaitlistFor(instrumentName)}
        onChange={onSelect}
        label="Join waitlist"
      />
    </div>
  );

  const button = () => {
    const buttonProps = {
      onClick: onSelect,
      primary: instrumentIsAvailable,
      basic: !instrumentIsAvailable,
      color: instrumentIsAvailable ? undefined : 'blue',
      fluid: isMobile,
    };

    const instrumentIsAvailableText = (
      `Enrol${upSellFrom ? ` on ${instrumentName} and join waitlist for ${upSellFrom}` : ''}`
    );
  
    const instrumentIsUnavailableText = (
      `Join waitlist${onWaitlistFor(instrumentName) ? ` for ${instrumentName}` : ''}`
    );

    return (
      <div className={buttonAtBottom ? 'mt-8 full-width' : 'max-half-width'}>
        <Button {...buttonProps}>
          {instrumentIsAvailable ? instrumentIsAvailableText : instrumentIsUnavailableText}
        </Button>
      </div>
    );
  };

  return (
    <div className={`instrument-card${!placesAvailable && onWaitlistFor(instrumentName) ? ' white-background blue-border' : ''}`}>
      <div className="full-width flex-row-aligned-center space-between mb-8">
        <div className="max-half-width">
          <Instrument instrumentName={instrumentName} />
        </div>
        {placesAvailable && !buttonAtBottom && button()}
        {!placesAvailable && checkbox()}
      </div>
      <div className="full-width">
        <div>
          {joiningWaitlistWithUpsellAvailable ? WAITLIST_COMMIT_STATEMENT : INSTRUMENT_DESCRIPTIONS[instrumentName]}
        </div>
        {placesAvailable && buttonAtBottom && button()}
      </div>
    </div>
  );
}

InstrumentChoiceCard.propTypes = {
  instrumentName: PropTypes.string.isRequired,
  upSellFrom: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

InstrumentChoiceCard.defaultProps = {
  upSellFrom: null,
};
