import React, { useState } from 'react';
import { Modal, Accordion, Icon, Grid, Form, Button, Dimmer, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import "./BandMediaHelpModal.css"
import closeModalIcon from "./close-vector.svg"

const faqs = [
  {
    title: "Why am I seeing other children in the photo/video?",
    body: "The children shown are all in your child’s band and their parents/guardians have given consent for them to be featured. This photo/video will only be visible to other parents/guardians of children in the same band."
  },
  {
    title: "My child is not clearly visible or has their eyes closed, what should I do?",
    body: "Understandably sometimes a child may have their eyes closed or have moved as the photo was taken. Don’t worry you will see a photo/video every term, so there will be lots more chances to see your child in their band."
  },
  {
    title: "How do I change my consent, so my child is not featured in the future?",
    body: "You can change your consent at any time through your backstage account. Simply go to your child’s profile, click ‘recording and performances’ and make changes. Changing your consent close to a media capture day may mean your child will be included/excluded still but your preferences will be saved for next time."
  }
]

const BandMediaHelpModal = ({ displayModal, closeModal, onReport, isLoading, setLoadingState }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [inputContent, setInputContent] = useState("");

  const changeActiveIndex = (index) => {
    if (index !== activeIndex) {
      setActiveIndex(index);
    }
  }

  const onClose = () => {
    changeActiveIndex(0);
    closeModal();
  }

  const closeAndReport = async (event) => {
    event.preventDefault();
    setLoadingState(true);
    onReport(inputContent)
  }

  const modalHeader = (
    <Grid.Row width={16}>
      <div className="flex modal-header">
        <div className="flex header-text">
          <h2 className="modal-black modal-title">ABOUT BAND MEDIA</h2>
        </div>
        <button type="button" className="plain-button cross-button" onClick={onClose}>
          <img src={closeModalIcon} alt='close modal icon' />
        </button>
      </div>
    </Grid.Row>
    )

  const faqContent = (title, body, key) => (
    <div key={`faq-container-${key}`}>
      <Accordion.Title active={activeIndex === key} onClick={() => changeActiveIndex(key)}>
        <Icon name='dropdown' />
        {title}
      </Accordion.Title>
      <Accordion.Content index={key} active={activeIndex === key}>
        <p>
          {body}
        </p>
      </Accordion.Content>
    </div>
    );

    const reportMediaFragment = (
      <>
        <Accordion.Title active={activeIndex === faqs.length} onClick={() => changeActiveIndex(faqs.length)}>
          <Icon name='dropdown' />
          There is a problem with the media
        </Accordion.Title>
        <Accordion.Content index={faqs.length} active={activeIndex === faqs.length}>
          <p>
            You can report any critical issues with this photo/video to the team
            by submitting the problem below and it will be looked into.
          </p>
          <Form onSubmit={closeAndReport}>
            <Form.Field>
              <Form.Input
                name="comment"
                label="Let us know the reason below:"
                onChange={(e) => setInputContent(e.target.value)}
              />
            </Form.Field>
            <Button
              disabled={inputContent.length < 10}
              primary
              type="submit"
              className="modal-submit"
            >
              Submit
            </Button>
          </Form>
        </Accordion.Content>
      </>
    )

  return (
    <Modal centered={false} open={displayModal}>
      <Modal.Content>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Grid padded>
          {modalHeader}
          <Grid.Row>
            <Grid.Column>
              <Accordion fluid styled>
                {faqs.map((faq, index) => faqContent(faq.title, faq.body, index))}
                {reportMediaFragment}
              </Accordion>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

BandMediaHelpModal.propTypes = {
  displayModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onReport: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setLoadingState: PropTypes.func.isRequired
}


export default BandMediaHelpModal;
