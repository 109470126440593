import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import CountUp from 'react-countup';
import { TypeAnimation } from 'react-type-animation';
import { animated, useTransition } from '@react-spring/web';
import Markdown from 'markdown-to-jsx'
import './Slides.scss';

const SlideItem = ({item, containerClass, delay}) => {
  const keyName = Object.keys(item)[0];
  const value = Object.values(item)[0];
  const isImage = keyName.includes('image');
  const hasSparkles = keyName.includes('sparkles');
  const hasSpecial = keyName.includes('special');
  const isBigIcon = keyName.includes('big_icon');
  const hasStar = keyName.includes('star');
  const isTypeAnimation = keyName.includes('_typing');
  const keyId = uuidv4();
  let display;

  if (isBigIcon) {
    return null;
  }


  let strippedName = keyName.split('_sparkles')[0].split('_special')[0];
  const hasEmphasis = value && typeof value === 'string' && (value.includes('<span>') || value.includes('<strong>'));
  
  if (hasStar) {
    strippedName = strippedName.replace('_star', '')
  }

  if (isTypeAnimation) {
    strippedName = strippedName.replace('_typing', '')
  }

  let textContent = value;

  if (strippedName === 'unit' && value > 0) {
    textContent = (
      <CountUp
        end={value}
        duration={1.5}
      />
    )
  }

  if (isTypeAnimation) {
    textContent = (
      <TypeAnimation 
        sequence={[value]}
        repeat={1}
        cursor={false}
        speed={5}
      />
    )
  }

  const textContentWrapper = (
    <p key={keyId} className={`${containerClass}__${strippedName}`}>
      {textContent}
    </p>
  )
  
  let content = isImage ? (
    <img
      key={keyId}
      className={`${containerClass}__img`}
      src={value}
      alt="slide_image"
    />
  ) : textContentWrapper
  
  // to be able to have italics and bold within a single block
  if (hasEmphasis) {
    content = (
      <Markdown key={keyId} className={`${containerClass}__${strippedName}`}>
        {value}
      </Markdown>
    )
  }

  if ((hasSparkles || hasSpecial) && isImage) {
    display = (
      <div key={keyId} className={hasSparkles ? `${containerClass}__sparkles` : `${containerClass}__special`}>
        <span />
        <span />
        <span />
        <span />
        {content}
      </div>
    )
  } else if (hasStar) {
    const animItems = [
      <span />,
      <div>{content}</div>
    ]

    const transitions = useTransition(animItems, {
      from: { opacity: 0, transform: 'scale(1.1)' },
      enter: { opacity: 1, transform: 'scale(1)' },
      trail: 300,
      delay,
    })

    const animatedBit = transitions((style, i) => {
      return (
        <animated.div style={style}>
          {i}
        </animated.div>
      )
    })

    display = (
      <div key={keyId} className={`${containerClass}__star`}>
        {animatedBit}
      </div>
    )
  } else if (hasSparkles && !hasSpecial && !isImage) {
    display = (
      <div key={keyId} className={`${containerClass}__${strippedName}__sparkles`}>
        <span />
        <span />
        <span />
        <span />
        {content}
      </div>
    )
  }
  else {
    display = content
  }

  return display;
}

export default SlideItem;


SlideItem.propTypes = {
  item: PropTypes.object.isRequired, 
  containerClass: PropTypes.string.isRequired,
  delay: PropTypes.number
}

SlideItem.defaultProps = {
  delay: 300
}
