import React, { Component } from 'react';
import { Button, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import paths from '../../consts/paths';
import DateUtils from '../../utils/DateUtils';

class OfflineDirectDebit extends Component {
  static propTypes = {
    kidParams: PropTypes.object.isRequired,
    userIsOnlySignatory: PropTypes.string,
    accountInUsersName: PropTypes.string,
  };

  static defaultProps = {
    userIsOnlySignatory: 'yes',
    accountInUsersName: 'yes',
  };

  finishButton = () => {
    return (
      <Button href={paths.ROOT}>Return Home</Button>
    );
  };

  otherPayer = () => {
    const { accountInUsersName } = this.props;

    if (accountInUsersName === 'no') {
      return (
        <Message positive>
          <Message.Header>
            Someone Else will be Paying
          </Message.Header>
          <Message.Content>
            <p>
              You have said that someone else will be paying for the Rocksteady subscription.
            </p>
            <p>
              In order to arrange this, please ask the bill payer to contact the office on 0330 113 0330.
            </p>
            <p>
              We will be happy to set up an account for them.
            </p>
          </Message.Content>
        </Message>
      )
    }

    return null;
  }

  dualSignatory = () => {
    const { userIsOnlySignatory } = this.props;

    if (userIsOnlySignatory === 'no') {
      return (
        <Message negative>
          <Message.Header>
            Dual Signatory
          </Message.Header>
          <Message.Content>
            <p>
              You have said that your bank account needs two or more signatures.
            </p>
            <p>
              We are very sorry, but you can not use this bank account to set up a Direct Debit with Rocksteady.
            </p>
            <p>
              Please select a different bank account, or contact the office on 0330 113 0330 
              to discuss other methods of payment.
            </p>
            <p>
              <b>Note</b>
            </p>
            <p>  
              Most joint bank accounts only require one signature. Bank accounts that require
              two signatures are typically charity or business accounts.
            </p>
          </Message.Content>
        </Message>
      )
    }

    return null;
  }

  kidInformation = () => {
    const { kidParams } = this.props;
    const { name, reservedUntil } = kidParams;

    if (!_.isEmpty(kidParams)) {
      let dateText = null;

      const reservedUntildate = reservedUntil && moment(reservedUntil).format('Do MMM YYYY');
      if (reservedUntildate && reservedUntildate > DateUtils.today()) {
        dateText = (
          <p>
            The space will be held until
            {` ${reservedUntildate}.`}
          </p>
        );
      }

      return (
        <Message>
          <Message.Header>Reserved Kid</Message.Header>
          <Message.Content>
            <p>
              {name}
              {' '}
              has a reserved space, which we will confirm when we receive the payment details.
            </p>
            {dateText}
          </Message.Content>
        </Message>
      )  
    }

    return null;
  }

  render() {
    return (
      <div>
        {this.kidInformation()}
        {this.otherPayer()}
        {this.dualSignatory()}
      </div>
    );
  }
}

export default OfflineDirectDebit;
