export default class InstrumentUtils {
  static humanize = (preference) => {
    const preferences = {
      no_preference: 'No preference',
      drums: 'Drums',
      bass: 'Guitar (bass)',
      guitar: 'Guitar (electric)',
      guitar_any: 'Guitar (any)',
      keyboard: 'Keyboard',
      vocals: 'Vocals',
    }
    return preferences[preference];
  };
}
