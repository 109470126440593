import React from "react";
import PropTypes from "prop-types"
import { Icon, Grid } from "semantic-ui-react";
import "./ToastMessage.css";

const ToastMessage = ({title, body, iconName, iconColor, backgroundColor, textColor}) => (
  <div className="toast-message-container" style={{backgroundColor}}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={2}>
          <div style={{color: iconColor}}>
            <Icon name={iconName} size="big" />
          </div>
        </Grid.Column>
        <Grid.Column width={14}>
          <strong style={{color: textColor}}>
            {title}
          </strong>
          <p style={{color: textColor}}>
            {body}
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

ToastMessage.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string
}

ToastMessage.defaultProps = {
    textColor: "#004835",
    backgroundColor: "#CCFCEF",
    iconColor: "#00906A",
    iconName: "check",
}

export default ToastMessage;
