import axios from 'axios';
import WindowUtils from './WindowUtils';
import JWTManager from './JWTManager';
import paths from '../consts/paths';

class AuthenticatedHTTPWrapper {
  configWithAuthHeader = (config = {}) => ({
    ...config,
    headers: {
      Authorization: config.isSchool ? JWTManager.getSchoolsToken() : JWTManager.getToken(),
    },
  });

  handleError = (errors) => {
    if (errors.response && errors.response.status === 401) {
      if (JWTManager.getSchoolsToken()) {
        JWTManager.removeSchoolsToken();
        WindowUtils.replaceLocation(`${paths.SCHOOLS_SIGN_IN}`);
      } else {
        JWTManager.removeToken();
        const path = window.location.pathname + window.location.search;
        WindowUtils.replaceLocation(`${paths.SIGN_IN}?signInTimedOut=true&previousPath=${path}`);
      }
    }
  };

  patch = ({ url, data, config } = {}) => new Promise((resolve, reject) => {
    axios
      .patch(url, data, this.configWithAuthHeader(config))
      .then((response) => {
        resolve(response);
      })
      .catch((errors) => {
        this.handleError(errors);
        reject(errors);
      });
  });

  get = ({ url, config } = {}) => new Promise((resolve, reject) => {
    axios
      .get(url, this.configWithAuthHeader(config))
      .then((response) => {
        resolve(response);
      })
      .catch((errors) => {
        this.handleError(errors);
        reject(errors);
      });
  });

  delete = ({ url, config } = {}) => new Promise((resolve, reject) => {
    axios
      .delete(url, this.configWithAuthHeader(config))
      .then((response) => {
        resolve(response);
      })
      .catch((errors) => {
        this.handleError(errors);
        reject(errors);
      });
  });

  post = ({ url, config } = {}) => new Promise((resolve, reject) => {
    axios
      .post(url, config.data, this.configWithAuthHeader(config))
      .then((response) => {
        resolve(response);
      })
      .catch((errors) => {
        this.handleError(errors);
        reject(errors);
      });
  });
}

export default new AuthenticatedHTTPWrapper();
