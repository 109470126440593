import React, { useEffect, useState } from 'react';
import { Divider, Form, Header, Image, Message,} from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import DirectDebitQualifiers from './DirectDebitQualifiers';
import DirectDebitForm from '../DirectDebitForm/DirectDebitForm';
import WindowUtils from '../../utils/WindowUtils';
import OfflineDirectDebit from '../OfflineDirectDebit/OfflineDirectDebit';
import DdSummary from '../DirectDebitForm/DdSummary';
import TimetableAPI from '../../api/TimetableAPI';
import ddImage from '../../dd_logo.png';

export default function DirectDebits({ grownUpId, grownUpAttributes, refreshAllTheThings }) {
  const { state } = useLocation();
  const [kidParams, setKidParams] = useState((state && state.kidParams) || {});
  const { reservedUntil, timetableId } = kidParams;
  // If we have not navigated here from another page, then we are filling out a direct debit
  // to pay for a kid who is already enrolled. In this case we want to display the outstanding
  // balance on the billing account to the customer.
  const showOutstandingBalance = !(state && state.kidParams);

  const [errors, setErrors] = useState([]);

  const [userIsOnlySignatory, setUserIsOnlySignatory] = useState('');
  const [accountInUsersName, setAccountInUsersName] = useState('');

  const initialBankDetails = {
    accountName: null,
    accountNumber: null,
    accountSortCode: null,
    bacsReference: null,
    bankAddress: null,
  };

  const [
    {
      accountName,
      accountNumber,
      accountSortCode,
      bacsReference,
      bankAddress,
    },
    setValidatedBankDetails,
   ] = useState(initialBankDetails);

  const readyForSummary = [accountName, accountNumber, accountSortCode, bacsReference, bankAddress].every(val => val);

  useEffect(() => {
    if (reservedUntil && timetableId) {
      TimetableAPI.getPricingInfo(timetableId)
        .then(({ data }) => {
          setKidParams({
            ...kidParams,
            firstPaymentDate: data.first_payment_date,
            firstPaymentAmount: data.first_payment_amount,
            regularMonthlyAmount: data.regular_monthly_cost,
          });
        });
    }
  }, []);

  useEffect(() => {
    WindowUtils.scrollToTop();
  }, [grownUpAttributes.active_direct_debit_or_dd_info]);

  const handleErrorReceived = (error) => setErrors([...errors, error]);

  const handleRadioChange = (setter) => (_e, { label, value: currentVal }) => {
    const newVal = label.toLowerCase();
    if (newVal !== currentVal) setter(newVal);
  };

  const readyForBankDetails = userIsOnlySignatory === 'yes';

  const notAbleToGiveBankDetails = [userIsOnlySignatory, accountInUsersName].includes('no');

  const thankYou = () => {
    if (errors.length > 0) {
      return <Message negative header="Problem enroling Kid" content={errors} />;
    }

    if (readyForSummary) {
      return (
        <DdSummary
          grownUpAttributes={grownUpAttributes}
          firstPaymentDate={kidParams.firstPaymentDate}
          accountName={accountName}
          accountSortCode={accountSortCode}
          accountNumber={accountNumber}
          bacsReference={bacsReference}
          bankAddress={bankAddress}
        />
      );
    }

    return (
      <Message positive>
        <Message.Content>
          You already have an active Direct Debit with us.
        </Message.Content>
      </Message>
    );
  };

  const directDebitQualifiers = () => (
    <div>
      <p>
        We are very sorry, we don&apos;t have an Active Direct Debit for you. Please answer the questions below and set up a Direct Debit.
      </p>
      <Divider horizontal>
        <Header as="h3">Step 1. Bank Account Holder</Header>
      </Divider>

      <DirectDebitQualifiers
        accountInUsersName={accountInUsersName}
        onAccountInUsersNameChange={handleRadioChange(setAccountInUsersName)}
        userIsOnlySignatory={userIsOnlySignatory}
        onUserIsOnlySignatoryChange={handleRadioChange(setUserIsOnlySignatory)}
      />
    </div>
  );

  const bankDetails = () => {
    if (readyForBankDetails) {
      return (
        <div>
          <Divider horizontal>
            <Header as="h3">Step 2. Bank Account Details</Header>
          </Divider>

          <Form>
            <DirectDebitForm
              grownUpId={grownUpId}
              grownUpAttributes={grownUpAttributes}
              onBankDetailsValidated={setValidatedBankDetails}
              kidParams={kidParams}
              refreshAllTheThings={refreshAllTheThings}
              onErrorReceived={handleErrorReceived}
              displayOutstandingBalance={showOutstandingBalance}
            />
          </Form>
        </div>
      )
    }

    if (notAbleToGiveBankDetails) {
      return (
        <OfflineDirectDebit
          userIsOnlySignatory={userIsOnlySignatory}
          accountInUsersName={accountInUsersName}
          kidParams={kidParams}
        />
      )
    }

    return null;
  };

  const content = () => {
    if (grownUpAttributes.active_direct_debit_or_dd_info) return thankYou();

    return (
      <div className="page-container">
        <h2>
          Direct Debit
          <Image src={ddImage} size="tiny" floated="right" />
        </h2>
        {directDebitQualifiers()}
        {bankDetails()}
      </div>
    );
  };

  return content();
};

DirectDebits.propTypes = {
  grownUpId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  grownUpAttributes: PropTypes.object.isRequired,
  refreshAllTheThings: PropTypes.func.isRequired,
};
