import HTTPWrapper from '../utils/HTTPWrapper';

// Currently the service we are using is 'getAddress()', https://documentation.getaddress.io/
// The tokens required are set per sub-domain making requests to their service.
// In our instance we have added both booking. and backstage.rocksteadymusicschool.com

// If utilisation/making requests to the service is required for development purposes, it
// is recommended to first make use of a personal account (free tier offers 20 requests per-hour),
// or requesting the actual api key from whoever holds it, and place key in your local .env.

class AddressAPI {
  autoComplete = (baseUrl, term, token) =>
    HTTPWrapper.get({ url: `${baseUrl}/autocomplete/${term}?api-key=${token}&all=true` });

  getByID = (baseUrl, id, token) =>
    HTTPWrapper.get({ url: `${baseUrl}/get/${id}?api-key=${token}` });
}

export default new AddressAPI();
