import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Image, Grid } from 'semantic-ui-react';

import './DealCard.scss'

const DealCard = ({
  img,
  heading,
  description,
  buttonPath,
  buttonText,
  copyButton,
  instrument,
  fullwidth,
  disabled
}) => {
  const displayCard = () => {
    if(fullwidth) {
      return (
        <Grid stackable columns={2}>
          <Grid.Row style={{marginTop: "27px"}}>
            <Grid.Column className="vocals-image">
              <Image src={img} />
            </Grid.Column>

            <Grid.Column className="vocals-content">

              <Card className="instrument-deal" style={{width: "100%"}}>
                <Card.Content>
                  <Card.Header>{heading}</Card.Header>
                  <Card.Description>{description}</Card.Description>
                </Card.Content>

                <Card.Content extra>
                  <Button positive href={buttonPath} target="_blank" disabled={disabled}>
                    {buttonText}
                  </Button>
                </Card.Content>
              </Card>

            </Grid.Column>
          </Grid.Row>
        </Grid>
      )
    }

    return (
      <Card className="instrument-deal">
        <Image src={img} />
        <Card.Content>
          <Card.Header>{heading}</Card.Header>
          <Card.Description>{description}</Card.Description>
        </Card.Content>

        <Card.Content extra>
          <Button positive href={buttonPath} target="_blank" disabled={disabled}>
            {buttonText}
          </Button>
          {copyButton ? (
            <Button
              className={`normans-copy-btn blue ${instrument}`}
              onClick={() => copyButton(`normans-copy-btn.${instrument}`)}
            >
              Copy Code
            </Button>
          ) : null}
        </Card.Content>
      </Card>
    );
  }

  return displayCard()
};

DealCard.propTypes = {
  img: PropTypes.string.isRequired,
  buttonPath: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  copyButton: PropTypes.func,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  instrument: PropTypes.string,
  fullwidth: PropTypes.bool
};

DealCard.defaultProps = {
  copyButton: null,
  instrument: null,
  fullwidth: false
};

export default DealCard;
