import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Audio = ({play, track, fade, muted, pause}) => {

  const player = useRef()
  
  const triggerPlay = () => {
    player.current.play()
  }

  const triggerPause = () => {
    player.current.pause()
  }

  const triggerFade = () => {
    player.current.loop = false;
  }

  const toggleMuted = (mutedStatus) => {
    if (mutedStatus) {
      player.current.muted = true;
    } else {
      player.current.muted = false;
    }
  }
  
  useEffect(() => {
    if (player && player.current) {
      if (play) {
        triggerPlay();
      }
      if (fade) {
        triggerFade()
      }
      if (pause) {
        triggerPause()
      }
    }
  }, [play, fade, player, pause])

  useEffect(() => {
    if (player && player.current) {
      toggleMuted(muted)
    }
  }, [muted, player])



  return (
    <div>
      <audio loop ref={player}>
        <source src={track} type="audio/mp3" />
        <track kind="captions" />
      </audio>
    </div>
  )
}

export default Audio;

Audio.propTypes = {
  play: PropTypes.bool.isRequired,
  track: PropTypes.string.isRequired,
  fade: PropTypes.bool,
  muted: PropTypes.bool,
  pause: PropTypes.bool
}

Audio.defaultProps = {
  fade: false,
  muted: false,
  pause: false
}

