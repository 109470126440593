import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { Image, Table } from 'semantic-ui-react';
import RequireKidsAppPromoLive from '../RequireKidsAppPromoLive/RequireKidsAppPromoLive';
import DdGuarantee from '../DirectDebitForm/DdGuarantee';
import KidsAppSubscriptionToggle from '../KidsAppSubscriptionToggle/KidsAppSubscriptionToggle';
import rocksteadyPlayPromo from '../../images/portal/rsplay_feature_wide.png';
import paths from '../../consts/paths';

export default function KidsAppSubscribe() {
  const { state } = useLocation();

  if (!state?.eligibleKids?.length) return <Redirect to={paths.ROOT} />;

  return (
    <RequireKidsAppPromoLive>
      <h2>MANAGE YOUR ROCKSTEADY PLAY SUBSCRIPTION</h2>
      <Image src={rocksteadyPlayPromo} fluid />

      <h3>Benefits of Rocksteady PLAY</h3>
      <p>
        The Rocksteady PLAY app allows your child to extend the benefits of
        their in-person Rocksteady lessons and enhance their learning between
        weekly band lessons. In the app, your child can listen to the exact
        version of the song they’re currently learning, at their level, build
        their very own avatar and collect digital stickers based on their
        in-lesson achievements, if they have an instrument at home, they can
        even play along!
      </p>

      <p>
        Use the tabs below to add or remove your child’s subscription to the
        app. The cost of the app subscription will be added to your regular
        Rocksteady subscription.
      </p>

      <h3>Select YES/NO for each child</h3>

      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Children eligible to use the app
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Rocksteady Play
              <br />
              cost per month
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Manage
              <br />
              Subscription
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {state.eligibleKids.map(({ id, attributes }) => (
            <Table.Row key={id}>
              <Table.Cell>{attributes.name}</Table.Cell>
              <Table.Cell textAlign="center">
                {`£${(
                  attributes.kids_app_timetable_price_pennies / 100
                ).toFixed(2)}`}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <KidsAppSubscriptionToggle
                  id={id}
                  name={attributes.name}
                  price_in_pennies={attributes.kids_app_timetable_price_pennies}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <div>
        <h3>Direct Debit Information</h3>
        <DdGuarantee />

        <p>
          The Rocksteady PLAY subscription cost will be added to your existing
          Monthly Lesson Subscription and is collected as part of your existing
          Direct Debit (details below).
        </p>

        <p>
          Like our existing Lesson Subscription, you are protected by the Direct
          Debit Guarantee.
        </p>

        <p>
          Direct Debits are finalised on the 20th day of each month. If you
          cancel your Rocksteady PLAY subscription on or before the 20th you
          won’t be charged for the app in the following month. If you are
          cancelling your subscription after the 20th you will be charged one
          further monthly subscription.
        </p>
      </div>
    </RequireKidsAppPromoLive>
  );
}
