import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'semantic-ui-react';
import moment from 'moment';
import StringUtils from '../../../utils/StringUtils';

export default class TimetableSummary extends Component {
  static propTypes = {
    timetable: PropTypes.object.isRequired,
    chosenInstrument: PropTypes.string.isRequired,
    formattedYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    changeInstrument: PropTypes.func,
  };

  static defaultProps = {
    changeInstrument: null,
  };

  valueOrTBC = (val) => {
    if (!val) return 'TBC';
    return val;
  };


  dateOrTBC = (date) => {
    if (!date) return 'TBC';
    return moment(date).format('Do MMM YYYY');
  };

  delayedStartConcert = (upcomingConcert) => {
    if (!upcomingConcert) return null;
    return (
      <span>
        <br />
        <br />
        Please Note: All bands are now in final rehearsals for concerts.
        <br />
        <br />
        Your child’s first lesson will now be after the concert. They’ll learn a new song with their
        band, ready for their own moment to shine on stage next term!
      </span>
    );
  };

  changeButton = () => {
    const { changeInstrument } = this.props;
    return (
      changeInstrument && (
        <Button
          size="tiny"
          onClick={changeInstrument}
          compact
          basic
          floated="right"
          content="Change"
        />
      )
    );
  };

  render() {
    const { chosenInstrument, timetable, formattedYear } = this.props;
    const {
      name,
      lessons_are_on,
      regular_monthly_cost,
      first_lesson_date,
      first_payment_date,
      first_payment_amount,
      first_payment_covers_period,
      upcoming_concert,
    } = timetable;
    
    return (
      <Table celled striped unstackable compact>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="summary-column-title">School</Table.Cell>
            <Table.Cell>{name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Year</Table.Cell>
            <Table.Cell>{formattedYear}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Instrument</Table.Cell>
            <Table.Cell>
              {StringUtils.humanize(chosenInstrument)}
              {this.changeButton()}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Regular Monthly Cost</Table.Cell>
            <Table.Cell>{regular_monthly_cost}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Lessons are on</Table.Cell>
            <Table.Cell>{this.valueOrTBC(lessons_are_on)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>First Lesson Date</Table.Cell>
            <Table.Cell>
              {this.dateOrTBC(first_lesson_date)}
              {this.delayedStartConcert(upcoming_concert)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>First Payment Date</Table.Cell>
            <Table.Cell>{this.dateOrTBC(first_payment_date)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>First Payment Amount</Table.Cell>
            <Table.Cell>{this.valueOrTBC(first_payment_amount)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>First Payment Covers</Table.Cell>
            <Table.Cell>{this.valueOrTBC(first_payment_covers_period)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
