import React, { useEffect } from 'react';
import { Form, Message } from 'semantic-ui-react';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';
import DateUtils from '../../utils/DateUtils';
import StringUtils from '../../utils/StringUtils';
import WindowUtils from '../../utils/WindowUtils';
import TimetableSearch from '../TimetableSearch/TimetableSearch';
import SchoolYearDropdown from './SchoolYearDropdown';
import Section from './Section';

export default function KidAndSchoolSection() {
  const {
    kid: { setKidName, kidId, kidName, kidSchoolYear },
    timetable: { timetableId, timetableDisplayName, setTimetableState },
    consts: { CURRENT_ACADEMIC_SCHOOL_YEAR },
  } = useKidRegistration();

  useEffect(() => {
    if (!kidId && kidSchoolYear) WindowUtils.scrollToAnchor('instrument-choices');
  }, [kidSchoolYear]);

  const headingText = `${kidId ? '' : 'enter child & '}school information`

  const handleNameInput = (_e, { value }) => setKidName(value);

  const handleTimetableSelect = (selectedTimetableId, selectedTimetablename) => {
    setTimetableState(prev => ({ ...prev, timetableId: selectedTimetableId, timetableDisplayName: selectedTimetablename }));
  };

  const currentlyBetweenMarchAndSept = () => DateUtils.isBetweenMonths(3, 9);

  const currentSchoolYearMessage = year => (
    <li>{`They are currently in Year ${StringUtils.schoolYearAsString(year)}`}</li>
  );

  const nextSchoolYearMessage = year => (
    <li>{`In September ${CURRENT_ACADEMIC_SCHOOL_YEAR + 1} they will be in Year ${StringUtils.schoolYearAsString(year + 1)}`}</li>
  );

  const kidNameInput = () => (
    <Form.Input
      id="kid-name"
      label="Child's name"
      value={kidName}
      error={timetableId && !kidName}
      onChange={handleNameInput}
      autoComplete="kid-name"
    />
  );

  const schoolYearInfoMessage = () => (
    <Message info className="school-year-message">
      <p><strong>Please check that this is their current school year</strong></p>
      <ul>
        {currentSchoolYearMessage(kidSchoolYear)}
        {nextSchoolYearMessage(kidSchoolYear)}
      </ul>
    </Message>
  );

  return(
    <Section id="kid-and-school-info" sectionNumber={1} headingText={headingText}>
      {!kidId && kidNameInput()}
      <TimetableSearch
        placeholder={timetableDisplayName}
        disabled={!kidName && !timetableId}
        onSelectingTimetable={handleTimetableSelect}
        selectedTimetableId={timetableId}
      />
      {!kidId && <SchoolYearDropdown />}
      {!kidId && currentlyBetweenMarchAndSept() && ![null, -1].includes(kidSchoolYear) && schoolYearInfoMessage()}
    </Section>
  );
}
