/* eslint-disable global-require */
import React from 'react';
import bugsnagReact from '@bugsnag/plugin-react';
import bugsnag from '@bugsnag/js';

// eslint-disable-next-line import/no-mutable-exports
let BugsnagClient;

if (process.env.NODE_ENV === 'test') {
  BugsnagClient = {};
} else {
  BugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appVersion: process.env.REACT_APP_VERSION_NUMBER,
  });
  BugsnagClient.use(bugsnagReact, React);
  BugsnagClient.metaData = {
    props: null
  }

  BugsnagClient.startSession();
}

export default BugsnagClient;
