/* eslint-disable react/prop-types */

import _ from 'lodash';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GrownUpAPI from '../api/GrownUpAPI';
import TimetableAPI from '../api/TimetableAPI';
import OFFERED_INSTRUMENTS from '../consts/instruments';

const NO_INSTRUMENT_PREFERENCE_VALUE = 'no_preference';
const GROWNUP_REQUIRED_FIELDS = Object.freeze(['email', 'phone', 'name', 'address_l1', 'address_city', 'address_postcode', 'comms_marketing']);
const SUCCESS_ACTIONS = Object.freeze({ joinedWaitlist: 'joinedWaitlist', enrolled: 'enrolled' });
const NO_TIMETABLE_PLACES_AVAILABLE = OFFERED_INSTRUMENTS.reduce((obj, instrumentName) => ({ ...obj, [instrumentName]: 0 }), {});

const KidRegistrationContext = createContext();
export const useKidRegistration = () => useContext(KidRegistrationContext);

export default function KidRegistrationProvider({ children, grownUp, onDataInvalidation }) {
  const { state: navState } = useLocation();

  const CURRENT_ACADEMIC_SCHOOL_YEAR = grownUp.attributes.current_school_year;

  const setStateValue = (stateSetter, stateKey, newValue) => {
    stateSetter(prevState => ({ ...prevState, [stateKey]: newValue }));
  };

  const insertValueIntoStateArray = (stateSetter, stateKey, valueToInsert) => {
    stateSetter(prevState => ({ ...prevState, [stateKey]: [...prevState[stateKey], valueToInsert] }));
  };

  // --------------------------- KID ---------------------------

  const initialKidState = {
    kidId: null,
    kidName: '',
    kidSchoolYear: null,
    enrolmentInstrument: null,
    waitlistInstruments: [],
    firstPreferenceInstrument: NO_INSTRUMENT_PREFERENCE_VALUE,
    secondPreferenceInstrument: NO_INSTRUMENT_PREFERENCE_VALUE,
    firstLessonDate: null,
  };

  const [
    {
      kidId,
      kidName,
      kidSchoolYear,
      enrolmentInstrument,
      waitlistInstruments,
      firstPreferenceInstrument,
      secondPreferenceInstrument,
      firstLessonDate,
    },
    setKidState,
  ] = useState(initialKidState);

  const justJoiningWaitlist = useMemo(() => !enrolmentInstrument && !!waitlistInstruments.length, [enrolmentInstrument, waitlistInstruments]);
  const enrollingAndJoiningWaitlist = useMemo(() => enrolmentInstrument && !!waitlistInstruments.length, [enrolmentInstrument, waitlistInstruments]);
  const onWaitlistFor = instrumentName => waitlistInstruments.includes(instrumentName);
  const enrollingFor = instrumentName => enrolmentInstrument === instrumentName;
  const offerFirstPreference = useMemo(() => waitlistInstruments.length > 1, [waitlistInstruments]);
  const offerSecondPreference = useMemo(() => (waitlistInstruments.length > 2), [waitlistInstruments]);
  const firstPreferenceSet = useMemo(() => firstPreferenceInstrument !== initialKidState.firstPreferenceInstrument, [firstPreferenceInstrument]);
  const secondPreferenceSet = useMemo(() => secondPreferenceInstrument !== initialKidState.secondPreferenceInstrument, [secondPreferenceInstrument]);
  const setKidName = (value) => setStateValue(setKidState, 'kidName', value);
  const setKidSchoolYear = (value) => setStateValue(setKidState, 'kidSchoolYear', value);
  const addInstrumentToWaitlistInstruments = (value) => insertValueIntoStateArray(setKidState, 'waitlistInstruments', value);
  const removeInstrumentFromWaitlistInstruments = (value) => (
    setKidState(prev => ({ ...prev, waitlistInstruments: _.without(prev.waitlistInstruments, value) }))
  );
  const resetWaitlistInstruments = () => setStateValue(setKidState, 'waitlistInstruments', initialKidState.waitlistInstruments);
  const setEnrolmentInstrument = (value) => setStateValue(setKidState, 'enrolmentInstrument', value);
  const setAllInstrumentsForWaitlist = () => setStateValue(setKidState, 'waitlistInstruments', OFFERED_INSTRUMENTS);
  const setPreferenceInstrument = (prefNumber, value) => {
    if (prefNumber === 1) {
      setStateValue(setKidState, 'firstPreferenceInstrument', value);
    } else if (prefNumber === 2) {
      setStateValue(setKidState, 'secondPreferenceInstrument', value);
    }
  };

  const handleWaitlistToggleForInstrument = (instrumentName) => {
    if (onWaitlistFor(instrumentName)) {
      removeInstrumentFromWaitlistInstruments(instrumentName);
    } else {
      addInstrumentToWaitlistInstruments(instrumentName);
    }
  };

  // -----------------------------------------------------------
  // ------------------------ TIMETABLE ------------------------

  const initialTimetableState = {
    timetableId: null,
    timetableDisplayName: null,
    availablePlacesCounts: {},
    lessonsAreOn: null,
    minSchoolYear: null,
    maxSchoolYear: null,
    upcomingConcert: null,
  };

  const [
    {
      timetableId,
      timetableDisplayName,
      availablePlacesCounts,
      lessonsAreOn,
      minSchoolYear,
      maxSchoolYear,
      upcomingConcert,
    },
    setTimetableState,
  ] = useState(initialTimetableState);

  const availablePlacesKnown = useMemo(() => !_.isEmpty(availablePlacesCounts), [availablePlacesCounts]);

  const availablePlacesCountsFor = instrumentName => {
    if (instrumentName === 'guitar') {
      return availablePlacesCounts.guitar + availablePlacesCounts.bass;
    }
    return availablePlacesCounts[instrumentName];
  };

  const instrumentsWithAvailablePlaces = useMemo(() => OFFERED_INSTRUMENTS.filter(instrument => !!availablePlacesCountsFor(instrument)), [availablePlacesCounts]);
  const placesAvailable = !!instrumentsWithAvailablePlaces.length;
  const upSellAvailable = placesAvailable && !enrolmentInstrument && !!waitlistInstruments.length;

  // -----------------------------------------------------------
  // ------------------------- PAYMENT -------------------------

  const initialPaymentState = {
    firstPaymentDate: null,
    firstPaymentAmount: null,
    regularMonthlyAmount: null,
    firstPaymentCoversPeriod: null,
    withSiblingsNextPaymentAmount: null,
    withSiblingsRegularPaymentAmount: null,
    withOutstandingBalanceNextPaymentAmount: null,
    withOutstandingBalanceRegularPaymentAmount: null,
    outstandingBalance: null,
  };

  const [
    {
      firstPaymentDate,
      firstPaymentAmount,
      regularMonthlyAmount,
      firstPaymentCoversPeriod,
      withSiblingsNextPaymentAmount,
      withSiblingsRegularPaymentAmount,
      withOutstandingBalanceNextPaymentAmount,
      withOutstandingBalanceRegularPaymentAmount,
      outstandingBalance,
    },
    setPaymentState,
  ] = useState(initialPaymentState);

  const paysForSiblings = !!(withSiblingsNextPaymentAmount && withSiblingsRegularPaymentAmount);
  const directDebitInfoNeeded = !grownUp.attributes.active_direct_debit_or_dd_info;

  // -----------------------------------------------------------
  // -------------------------- PAGE ---------------------------

  const initialPageState = {
    httpRequestPending: false,
    httpErrors: [],
    successAction: null,
    tsAndCsAgreed: false,
    continueToDirectDebitInfo: false,
  };

  const [
    {
      httpRequestPending,
      httpErrors,
      successAction,
      tsAndCsAgreed,
      continueToDirectDebitInfo,
    },
    setPageState,
  ] = useState(initialPageState);

  const httpErrorsExist = !!httpErrors.length;
  const actionSuccessful = !!successAction;
  const readyForSummary = !!enrolmentInstrument;
  const handleTsAndCsToggle = () => setPageState(prev => ({ ...prev, tsAndCsAgreed: !prev.tsAndCsAgreed }));

  const handleHttpRequestCommence = () => setStateValue(setPageState, 'httpRequestPending', true);
  const handleHttpRequestFulfil = () => setStateValue(setPageState, 'httpRequestPending', false);
  const pushToHttpErrors = (newError) => insertValueIntoStateArray(setPageState, 'httpErrors', newError);
  const onEnrolmentSuccess = () => setStateValue(setPageState, 'successAction', SUCCESS_ACTIONS.enrolled);
  const onWaitlistJoinSuccess = () => setStateValue(setPageState, 'successAction', SUCCESS_ACTIONS.joinedWaitlist);
  const handleContinueToDirectDebitInfo = () => setPageState(prev => ({ ...prev, continueToDirectDebitInfo: true }));

  // -----------------------------------------------------------
  // ------------------------ GROWNUP --------------------------

  const profileComplete = useMemo(() => (
    GROWNUP_REQUIRED_FIELDS.every(field => grownUp.attributes[field] && grownUp.attributes[field] !== 'not_set')
  ), [grownUp]);

  // -----------------------------------------------------------

  // This sets the attributes as required for the backend
  const configureKidObject = (props = { withPaymentInfo: false }) => ({
    id: kidId,
    name: kidName,
    year: kidSchoolYear,
    as_of_sep: CURRENT_ACADEMIC_SCHOOL_YEAR,
    timetable_id: timetableId,
    instrument_first_choice: firstPreferenceInstrument,
    instrument_second_choice: secondPreferenceInstrument,
    would_do_drums: enrollingFor('drums') || onWaitlistFor('drums'),
    would_do_vocals: enrollingFor('vocals') || onWaitlistFor('vocals'),
    would_do_keyboard: enrollingFor('keyboard') || onWaitlistFor('keyboard'),
    would_do_guitar: enrollingFor('guitar') || onWaitlistFor('guitar'),
    instrument: enrolmentInstrument,
    firstPaymentDate: props.withPaymentInfo ? firstPaymentDate : undefined,
    firstPaymentAmount: props.withPaymentInfo ? firstPaymentAmount : undefined,
    regularMonthlyAmount: props.withPaymentInfo ? regularMonthlyAmount : undefined,
  });

  const handleTimetablePlacesSearch = () => {
    handleHttpRequestCommence();
    TimetableAPI.getAvailablePlaces(timetableId, kidSchoolYear)
      .then(({ data }) => setTimetableState(prev => ({ ...prev, availablePlacesCounts: data })))
      .catch(() => pushToHttpErrors('Timetable-places query failed'))
      .finally(handleHttpRequestFulfil);
  };

  const handleTimetablePricingFetch = () => {
    handleHttpRequestCommence();
    TimetableAPI.getPricingInfoForGrownUp(timetableId)
      .then(({ data: { timetable_pricing_info, pricing_with_enrolled_siblings, pricing_with_outstanding_balance } }) => {
        if (timetable_pricing_info) {
          setTimetableState(prev => ({
            ...prev,
            lessonsAreOn: timetable_pricing_info.lessons_are_on,
            upcomingConcert: timetable_pricing_info.upcoming_concert,
            minSchoolYear: timetable_pricing_info.min_year,
            maxSchoolYear: timetable_pricing_info.max_year,
          }));
          setStateValue(setKidState, 'firstLessonDate', timetable_pricing_info.first_lesson_date);
          setPaymentState(prev => ({
            ...prev,
            regularMonthlyAmount: timetable_pricing_info.regular_monthly_cost,
            firstPaymentDate: timetable_pricing_info.first_payment_date,
            firstPaymentAmount: timetable_pricing_info.first_payment_amount,
            firstPaymentCoversPeriod: timetable_pricing_info.first_payment_covers_period,
            withSiblingsNextPaymentAmount: pricing_with_enrolled_siblings && pricing_with_enrolled_siblings.next_payment_amount,
            withSiblingsRegularPaymentAmount: pricing_with_enrolled_siblings && pricing_with_enrolled_siblings.regular_monthly_amount,
            outstandingBalance: pricing_with_outstanding_balance && pricing_with_outstanding_balance.outstanding_balance,
            withOutstandingBalanceNextPaymentAmount:  pricing_with_outstanding_balance && pricing_with_outstanding_balance.next_payment_amount,
            withOutstandingBalanceRegularPaymentAmount:  pricing_with_outstanding_balance && pricing_with_outstanding_balance.regular_monthly_amount,
          }));
        }
      })
      .catch(() => pushToHttpErrors('Timetable-pricing query failed'))
      .finally(handleHttpRequestFulfil);
  };

  const handleJoinWaitlist = () => {
    handleHttpRequestCommence();
    const requestConfig = {
      data: { kid_action: 'join_waiting_list', kid: configureKidObject() },
    };
    GrownUpAPI.enrolAKidOrJoinWaitingList(grownUp.id, requestConfig)
      .then(onWaitlistJoinSuccess)
      .catch(() => pushToHttpErrors('Waitlist join request failed'))
      .finally(handleHttpRequestFulfil);
  };

  const handleEnrol = () => {
    handleHttpRequestCommence();
    const requestConfig = {
      data: { kid_action: 'enrol', kid: configureKidObject(), rejoin: !!kidId },
    };
    GrownUpAPI.enrolAKidOrJoinWaitingList(grownUp.id, requestConfig)
      .then(onEnrolmentSuccess)
      .catch(({ response: { data }}) => pushToHttpErrors(data.error || 'Enrolment failed'))
      .finally(handleHttpRequestFulfil);
  };

  // -----------------------------------------------------------

  const resetKidInstrumentState = () => {
    setKidState(prev => (
      {
        ...prev,
        enrolmentInstrument: initialKidState.enrolmentInstrument,
        waitlistInstruments: initialKidState.waitlistInstruments,
        firstPreferenceInstrument: initialKidState.firstPreferenceInstrument,
        secondPreferenceInstrument: initialKidState.secondPreferenceInstrument,
      }
    ));
  };

  const resetKidInstrumentPreferenceState = () => {
    setKidState(prev => (
      {
        ...prev,
        firstPreferenceInstrument: initialKidState.firstPreferenceInstrument,
        secondPreferenceInstrument: initialKidState.secondPreferenceInstrument,
      }
    ));
  };

  const resetKidState = () => setKidState(initialKidState);
  const resetTimetableState = () => setTimetableState(initialTimetableState);
  const resetPaymentState = () => setPaymentState(initialPaymentState);
  const resetPageState = () => setPageState(initialPageState);

  const resetState = () => {
    resetKidState();
    resetTimetableState();
    resetPaymentState();
    resetPageState();
  };

  // ------------------------- HOOKS ---------------------------

  // If a kidId is provided via navigation state, we wanna set state up to reflect the
  // kid in question (if found).
  // A kidId will be provided as part of the service to offer the 'rejoining' of a kid
  // we already store data for (e.g., if previously cancelled, or reservation expired etc.).
  useEffect(() => {
    if (navState && navState.kid) {
      const { id, attributes: { name, year, timetable_id, timetable_name, school_urn } } = navState.kid;
      setKidState({ ...initialKidState, kidId: id, kidName: name, kidSchoolYear: year });
      // If the urn doesnt exist, it means the school may have been deleted
      // so we only want to set timetable data if we know the school remains applicable.
      if (school_urn) {
        setTimetableState({ ...initialTimetableState, timetableId: timetable_id, timetableDisplayName: timetable_name });
      }
    }
  }, [navState]);

  // When the timetableId changes, we want a request initiated to go fetch pricing details for the timetable.
  useEffect(() => {
    if (timetableId) handleTimetablePricingFetch();
  }, [timetableId]);

  // When the timetableId and/or kidSchoolYear value changes, we want a request initiated to go
  // fetch available places in bands for the selected combo.
  useEffect(() => {
    if (timetableId && kidSchoolYear !== null) {
      if (kidSchoolYear >= 0) {
        handleTimetablePlacesSearch();
      // If the selected year is 'Starts Reception in September...', i.e., they havent started school yet,
      // then we basically want to spoof there being no places available so the kid can join the waitlist.
      } else if (kidSchoolYear === -1) {
        setStateValue(setTimetableState, 'availablePlacesCounts', NO_TIMETABLE_PLACES_AVAILABLE)
      }
      resetKidInstrumentState();
    }
  }, [timetableId, kidSchoolYear]);

  // If a first preference is set but, due to other changes, should no longer offer an option
  // to set it, reset it. In cases where the Kid is enrolling and joining the waitlist, we want
  // to preserve the instrument first choice as that is the instrument they will be placed
  // on the waitlist for.
  useEffect(() => {
    if (!offerFirstPreference && firstPreferenceSet && !enrollingAndJoiningWaitlist) {
      setStateValue(setKidState, 'firstPreferenceInstrument', initialKidState.firstPreferenceInstrument);
    }
  }, [offerFirstPreference, firstPreferenceSet, enrollingAndJoiningWaitlist]);

  // If a second preference is set but, due to other changes, should no longer offer an option
  // to set it, reset it.
  useEffect(() => {
    if (!offerSecondPreference && secondPreferenceSet) {
      setStateValue(setKidState, 'secondPreferenceInstrument', initialKidState.secondPreferenceInstrument);
    }
  }, [offerSecondPreference, secondPreferenceSet]);

  // If the first preference is not set but the second preference is set, reset the second preference.
  useEffect(() => {
    if (!firstPreferenceSet && secondPreferenceSet) {
      setStateValue(setKidState, 'secondPreferenceInstrument', initialKidState.secondPreferenceInstrument);
    }
  }, [firstPreferenceSet, secondPreferenceSet]);

  // If upon first prefrence change, and first and second are equal, reset the second preference.
  useEffect(() => {
    if (firstPreferenceSet && secondPreferenceSet && firstPreferenceInstrument === secondPreferenceInstrument) {
      setStateValue(setKidState, 'secondPreferenceInstrument', initialKidState.secondPreferenceInstrument);
    }
  }, [firstPreferenceInstrument]);

  // When it is deemed the user should not be offered a choice of instrument preference, reset both values.
  useEffect(() => {
    if (!offerFirstPreference) resetKidInstrumentPreferenceState();
  }, [offerFirstPreference]);

  // If the waitlist instruments change and the first preference is no longer one of the instruments, reset it
  useEffect(() => {
    if (firstPreferenceSet && !onWaitlistFor(firstPreferenceInstrument)) {
      setStateValue(setKidState, 'firstPreferenceInstrument', initialKidState.firstPreferenceInstrument);
    }
  }, [waitlistInstruments]);

  // If the waitlist instruments change and the second preference is no longer one of the instruments, reset it
  useEffect(() => {
    if (secondPreferenceSet && !onWaitlistFor(secondPreferenceInstrument)) {
      setStateValue(setKidState, 'secondPreferenceInstrument', initialKidState.secondPreferenceInstrument);
    }
  }, [waitlistInstruments]);

  // When an action (enrol/waitlist join) has been successful, call prop callback to invalidate grownup data.
  useEffect(() => {
    if (actionSuccessful) onDataInvalidation();
  }, [actionSuccessful])

  const state = {
    resetState,
    page: {
      httpRequestPending,
      httpErrors,
      httpErrorsExist,
      readyForSummary,
      tsAndCsAgreed,
      handleTsAndCsToggle,
      successAction,
      actionSuccessful,
      continueToDirectDebitInfo,
      handleContinueToDirectDebitInfo,
    },
    kid: {
      kidId,
      kidName,
      setKidName,
      kidSchoolYear,
      setKidSchoolYear,
      enrolmentInstrument,
      setEnrolmentInstrument,
      waitlistInstruments,
      setAllInstrumentsForWaitlist,
      handleWaitlistToggleForInstrument,
      addInstrumentToWaitlistInstruments,
      resetWaitlistInstruments,
      resetKidInstrumentState,
      firstPreferenceInstrument,
      offerFirstPreference,
      firstPreferenceSet,
      secondPreferenceInstrument,
      offerSecondPreference,
      setPreferenceInstrument,
      enrollingFor,
      onWaitlistFor,
      justJoiningWaitlist,
      enrollingAndJoiningWaitlist,
      firstLessonDate,
      configureKidObject,
    },
    timetable: {
      setTimetableState,
      timetableId,
      availablePlacesCounts,
      availablePlacesCountsFor,
      availablePlacesKnown,
      instrumentsWithAvailablePlaces,
      upSellAvailable,
      placesAvailable,
      timetableDisplayName,
      lessonsAreOn,
      minSchoolYear,
      maxSchoolYear,
      upcomingConcert,
      handleJoinWaitlist,
      handleEnrol,
    },
    payment: {
      regularMonthlyAmount,
      firstPaymentDate,
      firstPaymentAmount,
      firstPaymentCoversPeriod,
      withSiblingsNextPaymentAmount,
      withSiblingsRegularPaymentAmount,
      paysForSiblings,
      directDebitInfoNeeded,
      withOutstandingBalanceNextPaymentAmount,
      withOutstandingBalanceRegularPaymentAmount,
      outstandingBalance,
    },
    grownUp: { profileComplete },
    consts: { NO_INSTRUMENT_PREFERENCE_VALUE, SUCCESS_ACTIONS, CURRENT_ACADEMIC_SCHOOL_YEAR },
  };

  return <KidRegistrationContext.Provider value={state}>{children}</KidRegistrationContext.Provider>;
}
