import React from 'react';
import { Form, Checkbox, Button, Table } from 'semantic-ui-react';
import CookieUtils from '../../utils/CookiesUtils';
import PageHeading from '../PageHeading';

class CookiePolicy extends React.Component {
  state = {
    functionalityConsentGiven: false,
    analyticsConsentGiven: false,
  }

  componentDidMount() {
    this.setState({
      functionalityConsentGiven: CookieUtils.functionalityConsentGiven(),
      analyticsConsentGiven: CookieUtils.analyticsConsentGiven(),
    });
  }

  toggleAnalyticsConsent = () => {
    this.setState((oldState) => {
      return { analyticsConsentGiven: !oldState.analyticsConsentGiven }
    });
  }

  toggleFunctionalityConsent = () => {
    this.setState((oldState) => {
      return { functionalityConsentGiven: !oldState.functionalityConsentGiven }
    });
  }

  updatePreferences = () => {
    const { analyticsConsentGiven, functionalityConsentGiven } = this.state;
    CookieUtils.saveAnalyticsPreferences(analyticsConsentGiven);
    CookieUtils.saveFunctionalityPreferences(functionalityConsentGiven);
    window.location.reload();
  }

  render() {
    const { functionalityConsentGiven, analyticsConsentGiven } = this.state;

    return (
      <React.Fragment>
        <PageHeading heading="Cookie Policy" />
        <h3>What is a cookie?</h3>
        <p>
          A cookie is a small file that can be placed on your device that
          allows us to recognise and remember you. When you visit our site, we
          may collect information from you automatically through cookies.
        </p>

        <h3>What happens if I disable cookies?</h3>
        <p>
          If you disable cookies, that will mean that your
          experience of using our website won’t be to its
          full potential, as some of the cookies help us to
          identify and resolve errors or display relevant content.
        </p>

        <h3>Your preferences</h3>
        <Form>
          <Form.Field
            control={Checkbox}
            checked={analyticsConsentGiven}
            label="Enable optional analytics cookies"
            onChange={this.toggleAnalyticsConsent}
          />
          <Form.Field
            control={Checkbox}
            checked={functionalityConsentGiven}
            label="Enable optional site functionality cookies"
            onChange={this.toggleFunctionalityConsent}
          />
          <Button type='submit' positive onClick={this.updatePreferences}>Update Preferences</Button>
        </Form>

        <h3>What type of cookies do we use?</h3>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Cookie</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Purpose</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Analytics Consent</Table.Cell>
              <Table.Cell>analytics_consent</Table.Cell>
              <Table.Cell>Essential</Table.Cell>
              <Table.Cell>
                This cookie is used to remember your choice about
                analytics cookies on your device. If you have previously
                indicated a preference, it will be stored in this cookie.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Functionality Consent</Table.Cell>
              <Table.Cell>functionality_consent</Table.Cell>
              <Table.Cell>Essential</Table.Cell>
              <Table.Cell>
                This cookie is used to remember your choice about
                site functionality cookies on your device. These cookies enhance your
                experience of the site. If you have previously
                indicated a preference, it will be stored in this cookie.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Lowdown Video Cookie</Table.Cell>
              <Table.Cell>_lowdown_seen</Table.Cell>
              <Table.Cell>Optional</Table.Cell>
              <Table.Cell>
                This cookie is used to remember if you have watched our
                weekly lowdown video and if it has been set we will not
                show it to you in a pop up.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Vimeo</Table.Cell>
              <Table.Cell>
                __qca,
                __ssid,
                _abexps,
                _delighted_web,
                _fbp,
                _ga,
                _gcl_au,
                _mkto_trk,
                _uetvid,
                afUserId,
                auth_redirect,
                continuous_play_v3,
                has_logged_in,
                is_logged_in,
                sd_client_id,
                vimeo,
                vimeo_gdpr_optin,
                player,
                vuid
              </Table.Cell>
              <Table.Cell>Optional</Table.Cell>
              <Table.Cell>
                We embed videos from our official Vimeo channel. When
                you press play Vimeo will drop third party cookies
                to enable the video to play and to collect analytics
                data such as how long a viewer has watched the video.
                These cookies do not track individuals. See
                {' '}
                <a href="https://vimeo.com/cookie_policy" target="blank">Vimeo Cookie Policy</a>
                {' '}
                for more info.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Google Analytics</Table.Cell>
              <Table.Cell>
                _ga,  _ga_&lt;container-id&gt;
              </Table.Cell>
              <Table.Cell>Optional</Table.Cell>
              <Table.Cell>
                We use Google Analytics to monitor how people use our websites and digital systems, for
                example, to see which pages are most popular. Google Analytics uses cookies to understand
                when users return to our sites. We use Google Analytics to track anonymous behaviour of
                users, and not to track individuals.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

export default CookiePolicy;
