import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import TimetableAPI from '../../api/TimetableAPI';

class TimetableSearch extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onSelectingTimetable: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    selectedTimetableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    disabled: undefined,
    placeholder: null,
    selectedTimetableId: null,
  }

  constructor() {
    super();
    this.state = {
      searchQuery: '',
      options: [],
      selectedTimetableId: null,
      placeholder: null,
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

  componentDidUpdate(prevProps) {
    const { placeholder } = this.props;
    if (prevProps.placeholder !== placeholder) {
      this.setInitialState();
    }
  }

  setInitialState = () => {
    const { placeholder, selectedTimetableId } = this.props;
    this.setState({ placeholder, selectedTimetableId });
  }

  mapResponseToOptions = data => data.map(timetable => ({
    key: `timetable-key-${timetable.id}`,
    value: timetable.id,
    text: timetable.name,
  }));

  search = () => {
    const { searchQuery } = this.state;
    TimetableAPI.searchTimetables(searchQuery)
      .then((response) => {
        this.setState({
          options: this.mapResponseToOptions(response.data),
        });
      })
      .catch(() => {});
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.setState({ searchQuery, placeholder: null }, () => {
      if (searchQuery.length > 2) {
        this.search();
      } else {
        this.setState({ options: [] });
      }
    });
  };

  findTimetableName = (id) => {
    const { options } = this.state;
    const timetable = options.filter(option => option.value === id)[0];
    return timetable.text;
  };

  handleChange = (e, { value }) => {
    const { onSelectingTimetable } = this.props;
    if (value) {
      const timetableName = this.findTimetableName(value);
      this.setState(
        {
          selectedTimetableId: value,
          searchQuery: '',
        },
        () => onSelectingTimetable(value, timetableName),
      );
    }
  };

  // Return all the options regardless of the query
  // this is filtered at a server level
  searchAll = options => options;

  message = () => {
    const { searchQuery } = this.state;
    if (searchQuery.length < 3) {
      return 'Type school name...';
    }
    return 'No results found';
  };

  render() {
    const { searchQuery, selectedTimetableId, options, placeholder } = this.state;
    const { disabled } = this.props;
    const query = placeholder && !searchQuery ? placeholder : searchQuery;
    return (
      <Form.Dropdown
        disabled={disabled}
        fluid
        onChange={this.handleChange}
        onSearchChange={this.handleSearchChange}
        options={options}
        search={this.searchAll}
        searchQuery={query}
        selection
        value={selectedTimetableId}
        icon="search"
        selectOnNavigation={false}
        noResultsMessage={this.message()}
        label="School"
      />
    );
  }
}

export default TimetableSearch;
