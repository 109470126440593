import React, { Component } from 'react';
import {
  Form, Button, Grid, Message,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import paths from '../../../consts/paths';
import warnings from '../../../consts/warnings';
import StringUtils from '../../../utils/StringUtils';
import WindowUtils from '../../../utils/WindowUtils';
import SchoolUserAPI from '../../../api/SchoolUserAPI';

class SetPassword extends Component {
  static propTypes = {
    signedIn: PropTypes.bool.isRequired,
    onSigningIn: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      showValidationErrors: false,
      problemUpdatingPassword: false,
      loading: false,
    };
  }

  handlePasswordChange = (event) => {
    this.setState({ showValidationErrors: false, password: event.target.value });
  };

  handlePasswordConfirmationChange = (event) => {
    this.setState({ showValidationErrors: false, passwordConfirmation: event.target.value });
  };

  setPasswordForSchoolUser = () => {
    this.setState(
      {
        showValidationErrors: true,
      },
      () => {
        if (this.formValid()) {
          this.setState(
            {
              loading: true,
            },
            () => {
              const { password, passwordConfirmation } = this.state;
              const config = {
                data: {
                  school_user: {
                    reset_password_token: this.tokenFromUrl(),
                    password,
                    password_confirmation: passwordConfirmation,
                  },
                },
              };
              this.setPassword(config);
            },
          );
        }
      },
    );
  };

  setPassword = async (config) => {
    const { onSigningIn } = this.props;
    try {
      const response = await SchoolUserAPI.setPasswordForSchoolUser(config);
      const email = response.data.message;
      const { password } = this.state;
      const signInConfig = {
        data: {
          school_user: {
            email,
            password,
          },
        },
      };
      const signInResponse = await SchoolUserAPI.signIn(signInConfig);
      const { authorization } = signInResponse.headers;
      onSigningIn(authorization);
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ problemUpdatingPassword: true, loading: false });
    }
    this.setState({
      showValidationErrors: false,
    });
  };

  passwordIsNotComplexEnough = password => StringUtils.passwordIsNotComplexEnough(password);

  formValid = () => {
    const { password, passwordConfirmation } = this.state;
    if (!password || !passwordConfirmation) {
      return false;
    }
    if (password !== passwordConfirmation) {
      return false;
    }
    if (this.passwordIsNotComplexEnough(password)) {
      return false;
    }
    return true;
  };

  validationMessage = () => {
    const { showValidationErrors } = this.state;
    if (showValidationErrors && !this.formValid()) {
      return <Message negative>{warnings.PASSWORD_FORMAT}</Message>;
    }
    return null;
  };

  redirect = () => {
    const { signedIn } = this.props;
    return signedIn && <Redirect push to={`${paths.SCHOOLS_ROOT}`} />;
  };

  errorMessage = () => {
    const { problemUpdatingPassword } = this.state;
    return (
      problemUpdatingPassword && (
        <Message
          negative
          header="Problem updating password"
          content={warnings.CONTACT_THE_OFFICE}
        />
      )
    );
  };

  tokenFromUrl = () => {
    const urlParams = StringUtils.parseQueryString(WindowUtils.anyParams());
    const { token } = urlParams;
    return token;
  };

  render() {
    const {
      password, passwordConfirmation, showValidationErrors, loading,
    } = this.state;
    if (this.tokenFromUrl()) {
      return (
        <Grid relaxed padded>
          <Grid.Row centered>
            <Message info>
              <Message.Content>
                <Message.Header>Please set a new password below!</Message.Header>
                Password should be over 8 characters long and include at least one lowercase letter,
                uppercase letter and digit.
              </Message.Content>
            </Message>
          </Grid.Row>
          <Grid.Row centered>
            <Form onSubmit={this.setPasswordForSchoolUser} loading={loading}>
              {this.redirect()}
              {this.validationMessage()}
              {this.errorMessage()}
              <Form.Input
                onChange={this.handlePasswordChange}
                placeholder="Password"
                value={password}
                type="password"
                error={showValidationErrors && !this.formValid()}
              />
              <Form.Input
                onChange={this.handlePasswordConfirmationChange}
                placeholder="Confirm Password"
                value={passwordConfirmation}
                type="password"
                error={showValidationErrors && !this.formValid()}
              />
              <Button size="huge" type="submit" positive>
                Set Password
              </Button>
            </Form>
          </Grid.Row>
        </Grid>
      );
    }
    return <Message>Missing token</Message>;
  }
}

export default SetPassword;
