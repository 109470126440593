import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { ReactSVG } from 'react-svg';
import Vimeo from "@u-wave/react-vimeo";
import ToastMessage from '../ToastMessage/ToastMessage';
import BandMediaHelpModal from '../BandMediaHelpModal/BandMediaHelpModal';
import BandMediaAPI from '../../api/BandMediaAPI';
import reportIcon from './assets/report.svg';
import contacts from '../../consts/contacts';
import CookieUtils from '../../utils/CookiesUtils';
import './Slides.scss';

const SlideVideoItem = ({
  items,
  containerClass,
  muted,
  triggerAudioTrackPause,
  kidId,
  grownUpId,
  isReportable
}) => {
  if (!items) return null;
  const item = items.filter((i) => Object.keys(i).includes('video'))[0];
  const [playerOpen, setPlayerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportModelOpen, setReportModelOpen] = useState(false);
  const [toastSuccess, setToastSuccess] = useState(false);
  const [displayToastMessage, setDisplayToastMessage] = useState(null);

  const imageCaption = isReportable && items.filter((i) => Object.keys(i).includes('body-strong'))[0];
  const bandMediaSubmissionID = isReportable && items.filter((i) => Object.keys(i).includes('report'))[0]?.report;
  
  // REPORT OBJECT (layout required for api)
  const reportConfig = (comment) => {
    const config = {
        data: {
          band_media_discrepancy_report: {
            comment,
            band_media_submission_id: bandMediaSubmissionID,
            kid_id: kidId,
            grown_up_id: grownUpId
         }
       }
    };
    return config;
  }

  // UPDATE MESSAGE FROM REPORT
  const setToastType = (result) => {
    setDisplayToastMessage(true);
    setToastSuccess(result);
  }

  // CLOSE REPORT MODEL
  const resetModal = () => {
    setIsLoading(false);
    setReportModelOpen(false)
  }

  // TRIGGER REPORT API
  const reportMedia = (comment) => {
    const config = reportConfig(comment);
    BandMediaAPI.reportMediaDiscrepancy(config).then(() => {
      setToastType(true);
    }).catch(() => setToastType(false)).finally(() => resetModal());
  }

  // OPEN REPORT MODEL
  const triggerReport = () => {
    setReportModelOpen(true)
  }

  const triggerOpen = () => {
    triggerAudioTrackPause(true)
    setPlayerOpen(true)
  }

  const triggerClose = () => {
    setPlayerOpen(false)
    triggerAudioTrackPause(false)
  }

  const renderToastMessage = () => {
    if (!displayToastMessage) { return null }
    let content = (
      <ToastMessage
        title="Something went wrong"
        body={`Please try again in a moment or contact us by phone on: ${contacts.SUPPORT_TEL_HUMANIZED}`}
        iconName="exclamation"
        iconColor="#942d29"
        backgroundColor="#ff8985"
        textColor="#521715"
      />
    )

    if (toastSuccess) {
      content = <ToastMessage title="Thanks for reporting" body="A member of staff will review your report shortly." />
    } 
    return (
      <div className={`${containerClass}__reportMessage`}>{content}</div>
    )
  }

  const renderThumbnailAndCaption = () => {
    const body = imageCaption['body-strong'];
    return (
      <button
        className={`${containerClass}__video__info`}
        onClick={triggerOpen}
        type='button'
      >
        <div
          className={`${containerClass}__video__reportableThumbnail`}
        >
          <Vimeo
            video={item.video}
            autoplay={false}
            muted={muted}
            responsive
            controls={false}
          />
        </div>
        <p className={`${containerClass}__video__caption`}>{body}</p>
      </button>
    )
  }

  const renderVideoTools = () => {
    if (!isReportable) return null;
    return (
      <button
        className={`${containerClass}__video__report`}
        type='button'
        onClick={triggerReport}
      >
        <ReactSVG src={reportIcon} />
      </button>
    )
  }

  const renderThumbnail = () => {
    return (
      <div className={`${containerClass}__video`}>
        {isReportable ? renderThumbnailAndCaption() : (
          <button
            className={`${containerClass}__video__thumbnail`}
            style={{
              backgroundImage: `url(${item.thumbnail_src})`
            }}
            onClick={triggerOpen}
            type='button'
          />
         )}
        {renderVideoTools()}
      </div>
    )
  }

  const renderVideo = () => {
    return (
      <Modal 
        open 
        onClose={triggerClose} 
        closeIcon 
        className={`${containerClass}__video__player`}
        transition='scale'
      >
        <Modal.Content>
          <Vimeo
            video={item.video}
            autoplay
            muted={muted}
            loop
            responsive
            controls
            dnt={!CookieUtils.analyticsConsentGiven()}
          />

        </Modal.Content>
      </Modal>
    )
  }

  return (
    <>
      {!displayToastMessage && !playerOpen && !toastSuccess && renderThumbnail()}
      {playerOpen && renderVideo()}
      {isReportable && (
        <BandMediaHelpModal
          displayModal={reportModelOpen}
          closeModal={() => setReportModelOpen(false)}
          onReport={reportMedia}
          isLoading={isLoading}
          setLoadingState={setIsLoading}
        />
      )}
      {!playerOpen && isReportable && renderToastMessage()}
    </>
  )
}

export default SlideVideoItem;

SlideVideoItem.propTypes = {
  muted: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  containerClass: PropTypes.string.isRequired,
  triggerAudioTrackPause: PropTypes.func.isRequired,
  grownUpId: PropTypes.string,
  kidId: PropTypes.string,
  isReportable: PropTypes.bool,
}

SlideVideoItem.defaultProps = {
  muted: false,
  grownUpId: null,
  kidId: null,
  isReportable: false
}
