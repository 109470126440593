import React, { Component } from 'react';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import GrownUpAPI from '../../api/GrownUpAPI';
import PublicAPI from '../../api/PublicAPI';
import DdGuarantee from './DdGuarantee';
import warnings from '../../consts/warnings';
import contacts from '../../consts/contacts';

class DirectDebitForm extends Component {
  requiredFields = [
    'firstName',
    'lastName',
    'email',
    'accountName',
    'accountNumber',
    'accountSortCode',
  ]

  static propTypes = {
    grownUpId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    grownUpAttributes: PropTypes.object,
    kidParams: PropTypes.object.isRequired,
    refreshAllTheThings: PropTypes.func.isRequired,
    onErrorReceived: PropTypes.func.isRequired,
    onBankDetailsValidated: PropTypes.func.isRequired,
    displayOutstandingBalance: PropTypes.bool
  };

  static defaultProps = {
    grownUpAttributes: {},
    displayOutstandingBalance: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      firstName: '',
      lastName: '',
      email: '',
      accountName: '',
      accountNumber: '',
      accountSortCode: '',
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      accountNameError: null,
      accountSortCodeError: null,
      accountNumberError: null,
      loading: false,
    };
  }

  componentDidMount() {
    const { grownUpAttributes } = this.props;
    const { first_name, last_name, name, email, billing_account } = grownUpAttributes;

    let safeName = ''
    if (name) {
      safeName = name.substring(0, 17);
    };

    this.setState({
      firstName: first_name,
      lastName: last_name,
      accountName: safeName,
      email,
      billingAccount: billing_account,
    });
  }

  riskFreeMessage = () => (
    <Message positive>
      <Message.Header>Risk free</Message.Header>
      <Message.Content>
        Rocksteady is a risk free subscription service with no minimum commitment - if you cancel
        within the first month you won’t be charged anything, you can also cancel at any point
        after this, just let us know by the 20th of any month and your payments will be stopped.
      </Message.Content>
    </Message>
  );

  formField = ({ label, name, type, placeholder } = {}) => {
    const { showValidationErrors } = this.state;
    const { [name]: value } = this.state;

    return (
      <Form.Field key={name}>
        <Form.Input
          label={label}
          value={value}
          name={name}
          onChange={this.handleInputChange(name)}
          type={type}
          placeholder={placeholder}
          error={showValidationErrors && !this.fieldIsValid(name)}
        />
      </Form.Field>
    );
  };

  handleInputChange = (e, { value }) => this.setState({ [e.target.name]: value });

  enrolKid = () => {
    const { kidParams } = this.props;

    return new Promise((resolve) => {
      if (_.isEmpty(kidParams)) return resolve(null);

      if (kidParams.reservedUntil) {
        return this.enrolReservedKid().then(() => resolve())
      }

      return this.enrolNewKid().then(() => resolve());
    })

  };

  enrolNewKid = () => {
    const { grownUpId, kidParams, refreshAllTheThings, onErrorReceived } = this.props;
    const config = {
      data: {
        kid_action: 'enrol',
        kid: kidParams,
        rejoin: !!kidParams.id,
      },
    };

    this.setState({ loading: true });

    return new Promise((resolve) => {
      GrownUpAPI.enrolAKidOrJoinWaitingList(grownUpId, config)
      .then(() => {
        this.setState({ loading: false });
        refreshAllTheThings();
        resolve();
      })
      .catch(error => {
        this.setState({
          loading: false,
        });
        onErrorReceived(error.response.data.error)
        resolve();
      });
    });
  }

  enrolReservedKid = () => {
    const { refreshAllTheThings, onErrorReceived, kidParams, grownUpId } = this.props;

    const config = {
      data: {
        kid_action: 'enrol',
        kid: {
          as_of_sep: new Date().getFullYear(),
        },
      },
    };

    this.setState({loading: true});

    return new Promise((resolve) => {
      GrownUpAPI.enrolReservedKid(kidParams.id, grownUpId, config)
      .then(() => {
        this.setState({ loading: false });
        refreshAllTheThings();
        resolve();
      })
      .catch(error => {
        onErrorReceived(error.data);
        this.setState({ loading: false });
        resolve();
      });
    });
  }

  firstAmountPennies = () => {
    const { kidParams } = this.props;

    if (kidParams.firstPaymentAmount) {
      return kidParams.firstPaymentAmount.replace("£", '').replace(".", '');
    }

    return '';
  }

  defaultAmountPennies = () => {
    const { kidParams } = this.props;

    if (kidParams.regularMonthlyAmount) {
      return kidParams.regularMonthlyAmount.replace("£", '').replace(".", '');
    }

    return '';
  }

  bankAddressFromData = (bankInfo) => (
    `${bankInfo.bank_name}, ${bankInfo.branch}, ${bankInfo.address1}, ${bankInfo.town}, ${bankInfo.county}, ${bankInfo.postcode}`
  )

  validateWithSmartDebit = () => {
    const { grownUpId, refreshAllTheThings } = this.props;
    const {
      accountName,
      accountNumber,
      accountSortCode,
      firstName,
      lastName,
      email,
    } = this.state;

    const params = {
      data: {
        bank_details: {
          grown_up_id: grownUpId,
          account_name: accountName,
          email_address: email,
          account_number: accountNumber,
          sort_code: accountSortCode,
          first_name: firstName,
          last_name: lastName,
          regular_rate_in_pennies: this.defaultAmountPennies(),
          first_amount_in_pennies: this.firstAmountPennies(),
        },
      }
    };

    return new Promise((resolve) => {
      PublicAPI.validateBankDetails(params)
        .then((response) => {
          const { data } = response;

          if (data.errors) {
            this.setState(prevState => ({
              errors: [
                data.errors.error,
                ...prevState.errors,
              ],
            }), () => resolve(false));
          }
          if (data.successful) {
            this.enrolKid().then(() => {
              // Don't send in the BACS Reference, it runs the risk of duplicates
              // and the GU has not seen it yet, so no problem
              PublicAPI.createDdFromBankDetails(params).then((createResponse) => {
                const createData = createResponse.data;

                if (createData.errors) {
                  this.setState(prevState => ({
                    errors: [
                      createData.errors,
                      ...prevState.errors,
                    ],
                  }), () => resolve(false));
                }
                if (createData.successful) {
                  const { onBankDetailsValidated } = this.props;
                  const bankAddress = this.bankAddressFromData(data.successful.success[2]);
                  onBankDetailsValidated({
                    accountName,
                    accountNumber,
                    accountSortCode,
                    bankAddress,
                    bacsReference: createData.bacs_reference,
                  });
                  refreshAllTheThings();
                  this.setState(
                    {
                      loading: false,
                    }, () => {
                      resolve(true);
                    },
                  );
                }
              })
              .catch((err) => {
                this.setState({
                  errors: err,
                  loading: false,
                }, () => resolve(false));
              });
            })

          } else {
            this.setState({
              errors: [data.errors.error],
              loading: false,
            }, () => resolve(false));
          }
        })
        .catch(() => {
          this.setState({ errors: [this.errorText()] }, () => resolve(false))
        });
    });
  }

  errorText = () => (
    `We were not able to create the Direct Debit.`
  );

  handleSubmit = () => {
    this.resetErrors();
    if (this.validateFormInputs()) {
      this.setState({ loading: true }, () => {
        this.validateWithSmartDebit().then((valid) => {
          if (!valid) {
            this.setState({ loading: false });
          }
        });
      });
    }
  }

  formErrorMessage = () => {
    const { errors } = this.state;

    if (errors.length > 0) {
      const items = errors.map(err => <li key={err}>{err}</li>);
      return (
        <Message negative>
          <Message.Header>Error validating your details</Message.Header>
          <p>
            Please check the bank account number
            and branch sort code, then try again.
          </p>
          <p>
            If they are correct,
            {' '}
            {warnings.CONTACT_THE_OFFICE}
          </p>
          <p>Errors:</p>
          <ul>
            {items}
          </ul>
        </Message>
      );
    }
    return null;
  }

  fields = () => {
    const {
      firstName,
      lastName,
      email,
      accountName,
      accountNumber,
      accountSortCode,
      firstNameError,
      lastNameError,
      emailError,
      accountNameError,
      accountSortCodeError,
      accountNumberError,
    } = this.state;

    return (
      <div>
        <Form.Field>
          <Form.Input
            name="firstName"
            type="text"
            required
            label="First name"
            onChange={this.handleInputChange}
            value={firstName}
            error={firstNameError}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            name="lastName"
            type="text"
            required
            label="Last name"
            onChange={this.handleInputChange}
            value={lastName}
            error={lastNameError}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            name="email"
            type="text"
            required
            label="Email address for receipts"
            onChange={this.handleInputChange}
            value={email}
            error={emailError}
          />
        </Form.Field>
        <h3>Bank Details</h3>
        <Form.Field>
          <Form.Input
            name="accountName"
            type="text"
            required
            label="Full name of account holder (Maximum 18 characters)"
            onChange={this.handleInputChange}
            value={accountName}
            error={accountNameError}
            maxLength={18}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            name="accountNumber"
            type="text"
            required
            label="Bank / Building society Account number"
            placeholder="numbers only, no spaces or -"
            onChange={this.handleInputChange}
            value={accountNumber}
            error={accountNumberError}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            name="accountSortCode"
            type="text"
            required
            label="Branch sort code"
            placeholder="Bank Sort Code numbers only, no spaces or -"
            onChange={this.handleInputChange}
            value={accountSortCode}
            error={accountSortCodeError}
          />
        </Form.Field>
      </div>
    )
  }

  existingBalanceMessage = () => {
    const { billingAccount } = this.state;
    const { displayOutstandingBalance } = this.props;

    if (billingAccount) {
      const balance = billingAccount.data.attributes.balance_in_pennies
      if (balance > 0 && displayOutstandingBalance) {
        return (
          <Message warning visible>
            <Message.Header>Outstanding Balance</Message.Header>
            <Message.Content>
              There is an outstanding balance of
              {' '}
              {`£${(balance / 100).toFixed(2)}`}
              {' '}
              on this account. This balance will be added to the first collection
              of your new Direct Debit. If you have any questions about this, please call
              Customer Service on
              {' '}
              <b>
                {contacts.SUPPORT_TEL_HUMANIZED}
                {'.'}
              </b>
            </Message.Content>
          </Message>
        )
      }
    }
    return null;
  };

  detailsForm = () => {
    return (
      <Grid>
        <Grid.Column width={16}>
          <h2>
            Set up direct debit
          </h2>
          {this.riskFreeMessage()}
          {this.existingBalanceMessage()}
          <br />
          <h3>Personal Information</h3>
          {this.fields()}
          <br />
          {this.formErrorMessage()}
          <div>
            {this.submitButton()}
            <DdGuarantee />
          </div>
        </Grid.Column>
      </Grid>
    )
  };

  submitButton = () => {
    const { loading } = this.state;

    return (
      <Button
        content="Validate Bank Details and Create Direct Debit"
        primary
        onClick={this.handleSubmit}
        floated="left"
        loading={loading}
      />
    );
  }

  validateFormInputs = () => {
    const {
      firstName,
      lastName,
      email,
      accountName,
      accountNumber,
      accountSortCode,
    } = this.state;

    const errors = [];

    if (firstName === '') {
      errors.push('First Name can not be blank');
      this.setState({ firstNameError: true });
    }

    if (lastName === '') {
      errors.push('Last Name can not be blank');
      this.setState({ lastNameError: true });
    }

    if (email === '') {
      errors.push('Email can not be blank');
      this.setState({ emailError: true });
    } else if (!this.validateEmail(email)) {
      errors.push('Invalid email address');
      this.setState({ emailError: true });
    }

    if (accountName === '') {
      errors.push('Bank Account Holder name can not be blank');
      this.setState({ accountNameError: true });
    } else if (!this.validateFullName(accountName)) {
      errors.push('Please enter the full name of the Bank Account Holder');
      this.setState({ accountNameError: true });
    }

    if (accountName.length > 18) {
      errors.push('Bank Account Holder name is too long, it can not be more than 17 characters');
      this.setState({ accountNameError: true });
    }

    if (accountNumber === '') {
      errors.push('Account Number can not be blank');
      this.setState({ accountNumberError: true });
    } else if (!this.validateAccountNumber(accountNumber)) {
      errors.push('Account Number must be 8 digits long');
      this.setState({ accountNumberError: true });
    }

    if (accountSortCode === '') {
      errors.push('Sort Code can not be blank');
      this.setState({ accountSortCodeError: true });
    } else if (!this.validateSortcode(accountSortCode)) {
      errors.push('Sort Code must be six digits long');
      this.setState({ accountSortCodeError: true });
    }

    this.setState({ errors });

    return !errors.length > 0;
  }

  handleInputChange = (e, { value }) => this.setState({ [e.target.name]: value });

  validateEmail = value => /^\S+@\S+$/.test(value);

  validateFullName = value => _.words(value).length > 1;

  validateSortcode = value => value.length === 6;

  validateAccountNumber = value => value.length === 8;

  resetErrors = () => {
    this.setState({
      errors: [],
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      accountNameError: null,
      accountSortCodeError: null,
      accountNumberError: null,
    });
  }

  render() {
    return this.detailsForm();
  }
}

export default DirectDebitForm;
