import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class FaqAPI {
  getFaq = () => (
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/faqs.json`,
    })
  )
}
export default new FaqAPI();
