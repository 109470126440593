import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import PageHeading from '../PageHeading/PageHeading';
import paths from '../../consts/paths';
import WindowUtils from '../../utils/WindowUtils';

class EnrolmentStatus extends Component {
  static propTypes = {
    attributes: PropTypes.object.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  componentDidMount() {
    WindowUtils.scrollToTop();
  }

  isEnrolled = () => {
    const { attributes } = this.props;
    const { status } = attributes;
    return status.indexOf('enrolled') > -1;
  };

  isPaused = () => {
    const { attributes } = this.props;

    return attributes.exception_type === 'frozen_during_school_closure'
  }

  delayedCancellationDate = () => {
    const { attributes } = this.props;
    const { delayed_cancellation_date } = attributes;
    return delayed_cancellation_date;
  };

  pauseInfo = () => {
    if (!this.isEnrolled()) {
      return null;
    }

    return (
      <>
        <h3>Paused</h3>
        <p>
          Your child is paused, and there is currently no charge for them.
          <br />
          To restart lessons, please contact the office on 0330 113 0330
        </p>
      </>
    )
  }

  cancelButton = (id) => {
    if (!this.isEnrolled()) {
      return null;
    }

    if (this.delayedCancellationDate()) {
      return (
        <>
          <p>
            Your child is cancelling on
            {this.delayedCancellationDate()}
          </p>
        </>
      )
    }

    return (
      <>
        <h3>Cancel</h3>
        <p>Cancel your subscription and remove your child from the timetable</p>
        <Link
          to={paths.CANCELLATION_FORM(id)}
          className="ui button wrapping-button"
          key={`link-cancel-${id}`}
        >
          Cancel Enrolment
        </Link>
      </>
    )
  }

  render() {
    const { attributes, id } = this.props;
    return (
      <div className="page-container">
        <PageHeading
          heading={attributes.name}
          subHeading="Enrolment Status"
        />
        <Segment>
          {this.isPaused() ? this.pauseInfo() : ''}
          {this.cancelButton(id)}
        </Segment>
      </div>
    );
  }
}

export default EnrolmentStatus;
