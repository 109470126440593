import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class FeatureFlagAPI {
  getFeatureFlags = () => (
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/feature_flags.json`,
    })
  )
}

export default new FeatureFlagAPI();
