import React, { Component } from 'react';
import {
  Form, Button, Grid, Message,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import paths from '../../consts/paths';
import StringUtils from '../../utils/StringUtils';
import WindowUtils from '../../utils/WindowUtils';
import UserAPI from '../../api/UserAPI';
import BugsnagClient from '../../utils/BugsnagClient';

class SetPassword extends Component {
  static propTypes = {
    signedIn: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      showValidationErrors: false,
      problemUpdatingPassword: false,
      problemUpdatingPasswordMessage: null,
      loading: false,
    };
  }

  handlePasswordChange = (event) => {
    this.setState({ showValidationErrors: false, problemUpdatingPassword: false, password: event.target.value });
  };

  handlePasswordConfirmationChange = (event) => {
    this.setState({ showValidationErrors: false, problemUpdatingPassword: false, passwordConfirmation: event.target.value });
  };

  setPasswordForUser = () => {
    this.setState(
      {
        showValidationErrors: true,
        problemUpdatingPasswordMessage: null,
        problemUpdatingPassword: false,
      },
      () => {
        if (this.formValid()) {
          this.setState(
            {
              loading: true,
            },
            () => {
              const { password, passwordConfirmation } = this.state;
              const config = {
                data: {
                  user: {
                    reset_password_token: this.tokenFromUrl(),
                    password,
                    password_confirmation: passwordConfirmation,
                  },
                },
              };
              this.setPassword(config);
            },
          );
        }
      },
    );
  };

  setPassword = async (config) => {
    try {
      await UserAPI.setPasswordForUser(config);
      this.setState({ loading: false });
      WindowUtils.replaceLocation(`${paths.SIGN_IN}?passwordSet=true`);
    } catch (err) {
      this.setState({ problemUpdatingPassword: true, loading: false });
      err.message = `SET PASSWORD FAILED - ${err.message}`;
      let data = {};
      if (err.response) {
        this.setState({ problemUpdatingPasswordMessage: err.response.data.error });
        data = {
          metaData: {
            props: {
              message: err.response.data.error,
              status: err.response.status,
              headers: err.response.headers,
            },
          }
        }
      }
      BugsnagClient.notify(err, data);
    }
    this.setState({
      showValidationErrors: false,
    });
  };

  passwordIsNotComplexEnough = password => StringUtils.passwordIsNotComplexEnough(password);

  formValid = () => {
    const { password, passwordConfirmation } = this.state;
    if (!password || !passwordConfirmation) {
      return false;
    }
    if (password !== passwordConfirmation) {
      return false;
    }
    if (this.passwordIsNotComplexEnough(password)) {
      return false;
    }
    return true;
  };

  errorMessageDetails = () => {
    const { problemUpdatingPasswordMessage } = this.state;
    if (problemUpdatingPasswordMessage) {
      return (
        <>
          <p>The following error occured:</p>
          <ul><li>{problemUpdatingPasswordMessage}</li></ul>
        </>
      );
    }
    return null;
  }

  validationErrorMessage = () => {
    const { password, passwordConfirmation } = this.state;
    if (!password || !passwordConfirmation) {
      return 'Password or Password Confirmation is missing.';
    }

    if (password !== passwordConfirmation) {
      return 'Passwords do not match.';
    }

    if (this.passwordIsNotComplexEnough(password)) {
        return 'Password is not complex enough.'
    }

    return null
  }

  validationMessage = () => {
    const { showValidationErrors } = this.state;
    if (showValidationErrors && !this.formValid()) {

      return (
        <Grid.Row centered>
          <Message negative>{this.validationErrorMessage()}</Message>
        </Grid.Row>
      );
    }
    return null;
  };

  redirect = () => {
    const { signedIn } = this.props;
    return signedIn && <Redirect push to={`${paths.ROOT}`} />;
  };

  errorMessage = () => {
    const { problemUpdatingPassword } = this.state;
    return (
      problemUpdatingPassword && (
        <Grid.Row centered>
          <Message negative style={{ textAlign: 'left' }}>
            <Message.Header>Problem Updating Password</Message.Header>
            <Message.Content>
              <p>There was a problem updating your password</p>
              {this.errorMessageDetails()}
              <p>
                {`Please make sure you have clicked the link in the `}
                <b>most recent</b>
                {` email.`}
              </p>
              <p>
                Password reset links are valid for 12 hours but a new one can be requested
                10 minutes after the last one was sent.
              </p>
              <p>
                {`If needed, you can request a new password reset email `}
                <a href={paths.FORGOTTEN_PASSWORD}>here.</a>
              </p>
              <p>If problems persist, please contact the office on 0330 113 0330</p>
            </Message.Content>
          </Message>
        </Grid.Row>
      )
    );
  };

  tokenFromUrl = () => {
    const urlParams = StringUtils.parseQueryString(WindowUtils.anyParams());
    const { token } = urlParams;
    return token;
  };

  render() {
    const {
      password, passwordConfirmation, showValidationErrors, loading,
    } = this.state;
    if (this.tokenFromUrl()) {
      return (
        <Grid relaxed padded>
          <Grid.Row centered>
            <Message info>
              <Message.Content>
                <Message.Header>Please set a new password below!</Message.Header>
                Password should be over 8 characters long and include at least one lowercase letter,
                uppercase letter and digit.
              </Message.Content>
            </Message>
          </Grid.Row>
          {this.validationMessage()}
          {this.errorMessage()}
          <Grid.Row centered>
            <Form onSubmit={this.setPasswordForUser} loading={loading}>
              {this.redirect()}
              <Form.Input
                onChange={this.handlePasswordChange}
                placeholder="Password"
                value={password}
                type="password"
                error={showValidationErrors && !this.formValid()}
              />
              <Form.Input
                onChange={this.handlePasswordConfirmationChange}
                placeholder="Confirm Password"
                value={passwordConfirmation}
                type="password"
                error={showValidationErrors && !this.formValid()}
              />
              <Button size="huge" type="submit" positive>
                Set Password
              </Button>
            </Form>
          </Grid.Row>
        </Grid>
      );
    }
    return <Message>Missing token</Message>;
  }
}

export default SetPassword;
