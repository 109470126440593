export default class TimetableUtils {
  static formattedYear = (year) => {
    if (year < 0 ) {
      return 'Starting Reception Next Autumn';
    }

    if (['R', '0', 0].indexOf(year) > -1) {
      return 'R';
    }
    return year;
  };
}
