import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const TotalAmountOnEnrolment = ({ nextPaymentAmount, regularMonthlyAmount }) => {
  return (
    <Table celled striped unstackable compact>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Total Next Payment Amount</Table.Cell>
          <Table.Cell>{nextPaymentAmount}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Total Regular Monthly Payment Thereafter</Table.Cell>
          <Table.Cell>{regularMonthlyAmount}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export default TotalAmountOnEnrolment;

TotalAmountOnEnrolment.propTypes = {
  nextPaymentAmount: PropTypes.string.isRequired,
  regularMonthlyAmount: PropTypes.string.isRequired,
}
