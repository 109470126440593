export default class ObjectUtils {
  static filterUpdatedValuesOnObject = (original, comparison) => {
    const filteredObject = {};
    Object.keys(comparison).forEach((key) => {
      if (comparison[key] === original[key]) return;
      filteredObject[key] = comparison[key];
    });
    return filteredObject;
  };

  // Checks that for two objects with same keys, the values are the same
  static objectsAreTheSame = (original, comparison) => {
    const keys = Object.keys(comparison);
    return keys.every(key => comparison[key] === original[key]);
  };

  static containsATruthyValue = object => ObjectUtils.valuesFromKeys(object).some(value => value);

  static valuesFromKeys = object => Object.keys(object).map(key => object[key]);
}
