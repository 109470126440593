import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import { Button, Form, Message, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import SignInPageMessage from './SignInPageMessage';
import UserAPI from '../../api/UserAPI';
import StringUtils from '../../utils/StringUtils';
import WindowUtils from '../../utils/WindowUtils';
import paths from '../../consts/paths';
import warnings from '../../consts/warnings';

const SignInForm = ({ onSigningIn, signedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [failedSignIn, setFailedSignIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const tryToSignIn = async () => {
    const config = {
      data: {
        user: {
          email,
          password
        }
      }
    };
    setLoading(true);
    try {
      const response = await UserAPI.signIn(config);
      const { authorization } = response.headers;
      onSigningIn(authorization);
    } catch (err) {
      if (err.request.status === 401) {
        setFailedSignIn(true);
        setLoading(false);
      } else {
        throw err;
      }
    }
  };

  const emailValid = () => {
    return StringUtils.isEmailIsh(email);
  };

  const passwordValid = () => {
    return !!password;
  };

  const formValid = () => emailValid() && passwordValid();

  const signIn = async () => {
    setShowValidationErrors(true);
    if (formValid()) { tryToSignIn(); }
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const messages = () => {
    const urlParams = StringUtils.parseQueryString(WindowUtils.anyParams());
    const { passwordSet, signInTimedOut, signedOut } = urlParams;
    if (passwordSet) {
      return (
        <Message positive>
          Thanks for setting up a password, please log in.
        </Message>
      );
    }
    if (signInTimedOut) {
      return <Message negative>Please sign in again.</Message>;
    }
    if (signedOut) {
      return <Message className="signed-out" size="big">You are now signed out</Message>;
    }
    return null;
  };

  const redirect = () => {
    const queryString = WindowUtils.anyParams() || ''
    const index = queryString.indexOf('previousPath');
    let path;
    if (index === -1) {
      path = paths.ROOT;
    } else {
      path = queryString.substring(index).replace('previousPath=', '');
    }
    return signedIn && <Redirect push to={`${path}`} />;
  };

  const warningMessage = () => {
    const formNotValid = showValidationErrors && !formValid();
    return (
      formNotValid && <Message negative content={warnings.EMAIL_OR_PASSWORD} />
    );
  };

  const errorMessage = () => {
    return (
      failedSignIn && (
        <Message negative>
          <Message.Header>Please check your email and password.</Message.Header>
          <Message.Content>
            <p>
              Excessive failed sign in attempts will result in the account being locked. If this happens
              we will email you with instructions on how to unlock your account.
              If this problem continues please contact the office on info@rocksteadymusicschool.com
            </p>
          </Message.Content>
        </Message>
      )
    );
  };

  const emailInvalid = showValidationErrors && !emailValid();
  const passwordInvalid = showValidationErrors && !passwordValid();

  return (
    <Grid relaxed padded stackable>
      <Grid.Row centered>{messages()}</Grid.Row>
      <MediaQuery maxWidth={768}>
        {/* on mobile, show this message at the top of the page */}
        <Message>
          <Message.Content>
            <h3>New to Rocksteady?</h3>
            <p>
              <em>Backstage</em>
              {' '}
              is our Parent Portal for existing customers.
              If this is the first time you’ve contacted us, start by
              {' '}
              <a
                href="https://booking.rocksteadymusicschool.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                enrolling your child
              </a>
              .
            </p>
            <a
              className="ui blue button fluid"
              href="https://booking.rocksteadymusicschool.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Enrol your Child
            </a>
          </Message.Content>
        </Message>
      </MediaQuery>
      <Grid.Row columns={2} className="sign-in-form">
        <Grid.Column textAlign="center">
          <MediaQuery minWidth={769}>
            <Message size="big">
              <Message.Content>
                Welcome to
                {' '}
                <strong>Backstage</strong>
                ,
                {' '}
                the Rocksteady Parent Portal
              </Message.Content>
            </Message>
          </MediaQuery>

          <h3>Have an account? Sign In</h3>
          <Form onSubmit={signIn} loading={loading} size="big">
            {redirect()}
            {errorMessage()}
            {warningMessage()}
            <Form.Field>
              <Form.Input
                placeholder="Email..."
                value={email}
                onChange={handleEmailChange}
                error={emailInvalid}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                type="password"
                placeholder="Password..."
                value={password}
                onChange={handlePasswordChange}
                error={passwordInvalid}
              />
            </Form.Field>
            <Grid stackable centered padded>
              <Grid.Row>
                <Button size="big" positive type="submit">
                  Sign In
                </Button>
              </Grid.Row>
              <Grid.Row className="forgotten-password">
                <a href={paths.FORGOTTEN_PASSWORD}>
                  Forgotten your password?
                </a>
              </Grid.Row>
            </Grid>
          </Form>
        </Grid.Column>
        <Grid.Column>
          <SignInPageMessage />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

SignInForm.propTypes = {
  onSigningIn: PropTypes.func.isRequired,
  signedIn: PropTypes.bool,
}

SignInForm.defaultProps = {
  signedIn: false,
}

export default SignInForm
