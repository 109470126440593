import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio, Confirm, Modal } from 'semantic-ui-react';
import KidAPI from '../../api/KidAPI';

class KidsAppSubscriptionToggle extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    price_in_pennies: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      kidsAppOptIn: false,
      confirmOpen: false,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    KidAPI.getKid(id).then(response => {
      const kid = response.data;
      this.setState({
        loading: false,
        kidsAppOptIn: kid.data.attributes.kids_app_opt_in
      });
    });
  }

  promptChangeConfirmation = () => this.setState({ confirmOpen: true });

  handleCancelConfirm = () => this.setState({ confirmOpen: false })

  // After the user confirms the change, then we invert the kid's kids_app_opt_in state
  handleConfirm = () => {
    const { id } = this.props;
    const { kidsAppOptIn } = this.state;
    const data = {
      kids_app_opt_in: !kidsAppOptIn
    };

    KidAPI.updateKid(id, data).then(() => {
      this.setState({ kidsAppOptIn: !kidsAppOptIn, confirmOpen: false });
    });
  }

  confirmMessage = () => {
    const { kidsAppOptIn } = this.state;
    const { name, price_in_pennies } = this.props;


    const unsubscribeContent = (
      <Modal.Content>
        <p>
          You are about to unsubscribe
          {' '}
          {name}
          {' '}
          from Rocksteady PLAY.
        </p>
        <p>They will no longer be able to access the app.</p>
      </Modal.Content>
    );

    const subscribeContent = (
      <Modal.Content>
        <p>
          Please confirm you want to subscribe
          {' '}
          {name}
          {' '}
          to Rocksteady PLAY.
        </p>
        <p>
          This will cost an additional £
          {(price_in_pennies / 100).toFixed(2)}
          {' '}
          per month, which will be added to your next Direct Debit.
        </p>
      </Modal.Content>
    );

    return kidsAppOptIn ? unsubscribeContent : subscribeContent;
  }

  confirmButtonText = () => {
    const { kidsAppOptIn } = this.state;
    return kidsAppOptIn ? 'Unsubscribe' : 'Yes, Subscribe';
  }

  render() {
    const { loading, kidsAppOptIn, confirmOpen } = this.state;
    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <Confirm
          open={confirmOpen}
          onCancel={this.handleCancelConfirm}
          onConfirm={this.handleConfirm}
          content={this.confirmMessage()}
          confirmButton={this.confirmButtonText()}
          header='Please confirm'
        />
        <Radio
          toggle
          checked={kidsAppOptIn}
          onChange={this.promptChangeConfirmation}
        />
        <br />
        {kidsAppOptIn ? 'Yes (Subscribed)' : 'No'}
      </div>
    );
  }
}

export default KidsAppSubscriptionToggle;
