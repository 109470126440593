import React from 'react';
import paths from '../../consts/paths';

const threeSameOptions = (
  <p>
    Rocksteady is different to many music lessons, and sometimes you might not realise all the
    skills your child is learning, particularly if you have not yet seen them playing in a concert
    with their full band.
    <br />
    <br />
    Your child’s Band Leader should be able to give you specific information about what your child
    has learnt so far this term.
    {' '}
  </p>
);

const options = (id, band_leader_id) => [
  {
    text: (
      <p>
        If you
        <a href={`${paths.KID(id)}`}> update your child’s instrument preferences </a>
        {' '}
        we will
        put them on the waiting list for their preferred instrument while they continue to enjoy the
        benefits of their existing place.
      </p>
    ),
    value: 'instrument_not_available',
  },
  {
    text: (
      <p>
        It can be fun playing in a band with friends – some of the best friendships even start from
        being in a band together! Have you considered inviting another friend to join the band or
        switching to another band?
        <br />
        <br />
        <a href={`${paths.AVAILABLE_INSTRUMENT_CHANGES}?id=${id}`}>
          Click here to see what places are available.
        </a>
      </p>
    ),
    value: 'friend_left_the_band',
  },
  {
    text: (
      <p>
        It takes a lot of different skills to learn to play in a band, and our 300-skill
        curriculum has everything from chords to confidence.
        <br />
        <br />
        Have you seen the
        <a href={`${paths.TIMELINE(id)}`}> Parent Timeline? </a>
        This area shows all the skills your child has learnt so far, and the things they’re working towards now.
        <br />
        <br />
        Some parents find seeing their child perform at a concert can help
        demonstrate everything they’ve achieved. Your child’s Band Leader can
        always provide more information –
        <a href={`${paths.STAFF_PROFILE(band_leader_id)}`}>feel free to contact them here.</a>
      </p>
    ),
    value: 'not_learning_enough',
  },
  {
    text: threeSameOptions,
    value: 'nothing_more_to_learn',
  },
  {
    text: (
      <p>
        We’re sorry to hear this. What else can we do to help? If you haven’t
        already reached out, please call our team in the office and we’d be happy
        to help.
        <br />
        <br />
        Some children find changing instruments or the band they’re in can help.
        {' '}
        <br />
        <br />
        If you would like to consider this, you can
        <a href={`${paths.AVAILABLE_INSTRUMENT_CHANGES}?id=${id}`}> check availability here.</a>
      </p>
    ),
    value: 'not_having_fun',
  },
  {
    text: (
      <p>
        Have you checked to see if we teach at your child’s new school? We can
        easily transfer your account to the new school if we do. Feel free to
        contact our office and we will be happy to help.
        <br />
        <br />
        If you would like to recommend Rocksteady to your new school, please
        give them the link to our website where they can find out more.
      </p>
    ),
    value: 'moving_school',
  },
  {
    text: (
      <p>
        We&apos;re sorry to hear that you are not finding the programme right for you.
        <br />
        <br />
        Did you know that we are regularly uploading new videos aimed at parents, advice on how to
        get involved without an instrument, PDF resources to back up the video and plenty more fun,
        musical content? This helps children continue to experience all the fun and musical progression
        of normal Rocksteady lessons from home.
      </p>
    ),
    value: 'not_sure_rehearse_at_home_service_is_right',
  },
  {
    text: (
      <p>
        We’re really sorry to hear that the cost of lessons is an issue. It&apos;s worth bearing in
        mind lessons include use of instruments and the end of term concerts as well as not
        requiring travel to lessons.
        <br />
        <br />
        If you are suffering from extreme hardship, please consider calling us to discuss your
        individual situation before cancelling.
      </p>
    ),
    value: 'cost',
  },
  {
    text: (
      <p>
        We’re sorry your child has missed lessons. Rocksteady plan in additional lessons during the
        year to act as a buffer for missed lessons. We commit to 33 sessions over a whole year but
        plan to deliver 36. This compares to a lot of school services who work on 30 sessions a
        year.
        <br />
        <br />
        If you feel we’ve still not done enough, please contact Customer Services as there may be more we can do.
      </p>
    ),
    value: 'too_many_missed_rocksteady_lessons',
  },
  {
    text: (
      <p>
        Have you seen the
        <a href={`${paths.TIMELINE(id)}`}> Parent Timeline? </a>
        This area shows all the skills your
        child has learnt so far, and the things they’re working towards now.
        <br />
        <br />
        Playing a musical instrument with friends is a great way for your child to
        boost their academic performance. Learning skills like rhythm, song
        structure and aural awareness can improve their understanding of other
        subjects in school.
      </p>
    ),
    value: 'worried_about_missing_other_school_lessons',
  },
  {
    text: (
      <p>
        If you want to talk to our team, we will always do our best to resolve your issue. Call us
        on 0330 113 0330.
      </p>
    ),
    value: 'other_reason',
  },
];

export default ({ id, reasonData, band_leader_id}) => options(id, band_leader_id).find(value => value.value === reasonData.value).text;
