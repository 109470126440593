import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Icon,
  Segment,
  Grid,
  Message,
  Table,
  Header,
  Confirm,
  Label,
  Button
} from 'semantic-ui-react';
import { Prompt, Link, Redirect } from 'react-router-dom';
import StatusLabel from './StatusLabel/StatusLabel';
import InstrumentPreferences from './InstrumentPreferences/InstrumentPreferences';

import KidAPI from '../../api/KidAPI';
import StringUtils from '../../utils/StringUtils';
import TimetableUtils from '../../utils/TimetableUtils';
import ObjectUtils from '../../utils/ObjectUtils';
import FormUtils from '../../utils/FormUtils';
import WindowUtils from '../../utils/WindowUtils';
import paths from '../../consts/paths';
import KidNextSchool from '../KidDetails/KidNextSchool';
import PageHeading from '../PageHeading/PageHeading';
import FeatureFlagAPI from '../../api/FeatureFlagAPI';

class KidForm extends Component {
  static propTypes = {
    attributes: PropTypes.object.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    timetableAttributes: PropTypes.object.isRequired,
    changing: PropTypes.bool,
    refreshAllTheThings: PropTypes.func.isRequired,
  };

  static defaultProps = {
    changing: false
  };

  constructor(props) {
    super(props);
    this.state = {
      attributes: props.attributes,
      loading: false,
      successful: false,
      failedUpdate: false,
      confirmingDeletion: false,
      redirectToChildren: false,
      kidsAppOptInEnabled: false,
    };
  }

  componentDidMount() {
    WindowUtils.scrollToTop();

    FeatureFlagAPI.getFeatureFlags().then(response => {
      this.setState({
        kidsAppOptInEnabled: response.data.kids_app_opt_in,
      });
    }).catch(() => {
      this.setState({
        kidsAppOptInEnabled: false,
      });
    });
  }

  get formUtils() {
    return new FormUtils({
      requiredFields: ['name'],
      updateCallback: this.updateAttribute
    });
  }

  formField = ({ field, label, placeholder } = {}) => {
    const { attributes } = this.state;
    return (
      <Form.Field key={field} error={this.requiredAndMissing(field)}>
        <Form.Input
          id={field}
          label={label}
          placeholder={placeholder}
          value={attributes[field] || ''}
          required={this.formUtils.fieldIsRequired(field)}
          onChange={this.formUtils.handleInputChange(field)}
          autoComplete={`kid-${field}`}
        />
      </Form.Field>
    );
  };

  updateConcertVideoPermission = (value) => {
    this.updateAttribute('concert_video_permission', value);
  }

  concertVideoPermissions = () => {
    const { attributes: { concert_video_permission } } = this.state;
    return (
      <>
        <br />
        <p>
          <b>
            Recorded Performances
          </b>
        </p>
        <p className="concert-permission-text">
          On occasion, Rocksteady showcases the performances of its Band Members
          by offering the opportunity for you to
          see videos of your child playing their instrument.
        </p>
        <p className="concert-permission-text">
          {
            `The recorded performances are made available to the school and other Rocksteady parents in
            your child's band via your secure 'Backstage' login. We shall only use these recordings
            for the purposes identified above. For more information, please see our`
          }
          {' '}
          <a
            href="https://www.rocksteadymusicschool.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Notice.
          </a>
        </p>
        <p>
          <b>
            I consent for my child to be recorded and their performance made available for the
            school and parents of other children in their band
          </b>
        </p>
        <Form.Field>
          <Form.Checkbox
            onChange={() => this.updateConcertVideoPermission(true)}
            name="concert_video_permissions_true"
            checked={concert_video_permission === true}
            label="Yes"
          />
          <Form.Checkbox
            onChange={() => this.updateConcertVideoPermission(false)}
            name="concert_video_permissions_false"
            checked={concert_video_permission === false}
            label="No"
          />
        </Form.Field>
      </>
    );
  };

  formTextArea = ({ field, label } = {}) => {
    const { attributes } = this.state;
    return (
      <Form.Field key={field} error={this.requiredAndMissing(field)}>
        <Form.TextArea
          id={field}
          label={label}
          value={attributes[field] || ''}
          required={this.formUtils.fieldIsRequired(field)}
          onChange={this.formUtils.handleInputChange(field)}
          autoComplete={`kid-${field}`}
        />
        <span className="terms-by-dd mb22">Leave blank if none</span>
        <br />
        <br />
      </Form.Field>
    );
  };

  updateAttribute = (name, value) => {
    this.setState(prevState => ({
      attributes: {
        ...prevState.attributes,
        [name]: value
      },
      successful: false
    }));
  };

  noFieldsUpdated = () => {
    const { attributes, successful } = this.state;
    const { attributes: originalAttributes } = this.props;
    const cloneAttributes = { ...attributes };
    const cloneOriginalAttributes = { ...originalAttributes };

    cloneAttributes.confirmed_next_timetable_id = '';
    cloneOriginalAttributes.confirmed_next_timetable_id = '';
    cloneAttributes.confirmed_next_timetable_name = '';
    cloneOriginalAttributes.confirmed_next_timetable_name = '';
    cloneAttributes.do_not_move_to_next_timetable = false;
    cloneOriginalAttributes.do_not_move_to_next_timetable = false;

    return (
      ObjectUtils.objectsAreTheSame(cloneOriginalAttributes, cloneAttributes) ||
      successful
    );
  };

  deleteKid = () => {
    const { id } = this.props;
    this.setState(
      {
        confirmingDeletion: false,
        loading: true
      },
      () => {
        KidAPI.deleteKid(id)
          .then(() => {
            this.setState({
              loading: false,
              successful: true,
              redirectToChildren: true
            });
          })
          .catch(() => {
            this.setState({ loading: false, failedUpdate: true });
          });
      }
    );
  };

  submitFormButton = () => (
    <Form.Button
      positive
      disabled={this.noFieldsUpdated()}
      type="submit"
      size="big"
      onClick={this.validateFormAndSubmit}
    >
      Save Changes
    </Form.Button>
  );

  openConfirmationModal = () => {
    this.setState({ confirmingDeletion: true });
  };

  closeConfirmationModal = () => {
    this.setState({ confirmingDeletion: false });
  };

  deleteKidButton = (name, state) => (
    <div>
      <Form.Button negative onClick={this.openConfirmationModal}>
        Delete / Remove from Waiting List
      </Form.Button>
      <Confirm
        open={state}
        content={`Are you sure you want to delete the profile for ${name}?`}
        onCancel={this.closeConfirmationModal}
        onConfirm={this.deleteKid}
      />
    </div>
  );

  changeButton = () => {
    const { id, timetableAttributes } = this.props;
    const closedTT = timetableAttributes.closed_to_new_signups

    if (closedTT) { return null; }

    return (
      this.isEnrolled() && !this.isFrozen() && [
        <Link
          to={`${paths.AVAILABLE_INSTRUMENT_CHANGES}?id=${id}`}
          className="ui button wrapping-button"
          key="key-change-button"
        >
          Change Instrument or Band
        </Link>,
        <br key="key-change-button-br" />
      ]
    );
  };

  formIsValid = () => {
    const { attributes } = this.state;
    return this.formUtils.noFieldsMissing(attributes);
  };

  requiredAndMissing = field => {
    const { attributes, showValidationErrors } = this.state;
    const value = attributes[field];
    return (
      showValidationErrors && this.formUtils.requiredAndMissing(field, value)
    );
  };

  unCancelKid = () => {
    const { id } = this.props;
    const params = { delayed_cancellation_date: null };
    this.setState({ loading: true }, () => {
      KidAPI.updateKid(id, params)
        .then(() => {
          const { attributes } = this.state;
          attributes.delayed_cancellation_date = null;
          this.setState({ loading: false, successful: true, attributes });
        })
        .catch(() => {
          this.setState({ loading: false, failedUpdate: true });
        });
    });
  };

  validateFormAndSubmit = () => {
    this.setState({
      showValidationErrors: true
    });
    const { id, attributes: originalAttributes, refreshAllTheThings } = this.props;
    const { attributes } = this.state;
    const filteredObject = ObjectUtils.filterUpdatedValuesOnObject(
      originalAttributes,
      attributes
    );
    const params = {
      kid: filteredObject
    };
    if (this.formIsValid()) {
      this.setState({ loading: true }, () => {
        KidAPI.updateKid(id, params).then(() => {
          this.setState({ loading: false, successful: true });
          refreshAllTheThings();
        }).catch(() => {
          this.setState({ loading: false, failedUpdate: true });
        });
      });
    }
  };

  successMessage = () => {
    const { successful } = this.state;
    return (
      successful && (
        <span className="green">
          <Icon name="check" color="green" />
          {' '}
          Changes Saved
        </span>
      )
    );
  };

  failedUpdateMessage = () => {
    const { failedUpdate } = this.state;
    return (
      failedUpdate && (
        <Message warning>
          <Message.Header>Sorry but something went wrong</Message.Header>
          <Message.Content>
            Changes were not saved - please contact the office on 0330 113 033.
          </Message.Content>
        </Message>
      )
    );
  };

  assignedInstrument = instrument =>
    instrument !== 'not_set' && (
      <Table.Row>
        <Table.Cell>
          <strong>Instrument</strong>
        </Table.Cell>
        <Table.Cell>{StringUtils.humanize(instrument)}</Table.Cell>
      </Table.Row>
    );

  songInfo = (band_song, band_song_id) => (
    <React.Fragment>
      {band_song}
      {' '}
      <Link to={paths.SONG(band_song_id)}>
        View Song
      </Link>
    </React.Fragment>
  )

  bandAndSongInfo = () => {
    const { attributes } = this.state;
    const { band, band_song, band_song_id } = attributes;
    const bandName = (band.data !== null && band.data.attributes.name !== null) ? band.data.attributes.name : 'Not Set';

    if (this.isEnrolled()) {
      return [
        <Table.Row key="key-tr-band-name">
          <Table.Cell>
            <strong>Band name</strong>
          </Table.Cell>
          <Table.Cell>{bandName}</Table.Cell>
        </Table.Row>,
        <Table.Row key="key-tr-band-song">
          <Table.Cell>
            <strong>Song</strong>
          </Table.Cell>
          <Table.Cell>{band_song ? this.songInfo(band_song, band_song_id) : 'Not Set'}</Table.Cell>
        </Table.Row>
      ];
    }
    return null;
  };

  waitingToChangeMessage = status => {
    const { changing } = this.props;
    return (
      status === 'enrolled_waiting_to_change' &&
      !changing && (
        <Message info>
          You are still on the waiting list for your preferred instrument
        </Message>
      )
    );
  };

  isEnrolled = () => {
    const { attributes } = this.state;
    const { status } = attributes;
    return status.indexOf('enrolled') > -1;
  };

  isFrozen = () => {
    const { attributes } = this.state;
    const { exception_type } = attributes;
    return exception_type === 'frozen_during_school_closure'
  }

  delayedCancellationDate = () => {
    const { attributes } = this.state;
    const { delayed_cancellation_date } = attributes;
    return delayed_cancellation_date;
  };

  kidName = () => {
    const { attributes } = this.state;
    const { name } = attributes;
    return name;
  };

  isWaitingList = () => {
    const { attributes } = this.state;
    const { status } = attributes;
    return status === 'waiting_list';
  };

  delayedCancellationInfo = () => {
    const { attributes } = this.state;
    if (
      attributes.delayed_cancellation_date &&
      attributes.status.indexOf('enrolled') > -1
    ) {
      return (
        <Label horizontal color="orange">
          Cancelling on
          {' '}
          {attributes.delayed_cancellation_date}
        </Label>
      );
    }
    return null;
  };

  summaryTable = () => {
    const { timetableAttributes } = this.props;
    const { attributes } = this.state;
    const { status, year, assigned_instrument_type } = attributes;
    return (
      <Table basic="very" collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <strong>Status</strong>
            </Table.Cell>
            <Table.Cell>
              <StatusLabel status={status} size="medium" />
              {this.delayedCancellationInfo()}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <strong>Year</strong>
            </Table.Cell>
            <Table.Cell>{TimetableUtils.formattedYear(year)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <strong>School</strong>
            </Table.Cell>
            <Table.Cell>{timetableAttributes.display_name}</Table.Cell>
          </Table.Row>
          {this.assignedInstrument(assigned_instrument_type)}
          {this.bandAndSongInfo()}
        </Table.Body>
      </Table>
    );
  };

  accreditationPermissions = () => {
    const { attributes } = this.state;
    return (
      <>
        <b>Trinity Accreditation Opt-Out</b>
        <br />
        <br />
        <Form.Field>
          <Form.Checkbox
            onChange={this.formUtils.handleCheckbox('trinity_opt_out')}
            name="trinity-opt-out"
            checked={attributes.trinity_opt_out || false}
            label="I would like to opt my child out of Trinity accreditation"
          />
        </Form.Field>
      </>
    )
  };

  kidsAppOptIn = () => {
    const { attributes } = this.state;
    return (
      <>
        <b>Kids App Opt-In</b>
        <br />
        <br />
        <Form.Field>
          <Form.Checkbox
            onChange={this.formUtils.handleCheckbox('kids_app_opt_in')}
            name="kids-app-opt-in"
            checked={attributes.kids_app_opt_in || false}
            label="I would like my child to have access to the Kids App"
          />
        </Form.Field>
      </>
    )
  };

  ttClosedMessage = () => {
    const { timetableAttributes } = this.props;

    if (timetableAttributes.closed_to_new_signups) {
      return (
        <Message info style={{ marginBottom: 10, marginLeft: 0, width: '100%' }}>
          If your child would like to change instruments or bands, please contact
          our customer services team on 0330 113 0330 and we’ll do our best to help.
        </Message>
      );
    }

    return null;
  }

  render() {
    const {
      attributes,
      loading,
      confirmingDeletion,
      redirectToChildren,
      kidsAppOptInEnabled
    } = this.state;
    const {
      instrument_first_choice,
      instrument_second_choice,
      would_do_drums: wouldDoDrums,
      would_do_bass: wouldDoBass,
      would_do_guitar: wouldDoGuitar,
      would_do_keyboard: wouldDoKeyboard,
      would_do_vocals: wouldDoVocals,
      status
    } = attributes;
    const { id, timetableAttributes } = this.props;
    if (redirectToChildren) return <Redirect to={`${paths.ROOT}`} />;
    return (
      <div className="page-container">
        <PageHeading
          heading={attributes.name}
          subHeading="Please keep preferences up to date"
        />
        <div className={`savebtn-centered ${this.noFieldsUpdated() ? "savebtn-hidden" : "savebtn-sticky"}`}>
          {this.submitFormButton()}
        </div>
        <Segment>
          {this.failedUpdateMessage()}
          <Form loading={loading}>
            <Grid relaxed stackable padded>
              <Grid.Row columns={2}>
                <Grid.Column>
                  {this.formField({
                    field: 'name',
                    label: 'Name',
                    inline: true
                  })}
                  {this.formField({
                    field: 'school_class',
                    label: 'Class Name',
                    inline: true
                  })}
                  {this.formTextArea({
                    field: 'medical_and_education_notes',
                    label: 'Medical & Educational notes',
                    inline: true
                  })}
                  <InstrumentPreferences
                    firstPreference={instrument_first_choice}
                    secondPreference={instrument_second_choice}
                    onChangingFirstPreference={this.formUtils.handleRadioOrDropdownChange(
                      'instrument_first_choice'
                    )}
                    onChangingSecondPreference={this.formUtils.handleRadioOrDropdownChange(
                      'instrument_second_choice'
                    )}
                    onChangingWouldDo={this.formUtils.handleCheckbox}
                    wouldDoOptions={{
                      wouldDoDrums,
                      wouldDoBass,
                      wouldDoGuitar,
                      wouldDoKeyboard,
                      wouldDoVocals
                    }}
                  />
                  {this.concertVideoPermissions()}
                  {this.accreditationPermissions()}
                  {kidsAppOptInEnabled && this.kidsAppOptIn()}
                </Grid.Column>
                <Grid.Column>
                  {this.summaryTable()}
                  {this.waitingToChangeMessage(status)}
                  <Prompt
                    when={!this.noFieldsUpdated()}
                    message="Are you sure you want to leave? You have unsaved changes"
                  />
                  {this.successMessage()}
                  <br />
                  <br />
                  <br />

                  <Header as="h3">Further Actions</Header>
                  {this.ttClosedMessage()}
                  {this.isWaitingList()
                    ? this.deleteKidButton(attributes.name, confirmingDeletion)
                    : ''}
                  {this.isEnrolled()
                    ? [
                      <Link
                        to={paths.TIMETABLE(id)}
                        className="ui button wrapping-button"
                        key={`link-timetable-${id}`}
                      >
                        View Timetable
                      </Link>,
                      <br key="link-timetable-br-a" />
                      ]
                    : ''}
                  {this.changeButton()}
                  {this.isEnrolled() && !this.delayedCancellationDate() ? (
                    <Link
                      to={paths.ENROLMENT_STATUS(id)}
                      className="ui button wrapping-button"
                      key={`link-enrolment-status-${id}`}
                    >
                      Enrolment Status
                    </Link>
                  ) : (
                    ''
                  )}
                  {this.isEnrolled() && this.delayedCancellationDate() ? (
                    <Button
                      onClick={this.unCancelKid}
                      className="ui button wrapping-button primary"
                      key={`link-cancel-${id}`}
                    >
                      Un-cancel
                      {' '}
                      {this.kidName()}
                    </Button>
                  ) : (
                    ''
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        <KidNextSchool
          kidAttributes={attributes}
          kidId={parseInt(id, 10)}
          timetableAttributes={timetableAttributes}
        />
      </div>
    );
  }
}

export default KidForm;
