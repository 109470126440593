import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import BandAPI from '../../api/BandAPI';
import TimetableUtils from '../../utils/TimetableUtils';

class BandDetails extends Component {
  static propTypes = {
    bandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  constructor() {
    super();
    this.state = {
      name: null,
      year_from: null,
      year_to: null,
      bandFetched: false
    };
  }

  componentDidMount() {
    this.fetchBandDetails();
  }

  fetchBandDetails = async () => {
    const { bandId } = this.props;
    const bandDetails = await BandAPI.fetchBand(bandId);
    const { attributes } = bandDetails.data.data;
    this.setState({
      ...attributes,
      bandFetched: true
    });
  };

  yearRange = () => {
    const { year_from, year_to } = this.state;
    return `${TimetableUtils.formattedYear(year_from)} - ${TimetableUtils.formattedYear(year_to)}`;
  };

  render() {
    const { bandFetched, name } = this.state;

    if (!bandFetched) {
      return null;
    }

    return (
      <Table celled unstackable compact>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <strong>Name of Band</strong>
            </Table.Cell>
            <Table.Cell>{name || '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <strong>Age Range of Band</strong>
            </Table.Cell>
            <Table.Cell>{`Year ${this.yearRange()}`}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export default BandDetails;
