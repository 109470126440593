import React from 'react';
import { Segment } from 'semantic-ui-react';
import paths from '../../consts/paths';
import PageHeading from '../PageHeading/PageHeading';

const TermsAndConditions = () => (
  <div className="page-container">
    <PageHeading
      heading="Terms and conditions"
      subHeading="Rocksteady Music School"
    />
    <Segment>
      <a href={paths.WWW_TERMS_CONDITIONS} target="_blank" rel="noopener noreferrer">
        {`View Rocksteady's Terms and Conditions for Parents.`}
      </a>
    </Segment>
  </div>
);

export default TermsAndConditions;
