import React, { Component } from 'react';
import { Form, Button, Message, Segment } from 'semantic-ui-react';
import PageHeading from '../PageHeading/PageHeading';
import warnings from '../../consts/warnings';
import StringUtils from '../../utils/StringUtils';
import UserAPI from '../../api/UserAPI';

class Settings extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      currentPassword: '',
      showValidationErrors: false,
      problemUpdatingPassword: false,
      loading: false,
      errorMessage: null
    };
  }

  handlePasswordChange = event => {
    this.setState({
      showValidationErrors: false,
      password: event.target.value
    });
  };

  handlePasswordConfirmationChange = event => {
    this.setState({
      showValidationErrors: false,
      passwordConfirmation: event.target.value
    });
  };

  handleCurrentPassword = event => {
    this.setState({
      showValidationErrors: false,
      currentPassword: event.target.value
    });
  };

  setPasswordForUser = () => {
    this.setState(
      {
        showValidationErrors: true,
        problemUpdatingPassword: false,
        successful: false
      },
      () => {
        if (this.formValid()) {
          this.setState({ loading: true }, () => {
            const {
              password,
              passwordConfirmation,
              currentPassword
            } = this.state;
            const config = {
              data: {
                user: {
                  password,
                  password_confirmation: passwordConfirmation,
                  current_password: currentPassword
                }
              }
            };
            UserAPI.updatePasswordForUser(config)
              .then(() => {
                this.setState({
                  loading: false,
                  successful: true,
                  password: '',
                  passwordConfirmation: '',
                  currentPassword: '',
                  showValidationErrors: false
                });
              })
              .catch(error => {
                this.setState({
                  loading: false,
                  problemUpdatingPassword: true,
                  errorMessage: error.response.data.error
                });
              });
          });
        }
      }
    );
  };

  passwordIsNotComplexEnough = password =>
    StringUtils.passwordIsNotComplexEnough(password);

  formValid = () => {
    const { password, passwordConfirmation, currentPassword } = this.state;
    if (!password || !passwordConfirmation || !currentPassword) {
      return false;
    }
    if (password !== passwordConfirmation) {
      return false;
    }
    if (this.passwordIsNotComplexEnough(password)) {
      return false;
    }
    return true;
  };

  validationMessage = () => {
    const { showValidationErrors } = this.state;
    if (showValidationErrors && !this.formValid()) {
      return <Message negative>{warnings.PASSWORD_FORMAT}</Message>;
    }
    return null;
  };

  errorMessage = error => {
    const { problemUpdatingPassword } = this.state;
    return (
      problemUpdatingPassword && (
        <Message
          negative
          header="Problem updating password"
          content={error || warnings.CONTACT_THE_OFFICE}
        />
      )
    );
  };

  completeMessage = () => {
    const { successful } = this.state;
    return (
      successful && <Message positive header="Password has been changed" />
    );
  };

  render() {
    const {
      password,
      passwordConfirmation,
      currentPassword,
      loading,
      showValidationErrors,
      errorMessage
    } = this.state;
    return (
      <div className='page-container'>
        <PageHeading
          heading="Account Settings"
          subHeading="Manage your account"
        />
        <Segment>
          <Form onSubmit={this.setPasswordForUser} loading={loading}>
            <h3>Change your password</h3>
            <p>
              Password should be over 8 characters long and include at
              least one lowercase letter
            </p>
            {this.validationMessage()}
            {this.errorMessage(errorMessage)}
            {this.completeMessage()}
            <Form.Input
              onChange={this.handleCurrentPassword}
              label="Current Password"
              value={currentPassword}
              type="password"
            />
            <Form.Input
              label="New Password"
              onChange={this.handlePasswordChange}
              value={password}
              type="password"
              error={showValidationErrors && !this.formValid()}
            />
            <Form.Input
              onChange={this.handlePasswordConfirmationChange}
              label="Confirm Password"
              value={passwordConfirmation}
              type="password"
              error={showValidationErrors && !this.formValid()}
            />
            <Button type="submit">Update Password</Button>
          </Form>
          <Message info>
            <p>
              For any account changes you haven’t been able to make in
              Backstage, please contact the office on
              info@rocksteadymusicschool.com
            </p>
          </Message>
        </Segment>
      </div>
    );
  }
}
export default Settings;
