import React from 'react';
import { Image } from 'semantic-ui-react';
import thumbsUpHover from './thumbs_up_icon_hover.png';
import heartHover from './heart_icon_hover.png';
import heartEyesHover from './heart_eyes_icon_hover.png';
import grinHover from './grin_icon_hover.png';
import handHornsHover from './hand_horns_icon_hover.png';

const emojiSvg = selected => (
  <svg
    width="35"
    height="26"
    viewBox="0 0 35 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_501_275)">
      <path
        d="M12.5 17.4817C14.3164 17.4817 15.5859 16.6272 16.3281 15.8264C16.7627 15.3528 17.5049 15.3186 17.9834 15.7581C18.457 16.1975 18.4863 16.9397 18.0469 17.4133C16.9775 18.5803 15.1221 19.8254 12.5 19.8254C9.87793 19.8254 8.02246 18.5803 6.95312 17.4133C6.51367 16.9397 6.49902 16.1975 7.0166 15.7581C7.49512 15.3186 8.2373 15.3528 8.67188 15.8264C9.41406 16.6272 10.6836 17.4817 12.5 17.4817ZM10.1758 10.4504C10.1758 11.3147 9.47266 12.0129 8.61328 12.0129C7.74902 12.0129 7.05078 11.3147 7.05078 10.4504C7.05078 9.58618 7.74902 8.88794 8.61328 8.88794C9.47266 8.88794 10.1758 9.58618 10.1758 10.4504ZM14.8633 10.4504C14.8633 9.58618 15.5615 8.88794 16.4258 8.88794C17.2852 8.88794 17.9883 9.58618 17.9883 10.4504C17.9883 11.3147 17.2852 12.0129 16.4258 12.0129C15.5615 12.0129 14.8633 11.3147 14.8633 10.4504ZM25 12.7942C25 19.6985 19.4043 25.2942 12.5 25.2942C5.5957 25.2942 0 19.6985 0 12.7942C0 5.88989 5.5957 0.294189 12.5 0.294189C19.4043 0.294189 25 5.88989 25 12.7942ZM12.5 2.63794C6.88965 2.63794 2.34375 7.18384 2.34375 12.7942C2.34375 18.4045 6.88965 22.9504 12.5 22.9504C18.1104 22.9504 22.6562 18.4045 22.6562 12.7942C22.6562 7.18384 18.1104 2.63794 12.5 2.63794Z"
        fill={selected ? 'white' : '#0072CE'}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.7692 1.25573C30.7692 0.724685 30.3387 0.294189 29.8077 0.294189C29.2766 0.294189 28.8461 0.724685 28.8461 1.25573L28.8461 4.14032H25.9615C25.4305 4.14032 25 4.57082 25 5.10186C25 5.6329 25.4305 6.0634 25.9615 6.0634H28.8461L28.8461 8.94804C28.8461 9.47908 29.2766 9.90957 29.8077 9.90957C30.3387 9.90957 30.7692 9.47908 30.7692 8.94804V6.0634H33.6538C34.1849 6.0634 34.6154 5.6329 34.6154 5.10186C34.6154 4.57082 34.1849 4.14032 33.6538 4.14032H30.7692V1.25573Z"
      fill={selected ? 'white' : '#0072CE'}
    />
    <defs>
      <clipPath id="clip0_501_275">
        <rect
          width="25"
          height="25"
          fill={selected ? '#0072CE' : 'white'}
          transform="translate(0 0.294189)"
        />
      </clipPath>
    </defs>
  </svg>
);

const heartSvg = selected => (
  <svg
    width="30"
    height={selected ? '24' : '28'}
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0881 2.66796C23.8772 -0.0683672 19.1018 0.42382 16.1545 3.46484L15.0002 4.65429L13.8459 3.46484C10.9045 0.42382 6.12328 -0.0683672 2.91234 2.66796C-0.767347 5.80859 -0.960707 11.4453 2.33226 14.8496L13.6702 26.5566C14.4026 27.3125 15.592 27.3125 16.3244 26.5566L27.6623 14.8496C30.9612 11.4453 30.7678 5.80859 27.0881 2.66796Z"
      fill={selected ? 'white' : '#0072CE'}
    />
  </svg>
);

const grinSquintSvg = selected => (
  <svg
    width="30"
    height={selected ? '24' : '31'}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0.758728C6.97266 0.758728 0.46875 7.26263 0.46875 15.29C0.46875 23.3173 6.97266 29.8212 15 29.8212C23.0273 29.8212 29.5312 23.3173 29.5312 15.29C29.5312 7.26263 23.0273 0.758728 15 0.758728ZM16.9805 10.2333L21.668 7.42084C22.3477 7.01654 23.0742 7.87201 22.5703 8.47552L20.6016 10.8369L22.5703 13.1982C23.0801 13.8076 22.3418 14.6513 21.668 14.2529L16.9805 11.4404C16.5293 11.165 16.5293 10.5087 16.9805 10.2333ZM7.42969 8.47552C6.92578 7.87201 7.65234 7.01654 8.33203 7.42084L13.0195 10.2333C13.4766 10.5087 13.4766 11.165 13.0195 11.4404L8.33203 14.2529C7.6582 14.6513 6.92578 13.8076 7.42969 13.1982L9.39844 10.8369L7.42969 8.47552ZM23.8418 18.2197C23.3203 22.3798 19.7695 25.6025 15.4688 25.6025H14.5312C10.2305 25.6025 6.67969 22.3798 6.1582 18.2197C6.08789 17.663 6.52734 17.165 7.08984 17.165H22.9102C23.4727 17.165 23.9121 17.6572 23.8418 18.2197Z"
      fill={selected ? 'white' : '#0072CE'}
    />
  </svg>
);

const grinHeartsSvg = selected => (
  <svg
    width="30"
    height={selected ? '24' : '31'}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0.758728C6.97266 0.758728 0.46875 7.26263 0.46875 15.29C0.46875 23.3173 6.97266 29.8212 15 29.8212C23.0273 29.8212 29.5312 23.3173 29.5312 15.29C29.5312 7.26263 23.0273 0.758728 15 0.758728ZM5.76562 11.0478C6.1582 10.0165 7.33008 9.48334 8.39648 9.76459L8.8125 9.87592L8.92969 9.4599C9.22266 8.39935 10.2656 7.64935 11.3613 7.82513C12.6152 8.02435 13.377 9.2431 13.0488 10.4326L11.9121 14.5283C11.8418 14.7919 11.5664 14.9501 11.2969 14.8798L7.18359 13.8134C5.98828 13.497 5.31445 12.2314 5.76562 11.0478ZM15 25.6025C11.4492 25.6025 7.11914 23.3583 6.57422 20.1357C6.45703 19.4443 7.11328 18.8759 7.78711 19.0869C9.55664 19.6552 12.1875 19.9775 15 19.9775C17.8125 19.9775 20.4434 19.6552 22.2129 19.0869C22.8809 18.8759 23.5371 19.4443 23.4258 20.1357C22.8809 23.3583 18.5508 25.6025 15 25.6025ZM22.8164 13.8076L18.7031 14.874C18.4395 14.9443 18.1641 14.7861 18.0879 14.5224L16.9512 10.4267C16.623 9.23724 17.3848 8.01849 18.6387 7.81927C19.7285 7.64349 20.7715 8.39349 21.0703 9.45404L21.1875 9.87006L21.6035 9.75873C22.6699 9.48334 23.8418 10.0107 24.2344 11.0419C24.6855 12.2314 24.0117 13.497 22.8164 13.8076Z"
      fill={selected ? 'white' : '#0072CE'}
    />
  </svg>
);

const thumbsUpSvg = selected => (
  <svg
    width="30"
    height={selected ? '24' : '31'}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.09375 13.415H1.40625C0.62959 13.415 0 14.0446 0 14.8212V28.8837C0 29.6604 0.62959 30.29 1.40625 30.29H6.09375C6.87041 30.29 7.5 29.6604 7.5 28.8837V14.8212C7.5 14.0446 6.87041 13.415 6.09375 13.415ZM3.75 27.9462C2.97334 27.9462 2.34375 27.3166 2.34375 26.54C2.34375 25.7633 2.97334 25.1337 3.75 25.1337C4.52666 25.1337 5.15625 25.7633 5.15625 26.54C5.15625 27.3166 4.52666 27.9462 3.75 27.9462ZM22.5 5.06256C22.5 7.54787 20.9783 8.94193 20.5502 10.6025H26.5105C28.4674 10.6025 29.9908 12.2282 29.9999 14.0067C30.0049 15.0577 29.5578 16.1892 28.8609 16.8893L28.8545 16.8957C29.4308 18.2631 29.3371 20.1792 28.3091 21.5521C28.8178 23.0694 28.3051 24.9332 27.3492 25.9324C27.6011 26.9636 27.4807 27.8411 26.989 28.5476C25.7931 30.2658 22.8291 30.29 20.3226 30.29L20.1559 30.2899C17.3266 30.2889 15.011 29.2588 13.1504 28.431C12.2154 28.0151 10.9929 27.5002 10.0654 27.4831C9.68221 27.4761 9.375 27.1634 9.375 26.7801V14.2545C9.375 14.067 9.45012 13.8871 9.58348 13.7552C11.9046 11.4616 12.9027 9.03334 14.8052 7.12763C15.6726 6.25857 15.9881 4.94584 16.293 3.67635C16.5536 2.5923 17.0987 0.289978 18.2812 0.289978C19.6875 0.289978 22.5 0.758728 22.5 5.06256Z"
      fill={selected ? 'white' : '#0072CE'}
    />
  </svg>
);

const handHornsSvg = selected => (
  <svg
    width="30"
    height={selected ? '24' : '31'}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.9841 14.3072H8.67036V12.086C8.67036 11.1557 8.67087 10.2255 8.67139 9.29534C8.67267 6.97017 8.67396 4.64534 8.66721 2.31972C8.66407 1.11909 8.008 0.36929 6.94287 0.295718C5.76761 0.21432 4.94162 1.00482 4.9369 2.27276C4.92758 5.55625 4.93065 8.83973 4.93372 12.1232L4.93532 13.9143C4.93532 13.9957 4.94861 14.0778 4.96307 14.1671C4.97029 14.2117 4.97781 14.2582 4.9841 14.3072Z"
      fill={selected ? 'white' : '#0072CE'}
    />
    <path
      d="M21.4679 16.9651C20.5774 18.8592 18.6343 19.4572 16.2932 18.6463L16.2917 18.6479C16.2696 18.7778 16.2476 18.9062 16.2256 19.033C15.8102 21.4311 14.218 22.7977 11.7873 22.8274C11.1411 22.8347 10.4943 22.8323 9.84762 22.8298C9.52451 22.8286 9.20111 22.8274 8.87823 22.8274C8.24419 22.8274 7.79894 22.5801 7.74702 21.9007C7.7014 21.3012 8.13721 20.9302 8.89711 20.9177C9.33827 20.9111 9.77971 20.9134 10.2212 20.9156C10.8283 20.9188 11.4355 20.9219 12.0422 20.902C13.3276 20.8597 14.3313 19.8172 14.3313 18.5822C14.3297 17.3471 13.326 16.292 12.039 16.267C10.2832 16.2326 8.52581 16.2153 6.77157 16.2701C4.78606 16.3312 3.32445 17.7181 3.0743 19.6685C2.47644 24.3474 5.54439 28.9229 10.1998 29.9341C11.821 30.2873 13.5338 30.2878 15.2208 30.2884C15.4478 30.2884 15.6742 30.2885 15.8999 30.2894C21.5827 30.3145 25.6497 26.2336 25.6639 20.5702C25.667 18.9536 25.666 17.3364 25.6649 15.7194C25.6644 14.9111 25.6639 14.1025 25.6639 13.2944C25.6639 12.6018 25.6646 11.9091 25.6654 11.2163C25.6673 9.48381 25.6693 7.75065 25.6591 6.01863C25.6529 4.84148 24.9512 4.08855 23.875 4.04785C22.7265 4.00402 21.9367 4.77887 21.9304 6.0155C21.9251 7.13929 21.9267 8.26309 21.9283 9.38689C21.9309 11.2356 21.9336 13.0846 21.9052 14.9333C21.8958 15.6158 21.7573 16.3515 21.4679 16.9651Z"
      fill={selected ? 'white' : '#0072CE'}
    />
    <path
      d="M16.2608 12.7926C16.2635 12.9939 16.2663 13.1951 16.2663 13.3962H16.2647V15.2683C16.2647 15.3216 16.2647 15.3732 16.2694 15.4249C16.3575 16.458 17.1064 17.1515 18.1307 17.1515C19.1549 17.1515 19.9463 16.458 19.9824 15.4249C20.0281 14.1006 20.0233 12.7732 19.9856 11.4489C19.9557 10.4048 19.1911 9.70195 18.17 9.68316C17.1159 9.66438 16.3292 10.3766 16.2726 11.4489C16.2487 11.8958 16.2548 12.3443 16.2608 12.7926Z"
      fill={selected ? 'white' : '#0072CE'}
    />
    <path
      d="M14.3345 11.5596C14.332 11.4046 14.3296 11.2496 14.3296 11.095H14.328C14.328 10.9337 14.3307 10.7723 14.3335 10.6109C14.3395 10.2521 14.3456 9.89326 14.3217 9.53591C14.254 8.50121 13.4941 7.80776 12.4715 7.80463C11.4488 7.80306 10.6606 8.49339 10.6134 9.52495C10.5678 10.5362 10.5725 11.5521 10.6103 12.5633C10.6512 13.6387 11.4441 14.3509 12.4998 14.3337C13.5209 14.3181 14.2619 13.6168 14.3248 12.5758C14.3454 12.2384 14.3399 11.8988 14.3345 11.5596Z"
      fill={selected ? 'white' : '#0072CE'}
    />
  </svg>
);

// eslint-disable-next-line react/prop-types
const ReactionIcon = ({ name, selected, style, onPress }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const getIcon = () => {
    if (name === 'heart') {
      if (isHovered && !selected) return <Image src={heartHover} width={36} />;

      return heartSvg(selected);
    }

    if (name === 'grin_squint') {
      if (isHovered && !selected) return <Image src={grinHover} width={34} />;

      return grinSquintSvg(selected);
    }

    if (name === 'grin_hearts') {
      if (isHovered && !selected)
        return <Image src={heartEyesHover} width={34} />;

      return grinHeartsSvg(selected);
    }

    if (name === 'thumbs_up') {
      if (isHovered && !selected)
        return <Image src={thumbsUpHover} width={35} />;

      return thumbsUpSvg(selected);
    }

    if (name === 'hand_horns') {
      if (isHovered && !selected)
        return <Image src={handHornsHover} width={27} />;

      return handHornsSvg(selected);
    }

    return null;
  };

  if (name === 'emoji') return emojiSvg(selected);

  return (
    <Image
      style={style}
      className="reaction"
      onClick={onPress}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {getIcon()}
    </Image>
  );
};

export default ReactionIcon;
