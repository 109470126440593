import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
  Message,
  Segment,
  Form,
  Dimmer,
  Loader,
  Grid,
  Confirm,
  Button,
  Header,
  Table,
  Modal,
} from 'semantic-ui-react';
import BandDetails from '../BandDetails';
import KidAPI from '../../api/KidAPI';
import StringUtils from '../../utils/StringUtils';
import WindowUtils from '../../utils/WindowUtils';
import DateUtils from '../../utils/DateUtils';
import PageHeading from '../PageHeading/PageHeading';
import TrinityInstrumentChangeWarning from '../TrinityInstrumentChangeWarning/TrinityInstrumentChangeWarning';

class InstrumentChange extends Component {
  static propTypes = {
    kid: PropTypes.object.isRequired,
    kidSlots: PropTypes.array.isRequired,
    refreshAllTheThings: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const shouldDisplayTrinityWarning = this.kidIsTrinityEnrolled();
    this.state = {
      dateOfChange: DateUtils.today(),
      confirmingRejection: false,
      loading: true,
      shouldDisplayTrinityWarning,
    };
  }

  componentDidMount() {
    this.newSlot()
    this.oldSlot()
  }

  oldSlot = () => {
    const { kidSlots } = this.props;
    const oldSlot = kidSlots.find(slot => slot.attributes.is_delayed_instrument_change !== true);
    
    this.setState({oldSlot, loading: false})
  }

  newSlot = () => {
    const { kidSlots } = this.props;
    const newSlot = kidSlots.find(slot => slot.attributes.is_delayed_instrument_change);

    this.setState({newSlot})
  };

  reservedUntil = () => {
    const { newSlot } = this.state;

    return DateUtils.formatDate(newSlot.attributes.blocked_until);
  };

  isChangingBands = () => {
    const { newSlot, oldSlot } = this.state;
    return newSlot.relationships.band.data.id !== oldSlot.relationships.band.data.id
  }

  kidIsTrinityEnrolled = () => {
    const { kid } = this.props;
    return !!kid.attributes.active_trinity_enrolment.data
  }

  getActiveTrinityEnrolmentExamName = () => {
    const { kid } = this.props;
    return kid.attributes.active_trinity_enrolment.data.attributes.exam_name;
  }

  getBandLeaderName = () => {
    const { kid } = this.props;
    return kid.attributes.timetable_bl.data.attributes.band_leader_name;
  }

  dismissTrinityWarning = () => this.setState({ shouldDisplayTrinityWarning: false })

  trinityWarnMessage = () => {
    return (
      <TrinityInstrumentChangeWarning
        examName={this.getActiveTrinityEnrolmentExamName()}
        bandLeaderName={this.getBandLeaderName()}
        onDismiss={this.dismissTrinityWarning}
      />
    );
  }

  acceptInstrumentChange = async () => {
    const { kid, refreshAllTheThings } = this.props
    const { dateOfChange, newSlot } = this.state;

    const config = {
      data: {
        slot: {
          change_is_pending_approval: false,
          blocked_until: DateUtils.formatDateAsIso(dateOfChange),
        },
      },
    };
    await KidAPI.acceptInstrumentChange(kid.id, newSlot.id, config);
    refreshAllTheThings();
    WindowUtils.scrollToTop();
    this.setState({ instrumentChanged: true });
  };

  rejectInstrumentChange = async () => {
    const { kid, refreshAllTheThings } = this.props;
    const { newSlot } = this.state;

    const slotId = newSlot.id;
    this.setState({ loading: true });
    const config = { data: {} };
    await KidAPI.rejectInstrumentChange(kid.id, slotId, config);
    refreshAllTheThings();
    WindowUtils.scrollToTop();
    this.setState({ instrumentChangeRejected: true });
  };

  confirmRejection = () => this.setState({ confirmingRejection: true });

  cancelRejection = () => this.setState({ confirmingRejection: false });

  rejectionConfirmation = () => {
    const { kid } = this.props;
    const { confirmingRejection } = this.state;

    const { name } = kid.attributes;
    return (
      <Confirm
        open={confirmingRejection}
        header="Are you sure?"
        content={`We will save that ${name} does not want to play ${this.newInstrumentType()}. You can update these preferences any time in the Kids section.`}
        onCancel={this.cancelRejection}
        onConfirm={this.rejectInstrumentChange}
      />
    );
  };

  instrumentChangeRejectedMessage = () => {
    const { kid } = this.props;
    const { name, assigned_instrument_type } = kid.attributes;
    return (
      <Message positive>
        <Message.Header>Thank you</Message.Header>
        <Message.Content>
          {`${name} is staying on ${StringUtils.humanize(
            assigned_instrument_type,
          )} - you can still update instrument preferences at any time.`}
        </Message.Content>
      </Message>
    );
  };

  instrumentChangedMessage = () => {
    const { kid } = this.props
    const { dateOfChange } = this.state;
    return (
      <Message positive>
        <Message.Header>Thank you</Message.Header>
        <Message.Content>
          {`${kid.attributes.name} is moving to ${this.newInstrumentType()} - this will change on ${DateUtils.formatDate(
            dateOfChange,
          )}`}
        </Message.Content>
      </Message>
    );
  };

  actionButtons = () => {
    const { kid } = this.props

    const instrument = this.newInstrumentType();
    return (
      <Grid.Row className="instrument-change-actions">
        <Grid.Column textAlign="center">
          <Form.Button positive size="huge" onClick={this.acceptInstrumentChange}>
            {`Yes, change to ${instrument} `}
          </Form.Button>
          <Form.Button negative size="huge" onClick={this.confirmRejection}>
            {`No, stay on ${StringUtils.humanize(kid.attributes.assigned_instrument_type)} `}
          </Form.Button>
        </Grid.Column>
      </Grid.Row>
    );
  };

  bandStatus = () => (this.isChangingBands() ? 'New Band' : 'Same Band');

  newBandDetails = () => {
    const { newSlot } = this.state;
    return (
      this.isChangingBands() && (
        <div>
          <h3>New Band Details</h3>
          <BandDetails bandId={newSlot.relationships.band.data.id} />
        </div>
      )
    );
  };

  handleDayClick = (day) => {
    this.setState({ dateOfChange: day, amendingDate: false });
  };

  newInstrumentType = () => {
    const { newSlot } = this.state;

    return StringUtils.humanize(newSlot.attributes.instrument_type);
  };

  datePicker = () => {
    const { amendingDate, dateOfChange } = this.state;
    return (
      <Modal centered open={amendingDate} onClose={this.cancelAmendingDate} closeIcon size="mini">
        <Modal.Content>
          <Segment textAlign="center">
            <Header as="h2" textAlign="center">
              <Header.Content>Date of Change</Header.Content>
            </Header>
            <DayPicker
              onDayClick={this.handleDayClick}
              selectedDays={dateOfChange}
              month={DateUtils.today()}
              initialMonth={DateUtils.today()}
              disabledDays={[
                {
                  before: DateUtils.today(),
                  after: DateUtils.thirtyDaysFromNow(),
                },
              ]}
            />
          </Segment>
        </Modal.Content>
      </Modal>
    );
  };

  amendDate = () => this.setState({
    amendingDate: true,
  });

  cancelAmendingDate = () => this.setState({
    amendingDate: false,
  });

  formattedDateOfChange = () => {
    const { dateOfChange } = this.state;
    return DateUtils.formatDate(dateOfChange);
  };

  changeButton = () => (
    <Button size="tiny" onClick={this.amendDate} compact positive floated="right" content="Change" />
  );

  changeSummary = () => (
    <Table celled unstackable compact>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <strong>New Instrument</strong>
          </Table.Cell>
          <Table.Cell>{this.newInstrumentType()}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Reserved Until</strong>
          </Table.Cell>
          <Table.Cell>{this.reservedUntil()}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Date of Change</strong>
          </Table.Cell>
          <Table.Cell>
            {this.formattedDateOfChange()}
            {this.changeButton()}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Same Band or New Band</strong>
          </Table.Cell>
          <Table.Cell>{this.bandStatus()}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );

  render() {
    const { kid } = this.props
    const { loading, instrumentChanged, instrumentChangeRejected, shouldDisplayTrinityWarning } = this.state;
    if (instrumentChanged) {
      return this.instrumentChangedMessage();
    }
    if (instrumentChangeRejected) {
      return this.instrumentChangeRejectedMessage();
    }
    if (loading) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }
    return (
      <div>
        <PageHeading
          heading={`Instrument Change Available for ${kid.attributes.name}`}
          subHeading="Please check and confirm"
        />

        <Segment>
          {shouldDisplayTrinityWarning && this.trinityWarnMessage()}
          {this.rejectionConfirmation()}
          {this.datePicker()}

          <Form>
            <Grid padded stackable>
              <Grid.Row>
                <Grid.Column>{this.changeSummary()}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">{this.newBandDetails()}</Grid.Column>
              </Grid.Row>
              <Grid.Row centered>{this.actionButtons()}</Grid.Row>
            </Grid>
          </Form>
        </Segment>
      </div>
    );
  }
}

export default InstrumentChange;
