const contacts = Object.freeze({
  SUPPORT_EMAIL: "info@rocksteadymusicschool.com",
  SUPPORT_TEL: '+443301130330',
  SUPPORT_TEL_HUMANIZED: '0330 113 0330',
  SUPPORT_HOURS: 'Monday-Thursday 8am-8pm, Friday 8am-6pm',
  FACEBOOK_URL: "https://www.facebook.com/rocksteadymusicschool",
  TWITTER_URL: "https://twitter.com/RSMusicSchool",
  INSTAGRAM_URL: "https://www.instagram.com/rocksteadymusicschool",
  WHATSAPP_URL: "https://api.whatsapp.com/send?phone=447457402950"
});

export default contacts;
