import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Header, Segment } from 'semantic-ui-react';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';

export default function Section({ id, children, sectionNumber, headingText }) {
  const { page: { httpRequestPending } } = useKidRegistration();

  const sectionNumberPrefix = sectionNumber ? `${sectionNumber}. ` : '';

  return(
    <Segment id={id} loading={httpRequestPending}>
      {(sectionNumber || headingText) && (
        <Divider horizontal>
          <Header as="h3">{`${sectionNumberPrefix}${headingText.toUpperCase()}`}</Header>
        </Divider>
      )}
      {children}
    </Segment>
  );
}

Section.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  sectionNumber: PropTypes.number,
  headingText: PropTypes.string,
}

Section.defaultProps = {
  id: undefined,
  sectionNumber: null,
  headingText: null,
}
