import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';
import StringUtils from '../../../utils/StringUtils';

class StatusLabel extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    size: PropTypes.string,
    exception_type: PropTypes.string,
  };

  static defaultProps = {
    size: 'tiny',
    exception_type: '',
  };

  get status() {
    const { status } = this.props;
    return status;
  }

  labelColor = () => {
    if (this.status.indexOf('enrolled') > -1) return 'green';
    if (this.status.indexOf('waiting') > -1) return 'blue';
    return null;
  };

  labelText = () => {
    if (this.status.indexOf('enrolled') > -1) return 'Enrolled';
    return StringUtils.humanize(this.status);
  };

  frozenLabel = () => {
    const { exception_type, size } = this.props;

    if (exception_type === 'frozen_during_school_closure') {
      return (
        <Label horizontal size={size} color='yellow' className="wrapping-label">
          Paused
        </Label>
      )
    }

    return null;
  }

  render() {
    const { size } = this.props;
    return (
      <div>
        <Label horizontal size={size} color={this.labelColor()} className="wrapping-label">
          {this.labelText()}
        </Label>
        {this.frozenLabel()}
      </div>
    );
  }
}

export default StatusLabel;
