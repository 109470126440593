import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';
import HTTPWrapper from '../utils/HTTPWrapper';

class SchoolUserAPI {
  signIn = config => HTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/school_users/sign_in.json`,
    config,
  });

  resetPasswordForSchoolUser = config => HTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/school_users/passwords.json`,
    config,
  });

  setPasswordForSchoolUser = config => HTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools/school_users/set_password.json`,
    config,
  });

  getConcertVideosForSchoolUser = () => AuthenticatedHTTPWrapper.get({
    config: { isSchool: true },
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools/concert_videos.json`,
  })
}

export default new SchoolUserAPI();
