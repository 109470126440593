import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Container, Button, Loader, Message } from 'semantic-ui-react';
import JWTManager from '../../utils/JWTManager';
import WindowUtils from '../../utils/WindowUtils';
import paths from '../../consts/paths';
import SchoolUserAPI from '../../api/SchoolUserAPI';
import logo from '../../images/rocksteady_logo_500.png';
import SiteFooter from '../SiteFooter';
import LessonHelper from '../../data/lessons/LessonHelper';
import MediaContentCard from '../MediaContentCard';
import VideoModal from '../VideoModal/VideoModal';

class SchoolsLandingPage extends React.Component {
  state = {
    authToken: JWTManager.getSchoolsToken() || null,
    concertVideos: [],
    videoModalOpen: false,
    selectedVideoTitle: '',
    selectedVideoID: null,
    loading: false,
    error: false,
  }

  componentDidMount() {
    const { authToken } = this.state;
    if (authToken) {
      this.setState({ loading: true }, () => {
        SchoolUserAPI.getConcertVideosForSchoolUser().then((response) => {
          this.setState({ concertVideos: response.data.data, loading: false });
        })
          .catch(() => this.setState({ error: true, loading: false}))
      })
    }
  }

  thumbURL = (id) => (`${LessonHelper.getThumbnailBaseUrl()}${id}.jpg`)

  getAuthToken = () => {
    const authToken = JWTManager.getSchoolsToken();

    if (authToken) {
      this.setState({ authToken })
    } else {
      // if the user is a grown up and has found their way here by
      // mistake, redirect them
      const grownUpToken = JWTManager.getToken();
      const path = grownUpToken ? paths.ROOT : paths.SCHOOLS_SIGN_IN;
      WindowUtils.replaceLocation(path);
    }
  }

  header = () => (
    <div className="site-header">
      <img src={logo} className="logo" alt="logo" />
      <div className="header-title">
        <Link to={paths.SCHOOLS_SIGN_OUT}>
          <Button
            id="landing-page-button"
            basic
            color="blue"
            content="Sign Out"
            floated="right"
          />
        </Link>
        <h4 id="landing-page-header">Schools Portal</h4>
      </div>
    </div>
  );

  title = () => (
    <h1>Concert Videos</h1>
  );

  handleVideoSelect = (vimeoID, name) => (
    this.setState({
      videoModalOpen: true,
      selectedVideoID: vimeoID,
      selectedVideoTitle: name,
    })
  );

  handleVideoClose = () => (
    this.setState({
      videoModalOpen: false,
      selectedVideoID: null,
      selectedVideoTitle: '',
    })
  );

  pageContent = () => {
    const { concertVideos, loading, error } = this.state;

    if (error) {
      return (
        <Grid.Row centered>
          <Message
            negative
            content="There was a problem fetching your videos, try again later"
          />
        </Grid.Row>
      );
    }

    if (loading) {
      return (
        <React.Fragment>
          <Grid.Row centered>
            <Loader active inline size="massive" />
          </Grid.Row>
          <Grid.Row centered>
            <h2>Fetching your concert videos...</h2>
          </Grid.Row>
        </React.Fragment>
      )
    }

    if (concertVideos.length === 0) {
      return (
        <Grid.Row centered>
          <h2>There are currently no concert videos available for your timetable.</h2>
        </Grid.Row>
      )
    }

    const thumbs = concertVideos.map((cv) => {
      return (
        <Grid.Column key={`concert_video_${cv.id}`} style={{ marginBottom: '20px' }}>
          <MediaContentCard
            imageSource={this.thumbURL(cv.attributes.vimeo_id)}
            onClick={() => this.handleVideoSelect(cv.attributes.vimeo_id, cv.attributes.name)}
          />
        </Grid.Column>
      )
    })

    return (
      <React.Fragment>
        <Grid.Row centered columns={2}>
          <Grid.Column>
            <Message info>
              <Message.Header>Disclaimer</Message.Header>
              <br />
              <Message.Content>
                These videos contain footage of children.
                Making copies or broadcasting these videos publicly, online or in any other way,
                including via social media, is not permitted.
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={3}>
          {thumbs}
        </Grid.Row>
      </React.Fragment>
    )
  }

  videoModal = () => {
    const {
      selectedVideoTitle,
      selectedVideoID,
      videoModalOpen
    } = this.state;

    if (videoModalOpen) {
      return (
        <VideoModal
          headerText={selectedVideoTitle}
          videoId={selectedVideoID}
          onClose={this.handleVideoClose}
        />
      );
    }

    return null;
  }

  render() {
    const { authToken } = this.state;

    if (!authToken) {
      this.getAuthToken();
    };

    return (
      <>
        <Container className="site-container app">
          {this.header()}
          <Grid stackable style={{ display: 'block'}}>
            <Grid.Row centered>
              <br />
              {this.title()}
            </Grid.Row>
            {this.pageContent()}
          </Grid>
          {this.videoModal()}
        </Container>
        <SiteFooter />
      </>
    );
  }
}

export default SchoolsLandingPage;
