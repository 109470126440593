import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'semantic-ui-react';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';
import Instrument from './Instrument';

export default function SelectedEnrolmentOption({ onDeselect }) {
  const {
    kid: { enrolmentInstrument, waitlistInstruments, enrollingAndJoiningWaitlist },
  } = useKidRegistration();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const waitlistInstrument = waitlistInstruments[0];
  const consideredInstrument = enrolmentInstrument || waitlistInstrument;

  const buttonAtBottom = isMobile && enrollingAndJoiningWaitlist;

  const doubleInstrument = () => (
    <div className="double-instruments flex-row-aligned-center space-between">
      <div>
        <strong>Enrol today on:</strong>
        <Instrument instrumentName={enrolmentInstrument} />
      </div>
      <div className="flex-row-aligned-center px-16">
        <strong>+</strong>
      </div>
      <div>
        <strong>Join the waitlist for:</strong>
        <Instrument showAvailability={false} instrumentName={waitlistInstrument} />
      </div>
    </div>
  );

  const singleInstrument = () => (
    <Instrument
      instrumentName={consideredInstrument}
      showAvailability={consideredInstrument === enrolmentInstrument}
      additionalText={consideredInstrument === waitlistInstrument ? 'Join waitlist' : undefined}
    />
  );

  return (
    <div id="selected-choice" className="instrument-card">
      <div className="full-width mb-8">
        <strong>Selected:</strong>
      </div>
      <div className={`full-width flex-row-aligned-center ${buttonAtBottom ? 'centered' : 'space-between'}`}>
        {enrollingAndJoiningWaitlist ? doubleInstrument() : singleInstrument()}
        {!buttonAtBottom && (
          <div className="max-half-width">
            <Button fluid={isMobile} basic color="blue" onClick={onDeselect}>Change</Button>
          </div>
        )}
      </div>
      {buttonAtBottom && (
        <div className="full-width mt-8">
          <Button fluid basic color="blue" onClick={onDeselect}>Change</Button>
        </div>
      )}
    </div>
  );
}

SelectedEnrolmentOption.propTypes = {
  onDeselect: PropTypes.func.isRequired,
};
