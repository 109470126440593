import React, { useEffect, useMemo } from 'react';
import { Button, Divider, Grid, Header, Segment } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';
import InstrumentChoiceCard from './InstrumentChoiceCard';
import OFFERED_INSTRUMENTS from '../../consts/instruments';
import InstrumentPreferenceChoices from './InstrumentPreferenceChoices';
import Section from './Section';
import WindowUtils from '../../utils/WindowUtils';
import SelectedEnrolmentOption from './SelectedEnrolmentOption';

export default function InstrumentChoiceSection() {
  const {
    kid: {
      kidName,
      enrolmentInstrument,
      offerFirstPreference,
      waitlistInstruments,
      setAllInstrumentsForWaitlist,
      justJoiningWaitlist,
      setEnrolmentInstrument,
      onWaitlistFor,
      resetKidInstrumentState,
      handleWaitlistToggleForInstrument,
      addInstrumentToWaitlistInstruments,
      setPreferenceInstrument,
    },
    timetable: {
      availablePlacesKnown,
      placesAvailable,
      instrumentsWithAvailablePlaces,
      upSellAvailable,
      handleJoinWaitlist,
    },
  } = useKidRegistration();

  const isTabletOrSmaller = useMediaQuery({ query: '(max-width: 900px)' });

  const choiceMade = useMemo(() => !!enrolmentInstrument || !!waitlistInstruments.length, [enrolmentInstrument, waitlistInstruments]);
  const chosenInstrument = useMemo(() => choiceMade ? (enrolmentInstrument || waitlistInstruments[0]) : null, [choiceMade, enrolmentInstrument, waitlistInstruments]);

  useEffect(() => {
    if (enrolmentInstrument) {
      WindowUtils.scrollToAnchor('summary');
    } else if (choiceMade && placesAvailable) {
      WindowUtils.scrollToAnchor('selected-choice');
    }
  }, [choiceMade]);

  const handleWaitlistWildCardClick = async () => {
    await setAllInstrumentsForWaitlist();
    WindowUtils.scrollToAnchor('instrument-preferences');
  };

  const onInstrumentSelectCallback = (instrumentName, upSellFrom) => () => {
    if (instrumentsWithAvailablePlaces.includes(instrumentName)) {
      setEnrolmentInstrument(instrumentName)
      // If we are upselling the instrument we need to make sure that
      // the first preference is set to the instrument the child would
      // prefer to be on.
      if (upSellFrom) { setPreferenceInstrument(1, upSellFrom) }
    } else if (placesAvailable && onWaitlistFor(instrumentName)) {
      handleJoinWaitlist();
    } else if (!placesAvailable) {
      handleWaitlistToggleForInstrument(instrumentName);
    } else {
      addInstrumentToWaitlistInstruments(instrumentName);
    }
  };

  const noAvailabilityStatement = () => (
    <div>
      <Header as="h4">{`Add ${kidName} to the waitlist`}</Header>
      <p>
        There are no suitable vacant spaces in any bands for your child&rsquo;s age group at the moment, but you can join our waitlist.
      </p>
      <p>We constantly review the waitlist and create space whenever we can.</p>
      <p>
        You can improve your chance of getting a space by joining the waitlist today, and choosing all the instruments that your child would like to play.
      </p>
      <br />
    </div>
  );

  const sectionDescription = () => {
    if (placesAvailable && choiceMade) return null;
    if (!availablePlacesKnown) return <p>Please fill in your details to see our available places.</p>;
    if (!placesAvailable) return noAvailabilityStatement();

    return <Header as="h4">What would you like to do?</Header>;
  };

  const selectedChoice = () => (
    <Grid.Column key="selected-instrument-choice">
      <SelectedEnrolmentOption
        onDeselect={resetKidInstrumentState}
      />
    </Grid.Column>
  );

  const choice = (instrumentName, upSellFrom) => (
    <Grid.Column key={`${instrumentName}-choice-card`}>
      <InstrumentChoiceCard
        instrumentName={instrumentName}
        upSellFrom={upSellFrom}
        onSelect={onInstrumentSelectCallback(instrumentName, upSellFrom)}
      />
    </Grid.Column>
  );

  const waitlistWildCardButton = () => (
    <div>
      <Header as="h4">What instrument would your kid consider playing?</Header>
      <Button primary onClick={handleWaitlistWildCardClick}>
        Happy to try any instrument
      </Button>
      <Divider horizontal>OR</Divider>
    </div>
  );

  const choices = () => (
    <div>
      {!placesAvailable && waitlistWildCardButton()}
      <Grid stackable columns={(placesAvailable && choiceMade) || isTabletOrSmaller ? 1 : 2}>
        {placesAvailable && choiceMade ? selectedChoice() : OFFERED_INSTRUMENTS.map(instrumentName => choice(instrumentName))}
      </Grid>
    </div>
  );

  const upsellChoices = () => (
    <div id="upsell-choices">
      <Header as="h4">{`Would you like to join the band today on another instrument while you wait for a space on ${chosenInstrument}?`}</Header>
      <p>
        If you enrol on another instrument now, you get all the teamwork, confidence and wellbeing benefits of
        {' '}
        Rocksteady right away, and we&rsquo;ll swap your child
        to
        {` ${chosenInstrument}, `}
        subject to availability.
      </p>
      <Grid columns={1}>
        {instrumentsWithAvailablePlaces.map(instrumentName => choice(instrumentName, chosenInstrument))}
        {choice(chosenInstrument)}
      </Grid>
    </div>
  );

  const joinWaitlistButton = () => (
    <Segment basic textAlign="center">
      <Button primary onClick={handleJoinWaitlist}>Join waitlist</Button>
    </Segment>
  );

  return (
    <Section id="instrument-choices" sectionNumber={2} headingText="choose an instrument">
      {sectionDescription()}
      {availablePlacesKnown && choices()}
      {upSellAvailable && upsellChoices()}
      {offerFirstPreference && <InstrumentPreferenceChoices />}
      {!upSellAvailable && justJoiningWaitlist && joinWaitlistButton()}
    </Section>
  );
};
