import React, { Component } from 'react';
import {
  Form, Button, Message, Grid,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import warnings from '../../consts/warnings';
import StringUtils from '../../utils/StringUtils';
import UserAPI from '../../api/UserAPI';
import paths from '../../consts/paths';

class GetPassword extends Component {
  static propTypes = {
    forgottenPassword: PropTypes.bool,
  };

  static defaultProps = {
    forgottenPassword: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      invitationSent: false,
      passwordResetSent: false,
      loading: false,
    };
  }

  handleEmailChange = (event) => {
    this.setState({ showValidationErrors: false, email: event.target.value });
  };

  formValid = () => {
    const { email } = this.state;
    return StringUtils.isEmailIsh(email);
  };

  submitForm = () => {
    const { forgottenPassword } = this.props;
    this.setState(
      {
        showValidationErrors: true,
      },
      () => {
        if (this.formValid()) {
          this.setState(
            {
              loading: true,
            },
            () => {
              return forgottenPassword ? this.resetPassword() : this.createUser();
            },
          );
        }
      },
    );
  };

  resetPassword = async () => {
    const { email } = this.state;
    const config = {
      data: {
        user: {
          email,
        },
      },
    };
    try {
      await UserAPI.sendResetPasswordInstructions(config);
      this.setState({ passwordResetSent: true, loading: false });
    } catch {
      this.setState({ apiError: true, loading: false });
    }
  }

  createUser = async () => {
    const { email } = this.state;
    const config = {
      data: {
        user: {
          email,
        },
      },
    };
    try {
      await UserAPI.createUser(config);
      this.setState({ invitationSent: true, loading: false });
    } catch {
      this.setState({ apiError: true, loading: false });
    }
  };

  errorMessage = ({ header, content } = {}) => (
    <Grid.Row centered>
      <Message negative header={header} content={content} />
    </Grid.Row>
  );

  invalidFormatError = () => this.submittedAndInvalid()
    && this.errorMessage({
      content: warnings.EMAIL_INPUT,
    });

  submittedAndInvalid = () => {
    const { showValidationErrors } = this.state;
    return showValidationErrors && !this.formValid();
  };

  apiErrorMessage = () => {
    const { apiError } = this.state;
    return (
      apiError
      && this.errorMessage({
        header: 'Sorry, there was a problem processing your request.',
        content: warnings.CONTACT_THE_OFFICE,
      })
    );
  };

  descriptionMessage = () => {
    const { forgottenPassword } = this.props;
    if (forgottenPassword) {
      return 'Please enter your e-mail address below to be sent instructions to reset your password';
    }
    return (
      <p>
        To get started we need to send you instructions to set up a password.
        <br />
        <br />
        Please enter the e-mail address you used to register with Rocksteady below.
      </p>
    );
  };

  buttonCopy = () => {
    const { forgottenPassword } = this.props;
    return forgottenPassword ? 'Reset Password' : 'Get Password';
  };

  invitationSentMessage = () => (
    <Grid relaxed>
      <Grid.Row centered>
        <Message
          className="invitation-sent"
          compact
          success
          size="big"
          header="Email sent. Please check your inbox"
          content="If you are registered with Rocksteady, you should receive an email invitation in a few minutes. If you do not get the email, please check your junk/trash/spam folders"
        />
      </Grid.Row>
      <Grid.Row centered>
        <a href={paths.SIGN_IN} className="ui huge positive button">
          Sign In
        </a>
      </Grid.Row>
    </Grid>
  );

  passwordResetSentMessage = () => (
    <Grid relaxed>
      <Grid.Row centered>
        <Message
          className="invitation-sent"
          compact
          success
          size="big"
          header="Password Reset Invitation Sent"
          content="If you are registered with Rocksteady, you should receive an email in a few minutes. If you do not get the email, please check your junk/trash/spam folders"
        />
      </Grid.Row>
      <Grid.Row centered>
        <a href={paths.SIGN_IN} className="ui huge positive button">
          Sign In
        </a>
      </Grid.Row>
    </Grid>
  )

  render() {
    const { email, invitationSent, loading, passwordResetSent } = this.state;
    if (invitationSent) {
      return this.invitationSentMessage();
    }
    if (passwordResetSent) {
      return this.passwordResetSentMessage();
    }
    return (
      <Form onSubmit={this.submitForm} loading={loading} size="big">
        <Grid relaxed padded>
          <Grid.Row centered>
            <Message>
              <Message.Content>{this.descriptionMessage()}</Message.Content>
            </Message>
          </Grid.Row>
          {this.invalidFormatError()}
          {this.apiErrorMessage()}
          <Grid.Row centered>
            <Form.Field>
              <Form.Input
                placeholder="E-mail...."
                onChange={this.handleEmailChange}
                value={email}
                error={this.submittedAndInvalid()}
              />
            </Form.Field>
          </Grid.Row>
          <Grid.Row centered>
            <Button size="huge" type="submit" positive>
              {this.buttonCopy()}
            </Button>
          </Grid.Row>
          <Grid.Row centered>
            <a href={paths.SIGN_IN}>Back to sign in</a>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default GetPassword;
