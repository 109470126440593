import React, { useEffect } from 'react';
import { Message } from 'semantic-ui-react';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';
import constructOnSignUpObject from './ga';

export default function SuccessMessage() {
  const {
    resetState,
    kid: { kidId, kidName, kidSchoolYear, enrolmentInstrument },
    timetable: { timetableDisplayName, lessonsAreOn },
    payment: { regularMonthlyAmount },
    page: { successAction },
  } = useKidRegistration();

  const ACTION_CONTENT = {
    joinedWaitlist: {
      headingText: 'Added to the Waitist',
      contentText: `We have added ${kidName} to the waitlist at ${timetableDisplayName}. We will keep you updated when an opportunity arises in a band.`
    },
    enrolled: {
      headingText: 'Enrolment Complete',
      contentText: `Thank you for booking ${kidName} onto Rocksteady lessons. You'll soon receive an email from us with all the information you need to get started.`
    }
  };

  useEffect(() => {
    if (successAction === 'enrolled') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(
        constructOnSignUpObject({
          kidId,
          kidSchoolYear,
          enrolmentInstrument,
          timetableDisplayName,
          lessonsAreOn,
          regularMonthlyAmount,
        }),
      );
    }
  }, [successAction]);

  return(
    <Message positive onDismiss={resetState}>
      <Message.Header>{ACTION_CONTENT[successAction].headingText}</Message.Header>
      <Message.Content>
        {ACTION_CONTENT[successAction].contentText}
      </Message.Content>
    </Message>
  );
};
