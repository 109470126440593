import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class GrownUpAPI {
  fetchGrownUpFromToken = () => AuthenticatedHTTPWrapper.get({
    url: `${
      process.env.REACT_APP_ABBEY_ROAD_URL
    }/api/parent_portal/fetch_grown_up_from_token.json`,
  });

  updateGrownUp = (id, data) => AuthenticatedHTTPWrapper.patch({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/grown_ups/${id}.json`,
    data,
  });

  fetchKidsForGrownUp = id => AuthenticatedHTTPWrapper.get({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/grown_ups/${id}/kids.json`,
  });

  enrolAKidOrJoinWaitingList = (id, config) => AuthenticatedHTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/grown_ups/${id}/kids.json`,
    config,
  });

  enrolReservedKid = (kidId, grownUpId, config) => AuthenticatedHTTPWrapper.post({
    url: `${
      process.env.REACT_APP_ABBEY_ROAD_URL
    }/staff/grown_ups/${grownUpId}/kids/${kidId}/enrol_from_reserved.json`,
    config,
  });
}

export default new GrownUpAPI();
