import React from 'react';
import { Form } from 'semantic-ui-react';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';

export default function SchoolYearDropdown() {
  const {
    kid: { kidSchoolYear, setKidSchoolYear },
    timetable: { timetableId, minSchoolYear, maxSchoolYear },
    consts: { CURRENT_ACADEMIC_SCHOOL_YEAR },
  } = useKidRegistration();

  const yearValues = [
    {
      key: 'key-year-pre',
      value: -1,
      text: `Starts Reception in September ${CURRENT_ACADEMIC_SCHOOL_YEAR + 1}`,
    },
    { key: 'key-year-r', value: 0, text: 'Reception' },
    { key: 'key-year-1', value: 1, text: 'Year 1' },
    { key: 'key-year-2', value: 2, text: 'Year 2' },
    { key: 'key-year-3', value: 3, text: 'Year 3' },
    { key: 'key-year-4', value: 4, text: 'Year 4' },
    { key: 'key-year-5', value: 5, text: 'Year 5' },
    { key: 'key-year-6', value: 6, text: 'Year 6' },
    { key: 'key-year-7', value: 7, text: 'Year 7' },
    { key: 'key-year-8', value: 8, text: 'Year 8' },
    { key: 'key-year-9', value: 9, text: 'Year 9' },
    { key: 'key-year-10', value: 10, text: 'Year 10' },
    { key: 'key-year-11', value: 11, text: 'Year 11' },
    { key: 'key-year-12', value: 12, text: 'Year 12' },
    { key: 'key-year-13', value: 13, text: 'Year 13' },
  ];

  const yearOptions = () => yearValues.filter(({ value }) => value >= minSchoolYear && value <= maxSchoolYear);

  const currentSchoolYearStarted = () => `September ${CURRENT_ACADEMIC_SCHOOL_YEAR}`;

  const placeholder = () => {
    if (!timetableId) {
      return 'Please select your school';
    }
    return `as of School Year (${currentSchoolYearStarted()})`;
  };

  const handleSchoolYearChange = (_e, { value }) => setKidSchoolYear(value);

  return (
    <Form.Dropdown
      disabled={!timetableId}
      selection
      compact
      options={yearOptions()}
      placeholder={placeholder()}
      onChange={handleSchoolYearChange}
      value={kidSchoolYear}
      label="Child's current School Year"
    />
  );
};
