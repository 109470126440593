export default class FormUtils {
  constructor({ requiredFields, uneditableFields, updateCallback } = {}) {
    this.requiredFields = requiredFields;
    this.uneditableFields = uneditableFields;
    this.updateCallback = updateCallback;
  }

  noFieldsMissing = (object) => {
    for (let i = 0; i < this.requiredFields.length; i += 1) {
      const field = this.requiredFields[i];
      const value = object[field];
      if (this.valueMissing(value)) return false;
    }
    return true;
  };

  missingFields = (object) => {
    const missingFields = [];
    this.requiredFields.forEach((field) => {
      const value = object[field];
      if (this.valueMissing(value)) {
        missingFields.push(field);
      }
    });
    return missingFields;
  };

  valueMissing = value => !value || value === 'not_set';

  handleRadioOrDropdownChange = name => (e, { value }) => {
    this.updateCallback(name, value);
  };

  handleCheckbox = name => (e, { checked }) => {
    const value = { checked }.checked;
    this.updateCallback(name, value);
  };

  handleInputChange = name => (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.updateCallback(name, value);
  };

  fieldIsRequired = field => this.requiredFields.indexOf(field) > -1;

  fieldNotEditable = field => this.uneditableFields.indexOf(field) > -1;

  requiredAndMissing = (field, value) => this.fieldIsRequired(field) && this.valueMissing(value);
}
