import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, Segment, Grid, Table, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import StatusLabel from '../KidForm/StatusLabel/StatusLabel';
import WouldDoOptions from '../KidForm/InstrumentPreferences/WouldDoOptions';
import TimetableUtils from '../../utils/TimetableUtils';
import InstrumentUtils from '../../utils/InstrumentUtils';
import TimetableAPI from '../../api/TimetableAPI';
import paths from '../../consts/paths';
import personAvatar from '../../images/portal/person_avatar.svg';

class KidView extends Component {
  static propTypes = {
    kidAttributes: PropTypes.object.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    timetableAttributes: PropTypes.object.isRequired
  };

  state = {
    timetableIsActive: false,
  }

  componentDidMount() {
    const { kidAttributes } = this.props;
    TimetableAPI.getPricingInfo(kidAttributes.timetable_id).then((response) => {
      if (response.data.lessons_are_on) {
        this.setState({ timetableIsActive: true });
      }
    }).catch(() => {});
  }

  bandLeaderProfileLink = () => {
    const { timetableAttributes } = this.props;
    if (timetableAttributes.band_leader_id) {
      return (
        <span>
          <Link to={paths.STAFF_PROFILE(timetableAttributes.band_leader_id)}>view profile</Link>
        </span>
      );
    }
    return null;
  };

  bandLeaderInfo = () => {
    const { timetableAttributes } = this.props;
    const { band_leader_name: bandLeaderName } = timetableAttributes;
    if (bandLeaderName) {
      return (
        <Table.Row>
          <Table.Cell>
            <strong>Band Leader</strong>
          </Table.Cell>
          <Table.Cell>
            {bandLeaderName}
            {' '}
            {this.bandLeaderProfileLink()}
          </Table.Cell>
        </Table.Row>
      );
    }
    return null;
  };

  delayedCancellationInfo = () => {
    const { kidAttributes } = this.props;
    if (
      kidAttributes.delayed_cancellation_date &&
      kidAttributes.status.indexOf('enrolled') > -1
    ) {
      return (
        <Label horizontal color="orange">
          Cancelling on
          {' '}
          {kidAttributes.delayed_cancellation_date}
        </Label>
      );
    }
    return null;
  };

  concertVideoPermissionInfo = () => {
    const { kidAttributes } = this.props;
    let text;

    switch(kidAttributes.concert_video_permission) {
      case true:
        text = 'Yes';
        break;
      case false:
        text = 'No';
        break;
      default:
        text = 'Not Set';
    }

    return (
      <Table.Row key="key-table-row-concert-permission">
        <Table.Cell>
          <strong>Concert Video Permission</strong>
        </Table.Cell>
        <Table.Cell>{text}</Table.Cell>
      </Table.Row>
    )
  }

  summaryTable = () => {
    const { timetableAttributes, kidAttributes } = this.props;
    const { status, year, exception_type } = kidAttributes;

    return (
      <Table basic="very" collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <strong>Status</strong>
            </Table.Cell>
            <Table.Cell>
              <StatusLabel status={status} size="medium" exception_type={exception_type} />
              {this.delayedCancellationInfo()}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <strong>Year</strong>
            </Table.Cell>
            <Table.Cell>{TimetableUtils.formattedYear(year)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <strong>School</strong>
            </Table.Cell>
            <Table.Cell>{timetableAttributes.display_name}</Table.Cell>
          </Table.Row>
          {this.bandLeaderInfo()}
          {this.bandAndSongInfo()}
          {this.instrumentInfo()}
          {this.concertVideoPermissionInfo()}
        </Table.Body>
      </Table>
    );
  };

  instrumentInfo = () => {
    const { kidAttributes } = this.props;
    const { assigned_instrument_type: instrument } = kidAttributes;
    if (this.isEnrolled()){
      return(
        <Table.Row key="key-table-row-band-name">
          <Table.Cell>
            <strong>Instrument</strong>
          </Table.Cell>
          <Table.Cell>{InstrumentUtils.humanize(instrument) || 'Not Set'}</Table.Cell>
        </Table.Row>
      )
    }
    return []
  }

  showEnrolButton = () => {
    const { timetableIsActive } = this.state;
    return !this.hasUnpaidDirectDebits() && this.isDeactivated() && timetableIsActive
  }

  bandAndSongInfo = () => {
    const { kidAttributes } = this.props;
    const { band, band_song, band_song_id } = kidAttributes;
    const bandName = (band.data !== null && band.data.attributes.name !== null) ? band.data.attributes.name : 'Not Set';

    if (this.isEnrolled()) {
      return [
        <Table.Row key="key-table-row-band-name">
          <Table.Cell>
            <strong>Band name</strong>
          </Table.Cell>
          <Table.Cell>{bandName}</Table.Cell>
        </Table.Row>,
        <Table.Row key="key-table-row-band-song">
          <Table.Cell>
            <strong>Song</strong>
          </Table.Cell>
          <Table.Cell>
            {band_song ? (
              <div>
                {`${band_song} `}
                <Link to={paths.SONG(band_song_id)}>view song</Link>
              </div>
            ) : (
              'Not Set'
            )}
          </Table.Cell>
        </Table.Row>
      ];
    }
    return null;
  };

  buttons = ({ id, kidAttributes }) => (
    <div className="kid-button-group">
      {this.isActive() ? (
        <Link to={paths.KID(id)} className="ui primary button">
          Make Changes
        </Link>
      ) : null}

      {this.isEnrolled() ? (
        <Link to={paths.TIMETABLE(id)} className="ui button green">
          View Timetable
        </Link>
      ) : null}

      {(this.showEnrolButton()) ? (
        <Link to={{ pathname: paths.ENROL, state: { kid: { id , attributes: kidAttributes } } }} className="ui button green">
          Enrol
        </Link>
      ) : null}
    </div>
  );

  isEnrolled = () => {
    const { kidAttributes } = this.props;
    const { status } = kidAttributes;
    return status.indexOf('enrolled') > -1;
  };

  isActive = () => {
    if (this.isEnrolled()) {
      return true;
    }
    const { kidAttributes } = this.props;
    const { status } = kidAttributes;
    return status === 'waiting_list';
  };

  isDeactivated = () => {
    if (this.isActive()) { return false };

    const { kidAttributes } = this.props;
    const { status } = kidAttributes;

    return !['reserved', 'reserved_during_enrolment'].includes(status);
  }

  hasUnpaidDirectDebits = () => {
    const { kidAttributes } = this.props;
    const { reason_for_cancelling } = kidAttributes;

    return reason_for_cancelling === 'unpaid_direct_debits'
  }

  render() {
    const { id, kidAttributes } = this.props;

    const {
      would_do_drums: wouldDoDrums,
      would_do_bass: wouldDoBass,
      would_do_guitar: wouldDoGuitar,
      would_do_keyboard: wouldDoKeyboard,
      would_do_vocals: wouldDoVocals
    } = kidAttributes;
    return (
      <div>
        <Segment>
          <h4>
            <Image src={personAvatar} />
            {kidAttributes.name}
            {' '}
            {this.buttons({ id, kidAttributes })}
          </h4>

          <Grid relaxed stackable padded>
            <Grid.Row>
              <Grid.Column width={8}>{this.summaryTable()}</Grid.Column>
              <Grid.Column width={8}>
                <div className="instrument-preferences">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <strong>Instrument Preferences</strong>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <p>
                          <strong>First choice</strong>
                          <br />
                          {InstrumentUtils.humanize(
                            kidAttributes.instrument_first_choice
                          )}
                        </p>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <p>
                          <strong>Second choice</strong>
                          <br />
                          {InstrumentUtils.humanize(
                            kidAttributes.instrument_second_choice
                          )}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <br />
                  <strong>Would play?</strong>
                  <Grid padded stackable>
                    <WouldDoOptions
                      disabled
                      onChangingWouldDo={() => {}}
                      wouldDoOptions={{
                        wouldDoDrums,
                        wouldDoBass,
                        wouldDoGuitar,
                        wouldDoKeyboard,
                        wouldDoVocals
                      }}
                    />
                    {}
                  </Grid>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <br />
      </div>
    );
  }
}

export default KidView;
