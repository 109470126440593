import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import StringUtils from '../../utils/StringUtils';
import VocalsIcon from '../../vocals.svg';
import KeyboardIcon from '../../keyboard.svg';
import GuitarIcon from '../../guitar.svg';
import DrumsIcon from '../../drums.svg';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';

const BASS_MESSAGE = 'When you sign up for a guitar space, that could be an electric guitar or a bass guitar depending on availability. The electric guitar and the bass guitar both play an important role in the band, and the teacher will demonstrate this in the first lesson.';

export default function Instrument({ instrumentName, showAvailability, additionalText }) {
  const {
    timetable: { availablePlacesCountsFor },
  } = useKidRegistration();

  const isGuitar = useMemo(() => instrumentName === 'guitar', []);

  const INSTRUMENT_ICONS = Object.freeze({
    vocals: VocalsIcon,
    keyboard: KeyboardIcon,
    guitar: GuitarIcon,
    drums: DrumsIcon,
  });

  const availabilityStatement = useCallback(() => {
    let textColor = 'red';
    let statement = 'Full';
    if (availablePlacesCountsFor(instrumentName) === 1) {
      statement = '1 place left';
    } else if (availablePlacesCountsFor(instrumentName) > 1) {
      textColor = 'green';
      statement = (
        <span>
          <small>
            <Icon name="check" />
          </small>
          Available
        </span>
      );
    }
    return <div className={`${textColor}-text`}>{statement}</div>;
  }, [availablePlacesCountsFor(instrumentName)]);

  return (
    <div className='flex-row-aligned-center'>
      <img
        src={INSTRUMENT_ICONS[instrumentName]}
        alt={`${instrumentName} icon`}
        width={45}
        height={45}
      />
      <div>
        <strong>
          {StringUtils.humanize(instrumentName)}
          {isGuitar && (
            <span>
              {' '}
              <Popup
                hoverable
                position="bottom center"
                content={BASS_MESSAGE}
                trigger={<Icon color="black" name="info circle" />}
              />
            </span>
          )}
        </strong>
        {showAvailability ? availabilityStatement() : additionalText && <p>{additionalText}</p>}
      </div>
    </div>
  );
};

Instrument.propTypes = {
  instrumentName: PropTypes.string.isRequired,
  showAvailability: PropTypes.bool,
  additionalText: PropTypes.string,
};

Instrument.defaultProps = {
  showAvailability: true,
  additionalText: null,
};
