import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import WouldDoOptions from './WouldDoOptions';

class InstrumentPreferences extends Component {
  static propTypes = {
    onChangingFirstPreference: PropTypes.func.isRequired,
    onChangingSecondPreference: PropTypes.func.isRequired,
    onChangingWouldDo: PropTypes.func.isRequired,
    firstPreference: PropTypes.string.isRequired,
    secondPreference: PropTypes.string.isRequired,
    wouldDoOptions: PropTypes.object.isRequired,
  };

  selectableInstruments = otherPreference => [
    { value: 'no_preference', text: 'No Preference' },
    { value: 'drums', text: 'Drums', disabled: (otherPreference === 'drums') },
    { value: 'bass', text: 'Guitar (bass)', disabled: (['bass', 'guitar_any'].includes(otherPreference)) },
    { value: 'guitar', text: 'Guitar (electric)', disabled: (['guitar', 'guitar_any'].includes(otherPreference)) },
    { value: 'guitar_any', text: 'Guitar (any)', disabled: (['bass', 'guitar', 'guitar_any'].includes(otherPreference)) },
    { value: 'keyboard', text: 'Keyboard', disabled: (otherPreference === 'keyboard') },
    { value: 'vocals', text: 'Vocals', disabled: (otherPreference === 'vocals') },
  ];

  instrumentPreferenceDropdown = ({ value, otherPreference, onChange, label } = {}) => (
    <Form.Field>
      <Form.Dropdown
        selection
        options={this.selectableInstruments(otherPreference)}
        value={value}
        onChange={onChange}
        label={label}
      />
    </Form.Field>
  );

  instrumentPreferenceDropdowns = () => {
    const {
      onChangingFirstPreference,
      onChangingSecondPreference,
      firstPreference,
      secondPreference,
    } = this.props;
    return (
      <Grid.Row columns={2}>
        <Grid.Column className="pref_inst_width">
          {this.instrumentPreferenceDropdown({
            value: firstPreference,
            otherPreference: secondPreference,
            onChange: onChangingFirstPreference,
            label: 'Preferred Instrument (first choice)',
          })}
        </Grid.Column>
        <Grid.Column className="pref_inst_width">
          {this.instrumentPreferenceDropdown({
            value: secondPreference,
            otherPreference: firstPreference,
            onChange: onChangingSecondPreference,
            label: 'Preferred Instrument (second choice)',
          })}
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    const { wouldDoOptions, onChangingWouldDo } = this.props;
    return (
      <Grid padded stackable>
        {this.instrumentPreferenceDropdowns()}
        <strong>Would Play?</strong>
        <WouldDoOptions
          wouldDoOptions={wouldDoOptions}
          onChangingWouldDo={onChangingWouldDo}
        />
      </Grid>
    );
  }
}

export default InstrumentPreferences;
