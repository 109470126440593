import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

const SecondaryHeading = ({ heading }) => (
  <Header as="h2" className="secondary-heading">
    <Header.Content>
      {heading}
    </Header.Content>
  </Header>
);

SecondaryHeading.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default SecondaryHeading;
