import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class PublicAPI {

  validateBankDetails = (config) => AuthenticatedHTTPWrapper.post({
    url: `${
      process.env.REACT_APP_ABBEY_ROAD_URL
    }/api/smart_debits/validate.json`,
    config,
  })

  createDdFromBankDetails = (config) => AuthenticatedHTTPWrapper.post({
    url: `${
      process.env.REACT_APP_ABBEY_ROAD_URL
    }/api/smart_debits.json`,
    config,
  })
};

export default new PublicAPI();
