import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

const PageHeading = ({ heading, subHeading }) => (
  <Header as="h1" className="page-heading">
    <Header.Content>
      {heading}
    </Header.Content>
    {subHeading ? <Header.Subheader>{subHeading}</Header.Subheader> : ''}
  </Header>
);

PageHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PageHeading.defaultProps = {
  subHeading: null,
};

export default PageHeading;
