/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useLocation, Link, Redirect } from 'react-router-dom';
import { Button, Grid, Icon, Image } from 'semantic-ui-react';
import paths from '../../consts/paths';
import RequireKidsAppPromoLive from '../RequireKidsAppPromoLive/RequireKidsAppPromoLive';
import rocksteadyPlayPromo from '../../images/portal/rsplay_feature.png';

export default function KidsAppPromo() {
  const { state } = useLocation();

  if (!state?.eligibleKids?.length) return <Redirect to={paths.ROOT} />;

  const eligibleFreeKids = state?.eligibleKids?.filter(
    ({ attributes }) => attributes.kids_app_timetable_price_pennies === 0
  );

  const eligiblePaidKids = state?.eligibleKids?.filter(
    ({ attributes }) => attributes.kids_app_timetable_price_pennies > 0
  );

  const subscribeButton = () => {
    return (
      <Link
        to={{
          pathname: paths.KIDS_APP_SUBSCRIBE,
          state: { eligibleKids: eligiblePaidKids }
        }}
        className="ui primary button fluid"
      >
        Manage your <em>Rocksteady Play</em> App Subscription
      </Link>
    );
  };

  const listEligibleFreeKids = () => {
    if (!eligibleFreeKids.length) {
      return null;
    }

    return (
      <div>
        <h5>
          Your children who have FREE Access to the <em>Rocksteady Play</em>{' '}
          App, during our Pilot:
        </h5>
        <ul>
          {eligibleFreeKids.map(({ id, attributes }) => (
            <li key={id}>{attributes.name}</li>
          ))}
        </ul>
      </div>
    );
  };

  const listEligiblePaidKids = () => {
    if (!eligiblePaidKids.length) {
      return null;
    }

    return (
      <div>
        <h5>
          Your children below, can use our Rocksteady Play App, if you subsribe:
        </h5>
        <ul>
          {eligiblePaidKids.map(({ id, attributes }) => (
            <li key={id}>{attributes.name}</li>
          ))}
        </ul>

        {subscribeButton()}
      </div>
    );
  };

  return (
    <RequireKidsAppPromoLive>
      <Image src={rocksteadyPlayPromo} fluid />
      <h3>Take your band home with you!</h3>

      <Grid reversed="computer tablet" stackable>
        <Grid.Column width={6}>
          <h4>
            Download <em>Rocksteady Play</em> Today:
          </h4>
          <p>
            <Button
              icon
              labelPosition="left"
              href={paths.KIDS_APP_IOS}
              target="_blank"
              rel="noopener noreferrer"
              fluid
              color="green"
            >
              <Icon name="apple" />
              Apple App Store
            </Button>
            <br />
            <Button
              icon
              labelPosition="left"
              href={paths.KIDS_APP_ANDROID}
              target="_blank"
              rel="noopener noreferrer"
              fluid
              color="green"
            >
              <Icon name="android" />
              Google Play
            </Button>
            <br />
            <Button
              icon
              labelPosition="left"
              href={paths.KIDS_APP_FIRE}
              target="_blank"
              rel="noopener noreferrer"
              fluid
              color="green"
            >
              <Icon name="amazon" />
              Amazon Kindle Fire
            </Button>
          </p>
          {listEligibleFreeKids()}
          {listEligiblePaidKids()}
        </Grid.Column>
        <Grid.Column width={10}>
          <p>
            Wouldn’t it be cool if your child could enter their very own
            Rocksteady world, and practice with their virtual band outside of
            weekly band lessons?
          </p>

          <p>
            <strong>Now they can!</strong>
          </p>

          <p>
            <em>Rocksteady Play </em>
            allows your child to extend the benefits of their in-person
            Rocksteady lessons and enhance their learning between weekly band
            lessons. In the app your child can listen to the exact version of
            the song they’re currently learning, at their level and, if they
            have an instrument, they can even play along.
          </p>

          <ul>
            <li>
              <strong>Extend the fun</strong>
              <br />
              Take the fun, creativity and celebration of Rocksteady home and
              even build a personalised avatar!
            </li>

            <li>
              <strong>Continue to progress </strong>
              <br />
              Whether your child has an instrument at home or not, listening to
              their exact arrangement in between lessons will build audible
              memory of the song and support progress when they are back in
              their lesson.
            </li>
          </ul>

          <p>
            Your child will be able to listen to the exact version of the song
            they’re currently learning in lessons and toggle between four
            different modes:
          </p>
          <ul>
            <li>
              Listen to the whole song being performed by a band of virtual Band
              Leaders (pro-mode)
            </li>
            <li>
              Listen to your version of the song customised to your own band
              (demo-mode)
            </li>
            <li>
              Listen to your part of the song customised to your level and
              instrument (solo-mode)
            </li>
            <li>
              If you have an instrument at home, join in with your band (play
              along-mode)
            </li>
          </ul>

          <p>
            The app is available for Android, iOS, and Amazon Kindle Fire. Click
            on the relevant link below to download <em>Rocksteady Play</em> and
            sign in with your usual Backstage username and password.
          </p>

          <ul>
            <li>
              <a
                href={paths.KIDS_APP_IOS}
                target="_blank"
                rel="noopener noreferrer"
              >
                Apple App Store
              </a>
            </li>
            <li>
              <a
                href={paths.KIDS_APP_ANDROID}
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Play
              </a>
            </li>
            <li>
              <a
                href={paths.KIDS_APP_FIRE}
                target="_blank"
                rel="noopener noreferrer"
              >
                Amazon Kindle Fire
              </a>
            </li>
          </ul>

          <h4>Tell us what you think!</h4>

          <p>
            We want to work with you to make sure we develop an app that is fun,
            entertaining, engaging and supports your child’s ongoing learning in
            between weekly band lessons. We think we have made a good start, but
            please let us know what you like and don’t like about the app so we
            can make it even better for your child.
          </p>

          <p>
            Look out for our survey link in the coming weeks and sign up for one
            of our online grownup focus groups.
          </p>
        </Grid.Column>
      </Grid>
    </RequireKidsAppPromoLive>
  );
}
