import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loader, Dimmer, Form, Button, Message } from 'semantic-ui-react';
import StringUtils from '../../utils/StringUtils';
import WindowUtils from '../../utils/WindowUtils';
import UserAPI from '../../api/UserAPI';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import paths from '../../consts/paths';

const UnlockAccount = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [accountUnlocked, setAccountUnlocked] = useState(false);
  const [email, setEmail] = useState('');
  const [instructionsSent, setInstructionsSent] = useState(false);

  useEffect(() => {
    const params = StringUtils.parseQueryString(WindowUtils.anyParams());
    setLoading(true);
    UserAPI.unlockAccount(params.token).then(() => {
      setAccountUnlocked(true);
    })
    .catch((err) => {
      setErrors(err.response.data.errors)
    })
    .finally(() => setLoading(false));
  }, []);

  const errorMessage = () => {
    if (errors) {
      return <ErrorMessage errors={errors} />
    }
    return null;
  }

  const handleFormSubmit = () => {
    setErrors(null);
    setLoading(true);
    const params = { data: { user: { email } } };
    UserAPI.resendUnlockInstructions(params).then(() => {
      setInstructionsSent(true)
    })
    .catch(() => {
      setErrors(['Unable to complete request'])
    })
    .finally(() => setLoading(false));
  }

  const handleEmailChange = (e, { value }) => setEmail(value);

  const requestNewInstructionsContent = () => (
    <div>
      {errorMessage()}
      <p>Please enter your email address and we will resend instructions to unlock your account.</p>
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <Form.Input value={email} onChange={handleEmailChange} />
          <Button type="submit" positive>Resend Instructions</Button>
        </Form.Group>
      </Form>
    </div>
  )

  const accountUnlockedContent = () => (
    <div style={{ marginBottom: 20 }}>
      <p>Your account was successfully unlocked.</p>
      <Link to={paths.SIGN_IN} className="ui button positive">
        Sign In
      </Link>
    </div>
  )

  const loadingContent = () => (
    <Dimmer inverted active>
      <Loader />
    </Dimmer>
  )

  const instructionsSentContent = () => (
    <Message positive visible>
      <Message.Content>
        <p>
          If your email address exists, you will receive an email shortly with instructions to
          unlock your account.
        </p>
      </Message.Content>
    </Message>
  )

  const pageContent = () => {
    if (loading) {
      return loadingContent();
    }
    if (errors) {
      return requestNewInstructionsContent();
    }
    if (accountUnlocked) {
      return accountUnlockedContent();
    }
    if (instructionsSent) {
      return instructionsSentContent();
    }
    return null;
  }

  return (
    <div style={{ marginTop: 50 }}>
      <h1>Unlock Account</h1>
      <div>{pageContent()}</div>
    </div>
  )
}

export default UnlockAccount;
