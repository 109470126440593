import { Cookies } from 'react-cookie';

class JWTManager {
  static cookies = () => new Cookies();

  removeToken = () => JWTManager.cookies().remove('rs_portal_jwt', { path: "/" });

  getToken = () => JWTManager.cookies().get('rs_portal_jwt');

  setToken = token => new Promise(() => {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expires = new Date(0);
    expires.setUTCSeconds(payload.exp)
    JWTManager.cookies().set('rs_portal_jwt', token, { path: '/', expires });
  });

  removeSchoolsToken = () => {
    JWTManager.cookies().remove('rs_schools_portal_jwt', { path: "/" });
  }

  getSchoolsToken = () => JWTManager.cookies().get('rs_schools_portal_jwt');

  setSchoolsToken = token => new Promise(() => {
    JWTManager.cookies().set('rs_schools_portal_jwt', token, { path: '/' });
  });
}

export default new JWTManager();
