import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table, Icon, Button, Grid, Loader, Dimmer, Message, Popup, Dropdown,
} from 'semantic-ui-react';
import MediaQuery from 'react-responsive';
import KidAPI from '../../api/KidAPI';
import SchoolAPI from '../../api/SchoolAPI';
import WindowUtils from '../../utils/WindowUtils';
import TimetableUtils from '../../utils/TimetableUtils';
import PageHeading from '../PageHeading/PageHeading';
import BugsnagClient from '../../utils/BugsnagClient';
import TrinityInstrumentChangeWarning from '../TrinityInstrumentChangeWarning/TrinityInstrumentChangeWarning';
import InstrumentChangeModal from './InstrumentChangeModal/InstrumentChangeModal'
import contacts from '../../consts/contacts';

class AvailableInstrumentChanges extends Component {
  static propTypes = {
    kid: PropTypes.object.isRequired,
    slots: PropTypes.array.isRequired,
    refreshAllTheThings: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const shouldDisplayTrinityWarning = this.kidIsTrinityEnrolled();

    this.state = {
      timetables: [],
      currentTimetableId: null,
      loading: true,
      error: false,
      placeTaken: false,
      changingInstrument: null,
      changingBandId: null,
      shouldDisplayTrinityWarning,
    };
  }

  componentDidMount() {
    const { kid } = this.props;
    this.fetchInstrumentChangeData();
    this.setState({ currentTimetableId: kid.attributes.timetable_id });
    WindowUtils.scrollToTop();
  }

  instruments = () => ({
    drums: 'Drums',
    bass: 'Guitar (bass)',
    guitar: 'Guitar (electric)',
    keyboard: 'Keyboard',
    vocals: 'Vocals',
  });

  fetchInstrumentChangeData = async () => {
    const { kid } = this.props;
    const timetablesFromAPI = await SchoolAPI.getInstrumentChangeData(
      { urn: kid.attributes.school_urn, year: kid.attributes.year }
    );
    const timetables = timetablesFromAPI.data.data;

    this.setState({
      timetables,
      loading: false,
    });
  };

  currentBandId = () => {
    // The active slot method has been known to return undefined
    // which should not be possible for an enrolled kid. The code here
    // exists to send some data to bugsnag so we can investigate the cause.
    try {
      const { id: bandId } = this.activeSlot().relationships.band.data;
      return bandId;
    } catch(err) {
      const { slots, kid } = this.props;
      BugsnagClient.notify(err, {
        metaData: {
          props: {
            slots,
            kidId: kid.id
          }
        }
      });
      return null;
    }
  };

  activeSlot = () => {
    const { slots } = this.props;
    return slots.find(slot => !slot.attributes.is_delayed_instrument_change);
  };

  noPlacesMessage = () => (
    !this.currentTimetableHasBands() && (
      <Message positive>
        <Message.Header>
          There are no available spaces at the moment.
        </Message.Header>
        <Message.Content>
          Please check your instrument preferences are up to
          {' '}
          date and we will keep looking for opportunities to change
        </Message.Content>
      </Message>
    )
  );

  errorMessage = () => {
    const { error } = this.state;
    return error && (
      <Message negative>
        <p>Sorry but we could not complete your request due to an error.</p>
        <p>
          {'You may wish to try again, or if the problem persists you can call us on '}
          {`${contacts.SUPPORT_TEL_HUMANIZED} (${contacts.SUPPORT_HOURS}), `}
          {'or email '}
          <a href={`mailto:${contacts.SUPPORT_EMAIL}`}>{contacts.SUPPORT_EMAIL}</a>
          .
        </p>
      </Message>
    );
  };

  placeTakenMessage = () => {
    const { placeTaken } = this.state;
    return placeTaken && (
      <Message>
        Sorry but that space is no longer available.
        {' '}
        We have updated the table to reflect latest availability
      </Message>
    );
  };

  successfulMessage = () => {
    const { kid } = this.props;
    const { successful } = this.state;
    return successful && (
      <Message positive>
        <Message.Header>
          {kid.attributes.name}
          {' '}
          has moved instruments and/or bands
        </Message.Header>
        <Message.Content>
          <br />
          Please note:
          <ul style={{ margin: '0' }}>
            <li>
              If your child&lsquo;s next lesson is in the next 24 hours,
              {' '}
              the school may not be aware of this change until the following week.
            </li>
            <li>
              If your child has an upcoming concert in the next few weeks,
              {' '}
              your Band Leader will wait until after the concert to switch
              {' '}
              instruments unless you have a strong preference for changing immediately.
            </li>
          </ul>
        </Message.Content>
      </Message>
    );
  };

  timetableOptions = () => {
    const { timetables } = this.state;

    return timetables.map((timetable, key) => {
      return {
        key,
        text: timetable.attributes.display_day_time_slot_long,
        value: Number(timetable.id),
      }
    });
  };

  timetableSelect = () => {
    const { currentTimetableId } = this.state;
    return (
      <Grid stackable verticalAlign="middle">
        <Grid.Column floated="left" width={8}>
          <b>Lesson day: </b>
          <div className="instrument-change-timetable-select">
            <Dropdown
              fluid
              selection
              defaultValue={currentTimetableId}
              options={this.timetableOptions()}
              onChange={this.onChangeSelectedTimetable}
            />
          </div>
        </Grid.Column>
        <Grid.Column textAlign="right" width={8} only="tablet computer">
          <b>Band Leader: </b>
          <span>{this.currentTimetable().band_leader_name}</span>
        </Grid.Column>
        <Grid.Column floated="right" width={8} only="mobile">
          <b>Band Leader: </b>
          <span>{this.currentTimetable().band_leader_name}</span>
        </Grid.Column>
      </Grid>
    )
  }

  onChangeSelectedTimetable = (e, { value }) => {
    this.setState({ currentTimetableId: value });
  };

  kidIsTrinityEnrolled = () => {
    const { kid } = this.props;
    return !!kid.attributes.active_trinity_enrolment.data
  }

  getActiveTrinityEnrolmentExamName = () => {
    const { kid } = this.props;
    return kid.attributes.active_trinity_enrolment.data.attributes.exam_name;
  }

  getBandLeaderName = () => {
    const { kid } = this.props;
    return kid.attributes.timetable_bl.data.attributes.band_leader_name;
  }

  dismissTrinityWarning = () => this.setState({ shouldDisplayTrinityWarning: false })

  trinityWarnMessage = () => {
    return (
      <TrinityInstrumentChangeWarning
        examName={this.getActiveTrinityEnrolmentExamName()}
        bandLeaderName={this.getBandLeaderName()}
        onDismiss={this.dismissTrinityWarning}
      />
    );
  }

  changingFinished = (outcome) => {
    this.setState({
      [outcome]: true,
      changingBandId: null,
      changingInstrument: null,
    });
    WindowUtils.scrollToTop();
    this.fetchInstrumentChangeData();
    if (outcome === 'successful') {
      const {refreshAllTheThings} = this.props;
      refreshAllTheThings();
    }
  }

  changeInstrument = (instrument, bandId) => () => {
    this.setState(
      {
        loading: true,
        successful: false,
        placeTaken: false,
      },
      async () => {
        const { kid } = this.props;
        const config = {
          data: {
            band_id: bandId,
            instrument,
          },
        };
        try {
          await KidAPI.assignKidToBand(kid.id, config);
          this.changingFinished('successful');
        } catch (err) {
          if (err.response.data.errors.match(/no places/)) {
            this.changingFinished('placeTaken');
          } else {
            this.changingFinished('error');
          }
        }
      },
    );
  };

  yearRange = (from, to) => `Year ${TimetableUtils.formattedYear(from)} - ${TimetableUtils.formattedYear(to)}`;

  setInstrumentChange = (instrument, id) => () => {
    this.setState({
      changingInstrument: instrument,
      changingBandId: id,
    });
  };

  cancelInstrumentChange = () => {
    this.setState({
      changingInstrument: null,
      changingBandId: null,
    });
  };

  fullMessage = instrument => (
    <React.Fragment>
      <MediaQuery minWidth={769}>
        <span>Full</span>
      </MediaQuery>
      <MediaQuery minWidth={320} maxWidth={768}>
        <span>
          {this.instruments()[instrument]}
          {' '}
          is Full
        </span>
      </MediaQuery>
    </React.Fragment>
  )

  buttonContent = instrument => (
    <React.Fragment>
      <MediaQuery minWidth={769}>
        <span>Move Here</span>
      </MediaQuery>
      <MediaQuery minWidth={320} maxWidth={768}>
        <span>
          Move to
          {' '}
          {this.instruments()[instrument]}
        </span>
      </MediaQuery>
    </React.Fragment>
  )

  confirmInstrumentChange = () => {
    const { kid } = this.props;
    const currentBand = kid.attributes.band.data.attributes;
    const { changingInstrument, changingBandId, currentTimetableId } = this.state;

    return (
      <InstrumentChangeModal
        isOpen={changingInstrument !== null}
        kid={kid}
        oldTimetable={this.timetableData(kid.attributes.timetable_id)}
        newTimetable={this.timetableData(currentTimetableId)}
        oldBand={currentBand}
        newBand={changingBandId ? this.bandData(changingBandId) : currentBand}
        newInstrument={changingInstrument}
        onConfirm={this.changeInstrument(changingInstrument, changingBandId)}
        onCancel={this.cancelInstrumentChange}
      />
    )
  };

  instrumentAvailabilityCell = (instrument, available, bandId) => {
    const { kid } = this.props;
    const inThisBand = bandId === this.currentBandId();
    if (instrument === kid.attributes.assigned_instrument_type && inThisBand) {
      return (
        <div>
          <Icon name="child" color="grey" size="large" />
          <br />
          {kid.attributes.name}
        </div>
      );
    }
    if (available) {
      return (
        <Button fluid positive onClick={this.setInstrumentChange(instrument, bandId)}>
          {this.buttonContent(instrument)}
        </Button>
      );
    }
    return this.fullMessage(instrument);
  };

  timetableData = (id) => {
    const { timetables } = this.state;
    const timetable = timetables.find((tt => Number(tt.id) === id));
    return (timetable ? timetable.attributes : {});
  }

  currentTimetable = () => {
    const { currentTimetableId } = this.state;
    return this.timetableData(currentTimetableId);
  }

  bandData = (id) => (
    this.currentTimetable().bands.data.find((band) => Number(band.id) === Number(id)).attributes || {}
  )

  currentTimetableHasBands = () => {
    if (!this.currentTimetable().bands) return false
    return this.currentTimetable().bands.data.length > 0;
  }

  rows = () => {
    return (
      <Table.Body>
        {this.currentTimetable().bands.data.map(band => this.bandRow(band))}
      </Table.Body>
    );
  };

  outOfHoursInfo = outOfHours => outOfHours && (
    <Popup trigger={<Button style={{ display: 'block', margin: '0 auto' }} compact size="tiny" basic>Out of Hours</Button>} on="click">
      <Popup.Content>
        The rehearsals for this band do not take place during school hours
      </Popup.Content>
    </Popup>
  )

  instrumentHeader = (key, label) => (
    <Table.HeaderCell key={key}>{label}</Table.HeaderCell>
  );

  tableHeader = () => (
    <MediaQuery minWidth={769}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          {Object.entries(this.instruments()).map(([key, label]) => this.instrumentHeader(key, label))}
        </Table.Row>
      </Table.Header>
    </MediaQuery>
  )

  bandRow = (band) => {
    const { attributes, id } = band;
    const {
      name: bandName, instrument_availability, out_of_hours, year_from, year_to,
    } = attributes;
    return (
      <Table.Row key={id}>
        <Table.Cell verticalAlign="middle">
          <strong>Band Name:</strong>
          {' '}
          {bandName || '-'}
          <br />
          {this.yearRange(year_from, year_to)}
          {this.outOfHoursInfo(out_of_hours)}
        </Table.Cell>
        {Object.keys(this.instruments()).map(instrument => (
          <Table.Cell key={`${id}-${instrument}`}>
            {
              this.instrumentAvailabilityCell(
                instrument, instrument_availability[instrument], id,
              )
            }
          </Table.Cell>
        ))}
      </Table.Row>
    );
  };

  render() {
    const { kid } = this.props;
    const { loading, shouldDisplayTrinityWarning } = this.state;

    if (loading) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }
    if (!loading && !this.currentTimetableHasBands()) {
      return this.noPlacesMessage();
    }
    return (
      <div className="page-container">
        <PageHeading
          subHeading="If there is a space available you can change instruments or bands here"
          heading={`Change Instrument for ${kid.attributes.name}`}
        />
        {this.errorMessage()}
        {this.placeTakenMessage()}
        {this.successfulMessage()}
        {this.confirmInstrumentChange()}
        {this.timetableSelect()}
        {shouldDisplayTrinityWarning && this.trinityWarnMessage()}
        <Table definition celled striped textAlign="center">
          {this.tableHeader()}
          {this.rows()}
        </Table>
      </div>
    );
  }
}

export default AvailableInstrumentChanges;
