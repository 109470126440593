import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const TrinityInstrumentChangeWarning = ({ bandLeaderName, examName, onDismiss }) => (
  <Message warning onDismiss={onDismiss}>
    <Message.Header>Important Information</Message.Header>
    <Message.Content>
      {`Your child is working towards their "${examName}" qualification. Before you change instruments, please speak with your Band Leader, ${bandLeaderName}, to make sure this does not affect their ability to achieve their qualification.`}
    </Message.Content>
  </Message>
);

TrinityInstrumentChangeWarning.propTypes = {
  examName: PropTypes.string.isRequired,
  bandLeaderName: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default TrinityInstrumentChangeWarning;
