import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import WindowUtils from '../../utils/WindowUtils';

class CancellationForm extends Component {
  static propTypes = {
    kid: PropTypes.object.isRequired,
    grownUpId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    grownUpName: PropTypes.string.isRequired,
    refreshAllTheThings: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };
  }

  componentDidMount() {
    WindowUtils.scrollToTop();
    const { kid } = this.props;
    const get_step = localStorage.getItem(`step kid ${kid.id}`);
    if (get_step) {
      this.setState({ step: parseInt(get_step, 10) });
    } else {
      this.setState({ step: 1 });
    }
  }

  nextStep = (currentStep = 1) => {
    this.setState({
      step: currentStep + 1,
    });
  };

  checkStep = () => {
    const { step } = this.state;
    const { kid, grownUpId, grownUpName, refreshAllTheThings } = this.props;

    switch (step) {
      case 1:
        return <Step1 nextStep={this.nextStep} id={kid.id} name={kid.attributes.name} kid={kid} />;
      case 2:
        return (
          <Step2
            nextStep={this.nextStep}
            id={kid.id}
            name={kid.attributes.name}
            grownUpId={grownUpId}
            grownUpName={grownUpName}
          />
        );
      case 3:
        return (
          <Step3
            id={kid.id}
            name={kid.attributes.name}
            kid={kid}
            refreshAllTheThings={refreshAllTheThings}
          />
        );
      default:
        return null;
    }
  };

  render() {
    return this.checkStep();
  }
}

export default CancellationForm;
