import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class BandAPI {
  fetchBand = id => AuthenticatedHTTPWrapper.get({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/bands/${id}.json`,
  });

  fetchBandLeaderProfile = id => AuthenticatedHTTPWrapper.get({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/band_leaders/${id}/profile.json`,
  });
}

export default new BandAPI();
