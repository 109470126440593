import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid } from 'semantic-ui-react';
import BandAPI from '../../api/BandAPI';
import WindowUtils from '../../utils/WindowUtils';

class BandLeaderProfile extends Component {
  static propTypes = {
    bandLeaderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      loading: true,
    };
  }

  componentDidMount() {
    const { bandLeaderId } = this.props;
    BandAPI.fetchBandLeaderProfile(bandLeaderId).then((response) => {
      this.setState({ loading: false, profile: response.data.data.attributes });
    });
    WindowUtils.scrollToTop();
  }

  imagePath = () => {
    const { profile } = this.state;
    if (profile.photo) {
      return `${process.env.REACT_APP_ABBEY_ROAD_URL}/${profile.photo}`;
    }

    return null;
  };

  quoteSection = profileQuote => {
    if (profileQuote) {
      return (


        <div className="quoteWrapper">
          <h1 className="quoteMark">&ldquo;</h1>
          <div className="quoteInner">
            <blockquote className="quoteBody">{profileQuote}</blockquote>
          </div>
        </div>
      )
    }
    return null;
  }

  aboutSectionB = profile => {
    if (profile.why) {
      return (
        <Grid.Row>
          <Grid.Column width={8}>
            <h4>Why I am a part of Rocksteady</h4>
            <p>{profile.why}</p>
          </Grid.Column>
          <Grid.Column width={8}>
            <h4>My favourite Rocksteady moment so far</h4>
            <p>{profile.favourite}</p>
          </Grid.Column>
        </Grid.Row>
      )
    }

    return null;
  }

  aboutSectionA = profile => {
    if (profile.influences) {
      return (
        <Grid.Row>
          <Grid.Column width={8}>
            <h4>My musical influences</h4>
            <p>{profile.influences}</p>
          </Grid.Column>
          <Grid.Column width={8}>
            <h4>How music has affected my life</h4>
            <p>{profile.how}</p>
          </Grid.Column>
        </Grid.Row>
      )
    }

    return null;
  }

  profile = () => {
    const { profile } = this.state;
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={8}>
            <center>
              <img width="200px" alt="" src={this.imagePath()} />
              <h3>{profile.name}</h3>
              <a href={`mailto:${profile.email_address}?bcc=bandleadercomms@rocksteadymusicschool.com`}>{profile.email_address}</a>
            </center>
          </Grid.Column>
          <Grid.Column width={8}>
            {this.quoteSection(profile.quote)}
          </Grid.Column>
        </Grid.Row>
        {this.aboutSectionA(profile)}
        {this.aboutSectionB(profile)}
      </Grid>
    );
  };

  render() {
    const { loading } = this.state;
    return (
      <div className="page-container">
        <Segment>{loading ? '' : this.profile()}</Segment>
      </div>
    )    
  }
}

export default BandLeaderProfile;
