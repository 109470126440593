import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Header, TextArea, Checkbox, Input, Radio,
} from 'semantic-ui-react';
import StepHeader from './StepHeader';
import NpsAPI from '../../api/NpsAPI';
import BenefitSurveyAPI from '../../api/BenefitSurveyAPI';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

class Step2 extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    grownUpId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    nextStep: PropTypes.func.isRequired,
    grownUpName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      npsScore: 0,
      errors: [],
    };
  }

  benefitSurvey = () => {
    const { grownUpName } = this.props;
    const {
      more_confident,
      learnt_new_musical_skills,
      made_new_friends,
      happier_at_school,
      academic_performance_improved,
      other_benefit,
      other_benefit_description,
    } = this.state;

    return {
      data: {
        more_confident,
        learnt_new_musical_skills,
        made_new_friends,
        happier_at_school,
        academic_performance_improved,
        other_benefit,
        other_benefit_description,
        logged_by: grownUpName,
      },
    };
  };

  nps = () => {
    const { grownUpName } = this.props;
    const { npsScore, why } = this.state;
    return {
      data: {
        score: npsScore,
        comment: why,
        logged_by: grownUpName,
        submitted_from: "cancellation_form"
      },
    };
  };

  saveAndContinue = () => {
    const { id, grownUpId, nextStep } = this.props;
    this.setState({ errors: [] }, () => {
      NpsAPI.createNPS(grownUpId, this.nps()).then(() => {
        BenefitSurveyAPI.createBenefit(id, this.benefitSurvey()).then(() => {
          nextStep(2);
        });
      })
      .catch((error) => {
        const { message } = error.response.data
        this.setState({ errors: [message] });
      })
    });
  };

  valid = () => {
    const { npsScore } = this.state;
    if (!npsScore) {
      return false;
    }
    return true;
  };

  checkboxes = () => {
    const checkboxes = [
      { label: 'They are more confident', value: 'more_confident' },
      { label: 'They have learnt new musical skills', value: 'learnt_new_musical_skills' },
      { label: 'They have made new friends', value: 'made_new_friends' },
      { label: 'They are happier at school', value: 'happier_at_school' },
      {
        label: 'Their performance in other subjects has improved',
        value: 'academic_performance_improved',
      },
      { label: 'Other (please state)', value: 'other_benefit' },
    ];
    return checkboxes.map(checkbox => (
      <Checkbox
        key={`cb_${checkbox.value}`}
        label={checkbox.label}
        className="cb-w-100"
        value={checkbox.value}
        onChange={this.onChangeCB}
      />
    ));
  };

  radios = () => Array.from({ length: 5 }, (v, k) => {
    const { npsScore } = this.state;
    return (
      <Form.Field key={`radio_${k}`}>
        <Radio
          label={k + 1}
          name="radioGroup"
          value={k + 1}
          checked={npsScore === k + 1}
          onChange={this.handleChange}
          className="mr-radio"
        />
      </Form.Field>
    );
  });

  handleChange = (e, { value }) => this.setState({ npsScore: value });

  onChangeCB = (e, { value, checked }) => {
    this.setState({ [value]: checked || undefined });
  };

  handleMessage = (e) => {
    this.setState({ other_benefit_description: e.target.value });
  };

  render() {
    const { name } = this.props;
    const { why, errors } = this.state;

    return (
      <Form>
        <h1 className="ui centered">
          Cancel&nbsp;
          {name}
        </h1>
        <StepHeader step={2} />
        <ErrorMessage errors={errors} />
        <Header size="medium" className="step2-header">
          How has doing Rocksteady benefited your child?
        </Header>
        <Header size="small">Please tick all that apply:</Header>
        {this.checkboxes()}
        <Input placeholder="Other reason" onChange={this.handleMessage} />
        <Header size="medium">How likely are you to recommend Rocksteady to a friend?</Header>
        <p>On a scale of 1-5, where 1 is very unlikely and 5 is very likely</p>
        <Form.Group>{this.radios()}</Form.Group>
        <Header size="small">Why?</Header>
        <TextArea
          onChange={(e, { value }) => this.setState({ why: value })}
          value={why}
          maxLength="254"
        />
        <br />
        <br />
        <Button onClick={this.saveAndContinue} disabled={!this.valid()} primary type="submit">
          Next Step
        </Button>
      </Form>
    );
  }
}
export default Step2;
