import React from "react";
import PropTypes from 'prop-types';
import { Message } from "semantic-ui-react";

const ErrorMessage = ({ errors }) => {
  if (errors.length > 0) {
    const messages = errors.map((err) => <li key={err}>{err}</li>);
    return (
      <Message visible negative>
        <Message.Content>
          There was a problem with your request:
          <ul>{messages}</ul>
        </Message.Content>
      </Message>
    )
  }
  return null;
}

ErrorMessage.propTypes = {
  errors: PropTypes.array,
}

ErrorMessage.defaultProps = {
  errors: [],
}

export default ErrorMessage;
