import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Grid, Image, Message, Label, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import paths from '../../consts/paths';
import MessagePrompt from '../MessagePrompt';
// import NPSForm from '../Nps/NpsForm';
import KidDetails from '../KidDetails/KidDetails';
import WindowUtils from '../../utils/WindowUtils';
import DateUtils from "../../utils/DateUtils";
import KidMenuItem from '../NavMenu/KidMenuItem';
import KidAPI from '../../api/KidAPI';
import personAvatar from '../../images/portal/person_avatar.svg';
import KidsAppPromoBanner from '../KidsAppPromoBanner/KidsAppPromoBanner';

class Welcome extends Component {

  static propTypes = {
    grownUpAttributes: PropTypes.object.isRequired,
    detailsMissing: PropTypes.bool.isRequired,
    reservedKids: PropTypes.array,
    unapprovedInstrumentChanges: PropTypes.array,
    // showNPSThanksMessage: PropTypes.bool.isRequired,
    // setShowNPSThanksMessage: PropTypes.func.isRequired,
    kids: PropTypes.array.isRequired,
    approvedInstrumentChanges: PropTypes.array
    // grownUpId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  };

  static defaultProps = {
    reservedKids: [],
    approvedInstrumentChanges: [],
    unapprovedInstrumentChanges: []
  };

  constructor(props) {
    super(props);
    this.state = {
      kidsTimetableData: {}
    };
  }

  componentDidMount() {
    WindowUtils.scrollToTop();
    this.getKidsCalendar();
  }

  getKidsCalendar = () => {
    const { kids } = this.props;

    kids.forEach(kid => {
      KidAPI.getRehearsalsAllDayEvents(kid.id).then(response => {
        this.setState(prevState => ({
          kidsTimetableData: {
            ...prevState.kidsTimetableData,
            [kid.id]: response.data
          }
        }));
      });
    });
  };

  reservedKidPrompt = kid => {
    const { id, attributes } = kid;
    const { name } = attributes;
    return (
      <MessagePrompt
        linkPath={`${paths.ENROL_RESERVED_KID}?id=${id}`}
        heading={`There is a space available for ${name}.`}
        copy="Please check the details and confirm."
        icon="music"
        key={id}
      />
    );
  };

  instrumentChangePrompt = change => {
    const { kidId, name } = change;
    return (
      <MessagePrompt
        linkCopy="Details"
        linkPath={`${paths.INSTRUMENT_CHANGE}?id=${kidId}`}
        heading={`There is an instrument change available for ${name}.`}
        copy="Please check the details and confirm."
        icon="exchange"
        key={kidId}
      />
    );
  };

  missingDetailsPrompt = () => (
    <MessagePrompt
      key="missing-details"
      linkCopy="Update"
      linkPath={paths.MY_DETAILS}
      heading="We currently do not have all of your details."
      copy="Please complete your profile."
      icon="id card"
    />
  );

  missingBankPrompt = () => (
    <MessagePrompt
      key="missing-bank-details"
      linkCopy="Update"
      linkPath={paths.DIRECT_DEBITS}
      heading="No Active Direct Debit"
      copy="Please update your bank details to keep your child's place in the band, or let us know if you are cancelling."
      icon="edit"
    />
  );

  upcomingConcertPrompt = kid => {
    const { id, attributes } = kid;
    const { first_name } = attributes;

    return (
      <MessagePrompt
        key={`upcoming-concert-${id}`}
        linkPath={this.pathToConcertDetails(kid)}
        heading="Exciting news!"
        copy={`We've got a date for ${first_name}'s concert. <u>Find out more</u>.`}
        icon="volume up"
      />
    );
  };

  pathToConcertDetails = kid => {
    const timelineEntries = kid.attributes.timeline_entries.data;
    const concertDetailsInTimeline = timelineEntries.some(
      e => e.attributes.category === 'Concerts'
    );
    if (concertDetailsInTimeline) {
      return paths.TIMELINE(kid.id);
    }
    return paths.TIMETABLE(kid.id);
  };

  // Disabled during school closure and launch of rehearse at home
  // const NpsForm = () => (
  //   <NPSForm
  //     grownUpId={grownUpId}
  //     grownUpName={grownUpName}
  //     setGrownUp={setGrownUp}
  //     grownUpAttributes={grownUpAttributes}
  //     showForm={showNps}
  //     showNPSThanksMessage={showNPSThanksMessage}
  //     setShowNPSThanksMessage={setShowNPSThanksMessage}
  //   />
  // );

  unseenEntryCount = (kid) => kid.attributes.timeline_entries.data.filter((entry) => {
    return entry.attributes.date_seen === null;
  }).length

  childTimelineSummary = kid => {
    const unseenCount = this.unseenEntryCount(kid);
    const entryCount = kid.attributes.timeline_entries.data.length;

    if (entryCount > 0) {
      return (
        <Link key={kid.id} to={paths.TIMELINE(kid.id)}>
          <Message className="child-entry-summary">
            <Message.Content>
              <div className="summary-flex-wrapper">
                <div className="summary-flex-wrapper">
                  <span><Image src={personAvatar} /></span>
                  <span className="name">
                    {kid.attributes.first_name}
                    &apos;s Timeline
                  </span>
                </div>
                <div>
                  {unseenCount > 0 && <Label circular className="circular-label">{unseenCount}</Label>}
                </div>
              </div>
            </Message.Content>
          </Message>
        </Link>
      )
    }

    return null;
  }

  childTimelines = () => {
    const { kids } = this.props

    if (kids.length === 0) {
      return null
    }

    let totalEntryCount = 0;

    kids.forEach(kid => {
      totalEntryCount += kid.attributes.timeline_entries.data.length
    })

    if (totalEntryCount === 0) {
      return null
    }

    return (
      <div className="homepage-child-timelines">
        <h3>Parent Timeline</h3>
        {kids.map(kid => this.childTimelineSummary(kid))}
      </div>
    );
  }

  upcomingConcertScheduled = kidId => {
    const { kidsTimetableData } = this.state;

    if (!_.isEmpty(kidsTimetableData[kidId])) {
      return kidsTimetableData[kidId].some(el => el.concert);
    }
    return false;
  };

  actions = () => {
    const messages = [];
    const { detailsMissing, grownUpAttributes, reservedKids, unapprovedInstrumentChanges, kids } = this.props;

    if (detailsMissing) {
      messages.push(this.missingDetailsPrompt());
    }
    if (grownUpAttributes.active_direct_debit_or_dd_info === false && grownUpAttributes.kids_paying_for_amount > 0) {
      messages.push(this.missingBankPrompt());
    }
    if (reservedKids.length > 0) {
      messages.push(reservedKids.map(kid => this.reservedKidPrompt(kid)));
    }

    if (unapprovedInstrumentChanges.length > 0) {
      messages.push(
        unapprovedInstrumentChanges.map(change =>
          this.instrumentChangePrompt(change))
      );
    }

    if (kids.length > 0) {
      kids.forEach(kid => {
        if (this.upcomingConcertScheduled(kid.id)) {
          messages.push(this.upcomingConcertPrompt(kid));
        }
      });
    }

    if (messages.length === 0) {
      return null;
    }
    return (
      <div className="homepage-actions">
        <h3>Notifications</h3>
        <div>{messages}</div>
      </div>
    );
  };

  timeOfDay = () => {
    const hours = DateUtils.today().getHours();

    if (hours < 12) {
      return "Morning"
    }

    if (hours < 17) {
      return "Afternoon"
    }

    return "Evening"
  }

  render() {
    const { kids, grownUpAttributes, approvedInstrumentChanges } = this.props;
    const grownUpFirstName = grownUpAttributes.first_name;

    return (
      <Grid relaxed stackable padded className="welcome">
        <Grid.Row>
          <Grid.Column computer={8} tablet={16}>
            <h1>
              Good
              {' '}
              {this.timeOfDay()}
              {' '}
              <span className="blue">{grownUpFirstName}</span>
              <span className="full-welcome">, Welcome to your Rocksteady Backstage</span>
            </h1>
          </Grid.Column>
          <Grid.Column computer={8} tablet={16}>
            {this.actions()}
            {this.childTimelines()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <KidsAppPromoBanner kids={kids} />
            {/* {NpsForm()} */}
            <MediaQuery minWidth={769}>
              <KidDetails
                kids={kids}
                approvedInstrumentChanges={approvedInstrumentChanges}
              />
            </MediaQuery>
            <MediaQuery maxWidth={768}>
              <h3>Your Children</h3>
              <Menu vertical fluid secondary>
                {kids.map((kid) => <KidMenuItem kid={kid} key={kid.id} isWelcomePage />)}
              </Menu>
            </MediaQuery>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
};

export default Welcome;
