/* eslint-disable react/no-danger */
import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MessagePrompt = (props) => {
  const {
    heading, copy, linkPath, icon,
  } = props;

  return (
    <Link to={linkPath}>
      <Message icon>
        <Icon name={icon} />
        <Message.Content>
          <Message.Header>{heading}</Message.Header>
          <p dangerouslySetInnerHTML={{ __html: copy }} />
        </Message.Content>
      </Message>
    </Link>  
  );
};

MessagePrompt.propTypes = {
  heading: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  linkPath: PropTypes.string,
};

MessagePrompt.defaultProps = {
  linkPath: null,
};

export default MessagePrompt;
