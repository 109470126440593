import React from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Message, Segment } from 'semantic-ui-react';
import InstrumentChoiceSection from './InstrumentChoiceSection';
import MessagePrompt from '../MessagePrompt/MessagePrompt';
import paths from '../../consts/paths';
import PageHeading from '../PageHeading/PageHeading';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';
import KidAndSchoolSection from './KidAndSchoolSection';
import SummarySection from './SummarySection';
import SuccessMessage from './SuccessMessage';

export default function KidRegistration() {
  const {
    resetState,
    page: { readyForSummary, actionSuccessful, httpErrors, httpErrorsExist, continueToDirectDebitInfo },
    kid: { kidId, kidName, configureKidObject },
    payment: { directDebitInfoNeeded },
    grownUp: { profileComplete },
  } = useKidRegistration();

  const content = () => {
    if (httpErrorsExist) {
      return (
        <Message negative onDismiss={resetState}>
          <Message.Header>Error</Message.Header>
          <Message.List>
            {httpErrors.map((message, i) => (
              <Message.Item key={`error-${i + 1}`}>{message}</Message.Item>
            ))}
          </Message.List>
          <Message.Content>
            Please dismiss this message or refresh to try again. If the problem persists please contact us.
          </Message.Content>
        </Message>
      );
    }

    if (directDebitInfoNeeded && continueToDirectDebitInfo) {
      return (
        <Redirect
          push
          to={{ pathname: paths.DIRECT_DEBITS, state: { kidParams: configureKidObject({ withPaymentInfo: true }) } }}
        />
      );
    }

    if (actionSuccessful) {
      return <SuccessMessage />;
    }

    if (!profileComplete) {
      return (
        <Segment>
          <MessagePrompt
            linkCopy="Update"
            linkPath={paths.MY_DETAILS}
            heading="We currently do not have all of your details"
            copy="Please complete your profile before continuing"
            icon="id card"
          />
        </Segment>
      );
    }

    return (
      <Form>
        <KidAndSchoolSection />
        <InstrumentChoiceSection />
        {readyForSummary && <SummarySection />}
      </Form>
    );
  }

  return (
    <div className="page-container">
      <PageHeading
        heading={`${kidId ? `Enrol ${kidName}` : 'Register a child'} for Rocksteady Lessons`}
        subHeading="Or join the waitlist"
      />
      {content()}
    </div>
  )
};
