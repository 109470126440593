import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Segment, Image, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import paths from '../../consts/paths';
import RequireKidsAppPromoLive from '../RequireKidsAppPromoLive/RequireKidsAppPromoLive';
import rocksteadyPlayLogo from '../../images/portal/rocksteady_play_logo.png';
import rocksteadyPlayFeature from '../../images/portal/rsplay_feature.png';

// WIP!!

export default function KidsAppPromoBanner({ kids }) {
  const eligibleKids = kids.filter(
    ({ attributes }) =>
      attributes.is_enrolled &&
      attributes.kids_app_timetable_price_pennies !== null
  );

  if (!eligibleKids.length) return null;

  return (
    <RequireKidsAppPromoLive>
      <Segment
        inverted
        color="green"
        style={{ padding: '0px', height: '130px' }}
      >
        <Link to={{ pathname: paths.KIDS_APP, state: { eligibleKids } }}>
          <MediaQuery minWidth={769}>
            <Image
              src={rocksteadyPlayFeature}
              floated="right"
              style={{ height: '130px', display: 'block' }}
            />
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <Image
              src={rocksteadyPlayLogo}
              floated="right"
              style={{ height: '130px', display: 'block' }}
            />
          </MediaQuery>
        </Link>

        <div style={{ padding: '20px', textAlign: 'left' }}>
          <Header as="h2" style={{ margin: '0px' }}>
            ROCKSTEADY PLAY
          </Header>
          <p style={{ margin: '0 0 5px' }}>Take your band home with you!</p>

          <Link
            to={{ pathname: paths.KIDS_APP, state: { eligibleKids } }}
            className="ui primary button"
          >
            Get the App
            <Icon name="right arrow" />
          </Link>
        </div>
      </Segment>
    </RequireKidsAppPromoLive>
  );
}

KidsAppPromoBanner.propTypes = {
  kids: PropTypes.array.isRequired
};
