import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import confetti from 'canvas-confetti';
import { animated, useTransition } from '@react-spring/web'
import SlideItem from './SlideItem';
import SlideImageItem from './SlideImageItem';
import SlideVideoItem from './SlideVideoItem';
import SlideProgressItem from './SlideProgressItem';
import './Slides.scss';

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const Slides = (props) => {
  const {
    slidesData,
    activeIndex,
    colorOption,
    grownUpId,
    kidId,
    muted,
    triggerAudioTrackPause,
    kidInstrument
  } = props;

  const activeSlide = slidesData[activeIndex]
  const {
    cta,
    cta_func
  } = activeSlide;

  const activeSection = slidesData[activeIndex].sections;
  const [showBigIcon, setShowBigIcon] = useState(false);

  const isIntro = activeSlide.name === 'intro';
  const isOutro = (activeIndex + 1) === slidesData.length;
  let colorStyle = '';
  if (isOutro && colorOption === 3) {
    colorStyle = ' SlideColorOption3';
  }


  let containerClass = 'Slide';
    switch (kidInstrument) {
      case 'vocals':
        if (showBigIcon) {
          containerClass = `SlideWithBigVocalsIcon${colorStyle}`; 
        } else {
          containerClass = 'SlideVocals';
        }
        break;
      case 'drums':
        if (showBigIcon) {
          containerClass = `SlideWithBigDrumsIcon${colorStyle}`; 
        } else {
          containerClass = 'SlideDrums';
        }
        break;
      case 'keyboard':
        if (showBigIcon) {
          containerClass = `SlideWithBigKeyboardIcon${colorStyle}`; 
        } else {
          containerClass = 'SlideKeyboard';
        }
        break;
      case 'guitar':
        if (showBigIcon) {
          containerClass = `SlideWithBigGuitarIcon${colorStyle}`; 
        } else {
          containerClass = 'SlideGuitar';
        }
        break;
      case 'bass':
        if (showBigIcon) {
          containerClass = `SlideWithBigBassIcon${colorStyle}`; 
        } else {
          containerClass = 'SlideBass';
        }
        break;
      default:
        if (showBigIcon) {
          containerClass = `SlideWithBigVocalsIcon${colorStyle}`;
        } else {
          containerClass = 'SlideVocals';
        }
        break;
  }

  if (colorOption === 2){
    containerClass = 'SlideColorOption2'
  }
  if (colorOption === 3 && !isOutro){
    containerClass = 'SlideColorOption3';
  }


  useEffect(() => {
    let bigIcon = false;
    Object.keys(activeSection).forEach((section) => {
      const data = activeSection[section];
      return data.length && data.forEach((d) => {
        if (Object.keys(d).includes('big_icon')) {
          bigIcon = true;
        }
      })
    })
    setShowBigIcon(bigIcon)
  }, [activeIndex, activeSection])

  // Confetti effect for the outro slide
  useEffect(
    () => {
      if (activeSlide.name === 'outro') {

        const duration = 15 * 1000;
        const animationEnd = Date.now() + duration;
        const defaults = {
          startVelocity: 30,
          spread: 360,
          ticks: 60,
          zIndex: 0
        };

  
        // eslint-disable-next-line consistent-return
        const interval = setInterval(() => {
          const timeLeft = animationEnd - Date.now();

          if (timeLeft <= 0) {
            return clearInterval(interval);
          }

          const particleCount = 50 * (timeLeft / duration);
          // since particles fall down, start a bit higher than random
          confetti({
            ...defaults,
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
          });
          confetti({
            ...defaults,
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
          });
        }, 250);
      }
    },
    [activeSlide]
  );

  const renderItems = (items) => {
    const isKidImage = items.filter((item) => Object.keys(item).includes('report')).length;
    const isVideo = items.filter((item) => Object.keys(item).includes('video'))[0];
    const isProgress = items.filter((item) => Object.keys(item).includes('progress'))[0];

    if (isProgress) {
      return (
        <SlideProgressItem
          containerClass={containerClass}
          item={isProgress}
          activeSlide={activeSlide}
        />
      )
    }

    if (isVideo) {
      return (
        <SlideVideoItem
          items={items}
          containerClass={containerClass}
          muted={muted}
          triggerAudioTrackPause={triggerAudioTrackPause}
          grownUpId={grownUpId}
          kidId={kidId}
          isReportable={isKidImage > 0}
        />
      )
    }

    if (isKidImage) {
      return (
        <SlideImageItem
          items={items} 
          containerClass={containerClass}
          grownUpId={grownUpId}
          kidId={kidId}
        />
      )
    }

    
    return items.map((item, index) => {
      const keyId = `item__${index}`;
      const delay = 250 + (150 * index);

      return (
        <SlideItem key={keyId} item={item} containerClass={containerClass} delay={delay} />
      )
    })
  };

  const renderSection = (section) => {
    const isVisible = true;
    const isVideo = section.filter((item) => Object.keys(item).includes('video'))[0];
    
    return (
      <div className={isVisible ? `Slide__section__active` : `Slide__section__inactive`} style={isVideo ? { width: "100%" } : {}}>
        {renderItems(section)}
      </div>
    )

  }

  const activeSection1 = activeSlide.sections[1];
  const activeSection2 = activeSlide.sections[2];
  const activeSection3 = activeSlide.sections[3];
  const activeSection4 = activeSlide.sections[4];
  const activeSection5 = activeSlide.sections[5];

  const items = useMemo(() => {
    const itemsArray = [];
    if (activeSection1.length) {
      itemsArray.push(renderSection(activeSection1));
    }
    if (activeSection2.length) {
      itemsArray.push(renderSection(activeSection2));
    }
    if (activeSection3.length) {
      itemsArray.push(renderSection(activeSection3));
    }
    if (activeSection4.length) {
      itemsArray.push(renderSection(activeSection4));
    }
    if (activeSection5.length) {
      itemsArray.push(renderSection(activeSection5));
    }
    if (cta) {
      itemsArray.push(<button type='button' onClick={cta_func} className='Slide__cta'>{cta}</button>);
    }
    return itemsArray;
  }, [activeSection1, activeSection2, activeSection3, activeSection4, activeSection5, cta, cta_func, colorOption]);

  const transitions = useTransition(items, {
    from: { opacity: 0, transform: 'translateY(30px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    trail: 150,
  });

  
  const animatedBit = transitions((style, item) => {
    return (
      <animated.div style={style}>
        {item}
      </animated.div>
    )
  })
  
  return (
    <div className={isIntro ? `${containerClass} IntroSlide` : containerClass}>
      {animatedBit}
    </div>
  )

}

export default Slides;

const SlideProps = {
  cta: PropTypes.string,
  cta_func: PropTypes.func,
  order: PropTypes.number,
  sections: PropTypes.object.isRequired,
}

Slides.propTypes = {
  slidesData: PropTypes.arrayOf(PropTypes.shape(SlideProps)).isRequired,
  activeIndex: PropTypes.number.isRequired,
  colorOption: PropTypes.number,
  grownUpId: PropTypes.string.isRequired,
  kidId: PropTypes.string.isRequired,
  muted: PropTypes.bool.isRequired,
  triggerAudioTrackPause: PropTypes.func.isRequired,
  kidInstrument: PropTypes.string
}

Slides.defaultProps = {
  colorOption: 1,
  kidInstrument: ''
}
