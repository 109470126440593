import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Segment, Form, Button, Message,
} from 'semantic-ui-react';
import TimetableSearch from '../TimetableSearch/TimetableSearch';
import KidAPI from '../../api/KidAPI';

class KidNextSchool extends Component {
  static propTypes = {
    timetableAttributes: PropTypes.object.isRequired,
    kidAttributes: PropTypes.object.isRequired,
    kidId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    const warningMessage = this.getWarningMessage(props.kidAttributes);
    this.state = {
      confirmedNextTimetableId: props.kidAttributes.confirmed_next_timetable_id,
      confirmedNextTimetableName: props.kidAttributes.confirmed_next_timetable_name,
      confirmedNextTimetableChanged: false,
      doNotMoveToNextTimetable: props.kidAttributes.do_not_move_to_next_timetable,
      doNotMoveToNextTimetableChanged: false,
      currentAndNextTimetablesAreEqual: false,
      warningMessage,
    };
  }

  generalInfo = () => {
    const { timetableAttributes } = this.props;
    return (
      <p>
        In your child’s current school, we teach up to
        {' '}
        <b>Year</b>
        {' '}
        <b>{timetableAttributes.rs_max_school_year}</b>
        .
      </p>
    );
  };

  noSchoolFeederInfo = () => {
    const { timetableAttributes } = this.props;
    if (
      timetableAttributes.likely_next_timetable_id === ''
      || timetableAttributes.likely_next_timetable_id === null
    ) {
      return (
        <div>
          <p>
            <em>{timetableAttributes.display_name}</em>
            {' '}
            is not setup as a &quot;feeder school&quot;
            for another school that we currently teach in.
          </p>
          <p>
            You can use the search below to see if we do offer lessons at your child’s next school.
            And if we do, we can transfer their account automatically when they finish Year
            {' '}
            {timetableAttributes.rs_max_school_year}
            .
          </p>
          <p>
            Alternatively, when your child finishes Year
            {' '}
            {timetableAttributes.rs_max_school_year}
            ,
            we can remove them from the timetable and cancel your Direct Debit subscription (see
            below).
            <br />
            <br />
          </p>
        </div>
      );
    }
    return null;
  };

  confirmNextSchool = (id, name) => {
    const { kidAttributes } = this.props;
    this.setState({
      confirmedNextTimetableId: id,
      confirmedNextTimetableName: name,
      confirmedNextTimetableChanged: true,
      currentAndNextTimetablesAreEqual: id === kidAttributes.timetable_id,
    });
  };

  resetNextConfirmedTimetableId = () => {
    this.setState({
      confirmedNextTimetableId: null,
      confirmedNextTimetableName: null,
      confirmedNextTimetableChanged: true,
      currentAndNextTimetablesAreEqual: false,
    });
  };

  confirmedNextTimetableNameEmpty = () => {
    const { confirmedNextTimetableName } = this.state;
    return confirmedNextTimetableName === '' || confirmedNextTimetableName === null;
  };

  searchShoolForm = () => {
    const { confirmedNextTimetableName, currentAndNextTimetablesAreEqual } = this.state;
    return (
      <div>
        {this.confirmedNextTimetableNameEmpty() ? (
          <TimetableSearch onSelectingTimetable={(id, name) => this.confirmNextSchool(id, name)} />
        ) : (
          ''
        )}
        <b>
          Your child&apos;s next school:
          {' '}
        </b>
        {' '}
        {confirmedNextTimetableName || 'Not set'}
        {' '}
        {!this.confirmedNextTimetableNameEmpty() ? (
          <Button icon="delete" size="mini" basic onClick={this.resetNextConfirmedTimetableId} />
        ) : (
          ''
        )}
        {currentAndNextTimetablesAreEqual ? (
          <Message negative>
            You have selected your child’s
            {' '}
            <strong>current</strong>
            {' '}
            school, but this form is to tell
            us which school they are going to
            {' '}
            <strong>next</strong>
            . Please double check your
            selection before you can save your changes.
          </Message>
        ) : (
          ''
        )}
      </div>
    );
  };

  doNotMoveToNextTimetableChange = (e, { checked }) => {
    this.setState({ doNotMoveToNextTimetable: checked, doNotMoveToNextTimetableChanged: true });
  };

  moveKidFromSchool = () => {
    const { timetableAttributes, kidAttributes } = this.props;
    const { rs_max_school_year } = timetableAttributes;
    const { doNotMoveToNextTimetable } = this.state;

    return (
      <Form.Checkbox
        onChange={this.doNotMoveToNextTimetableChange}
        checked={doNotMoveToNextTimetable}
        label={`Do not move ${
          kidAttributes.name
        } to a new school when they finish Year ${rs_max_school_year}`}
      />
    );
  };

  toWhichSchoolInfo = () => {
    const { timetableAttributes } = this.props;
    const {
      likely_next_timetable_id,
      likely_next_timetable_name,
      rs_max_school_year,
    } = timetableAttributes;

    const { confirmedNextTimetableName } = this.state;
    return (
      <div>
        <h3>Do we teach at your child’s next school?</h3>
        {likely_next_timetable_id && confirmedNextTimetableName === '' ? (
          <p>
            If your child is not moving to
            {' '}
            <em>{likely_next_timetable_name}</em>
            , please let us know
            which school they are moving to...
          </p>
        ) : (
          ''
        )}
        <p>
          Use this form to search and see if we teach at your child&apos;s next school.
        </p>
        <div>
          {this.searchShoolForm()}
          {this.saveButton()}
        </div>
        <h3>Availability of spaces in your next school</h3>
        <p>
          If your child moves across to a new school, lesson places will be offered in the order
          that children first joined Rocksteady (or our waiting list). Availability depends on the
          age ranges we can teach, and the number of existing bands and students in the school. As
          always, the more instruments your child is happy to play, the greater their chance of
          getting a place straight away, so please keep their preferences up to date.
        </p>
        <h3>Finishing Rocksteady</h3>
        <p>
          If you don’t want to move your child’s account to another school when they finish Year
          {' '}
          {rs_max_school_year}
          , please check this box
        </p>
        <div>{this.moveKidFromSchool()}</div>
      </div>
    );
  };

  withSchoolFeederInfo = () => {
    const { timetableAttributes } = this.props;
    const { confirmedNextTimetableName } = this.state;
    if (timetableAttributes.likely_next_timetable_id && confirmedNextTimetableName === '') {
      return (
        <div>
          <p className="ui message info">
            <em>{timetableAttributes.display_name}</em>
            {' '}
            is typically a &quot;feeder school&quot; for
            {' '}
            <em>{timetableAttributes.likely_next_timetable_name}</em>
            and we will automatically move your child&apos;s account across to
            <em>{timetableAttributes.likely_next_timetable_name}</em>
            {' '}
            when they finish Year
            {timetableAttributes.rs_max_school_year}
            {' '}
            (unless you tell us otherwise using the options
            on this page).
          </p>
          <p>
            If we are able to offer a place in the new term, we will continue your Direct Debit
            subscription (it will be paused over summer holidays). If we are not able to offer a
            place immediately, we will cancel your Direct Debit and prioritise your place on the
            waitlist until a place is available for your child.
            <br />
            <br />
          </p>
        </div>
      );
    }
    return null;
  };

  saveButton = () => {
    const {
      doNotMoveToNextTimetableChanged,
      confirmedNextTimetableChanged,
      currentAndNextTimetablesAreEqual,
    } = this.state;
    return (
      <div>
        <br />
        <Button
          onClick={this.saveForm}
          disabled={
            currentAndNextTimetablesAreEqual
            || (!doNotMoveToNextTimetableChanged && !confirmedNextTimetableChanged)
          }
          positive
        >
          Save Changes
        </Button>
      </div>
    );
  };

  getWarningMessage = (attributes) => {
    const {
      do_not_move_to_next_timetable: doNotMoveKidToNextTimetable,
      confirmed_next_timetable_id: confirmedNextTimetableId,
      confirmed_next_timetable_name: confirmedNextTimetableName,
      name,
    } = attributes;
    const { timetableAttributes } = this.props;

    if (doNotMoveKidToNextTimetable) {
      return (
        <p className="ui message orange">
          You have told us you don’t want to move
          {' '}
          {name}
          {"'"}
          s account to another school when they finish Year
          {' '}
          {timetableAttributes.rs_max_school_year}
          . You don&apos;t need to do anything else. We will cancel your Direct Debit when
          {name}
          {' '}
          finishes their last term with Rocksteady.
        </p>
      );
    }
    if (confirmedNextTimetableId > 0) {
      return (
        <p className="ui message positive">
          You have confirmed that
          {' '}
          {name}
          {' '}
          is moving to
          {' '}
          {confirmedNextTimetableName}
          {' '}
          when they finish
          Year
          {' '}
          {timetableAttributes.rs_max_school_year}
          {' '}
          and would like to continue Rocksteady
          lessons if there is space
        </p>
      );
    }
    return null;
  };

  saveForm = () => {
    const { confirmedNextTimetableId, doNotMoveToNextTimetable } = this.state;
    const { kidId } = this.props;
    const params = {
      confirmed_next_timetable_id: confirmedNextTimetableId,
      do_not_move_to_next_timetable: doNotMoveToNextTimetable,
    };
    KidAPI.updateKid(kidId, params).then((response) => {
      const warningMessage = this.getWarningMessage(response.data.data.attributes);
      this.setState({
        confirmedNextTimetableChanged: false,
        doNotMoveToNextTimetableChanged: false,
        confirmedNextTimetableName: response.data.data.attributes.confirmed_next_timetable_name,
        warningMessage,
      });
    });
  };

  render() {
    const { warningMessage } = this.state;
    return (
      <Segment>
        <Form>
          <h1>
            Your Child’s
            {' '}
            <em>Next</em>
            {' '}
            School
          </h1>
          {warningMessage}
          {this.generalInfo()}
          {this.noSchoolFeederInfo()}
          {this.withSchoolFeederInfo()}
          {this.toWhichSchoolInfo()}
          {this.saveButton()}
        </Form>
      </Segment>
    );
  }
}

export default KidNextSchool;
