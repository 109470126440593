import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Segment } from 'semantic-ui-react';
import RatingButtons from './RatingButtons';
import NpsAPI from '../../api/NpsAPI';

class NPSForm extends Component {
  static propTypes = {
    grownUpId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    grownUpName: PropTypes.string.isRequired,
    setGrownUp: PropTypes.func.isRequired,
    grownUpAttributes: PropTypes.object.isRequired,
    showForm: PropTypes.bool.isRequired,
    showNPSThanksMessage: PropTypes.bool.isRequired,
    setShowNPSThanksMessage: PropTypes.func.isRequired,
    preselectedScore: PropTypes.number,
  };

  static defaultProps = {
    preselectedScore: null
  }

  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      rate: props.preselectedScore,
    };
  }

  setComment = e => {
    this.setState({ comment: e.target.value });
  };

  submitForm = () => {
    const { rate, comment } = this.state;
    const {
      grownUpId,
      grownUpName,
      setGrownUp,
      grownUpAttributes,
      setShowNPSThanksMessage
    } = this.props;
    const data = { data: { score: rate, comment, logged_by: grownUpName, submitted_from: 'other' } };
    if (rate > 0) {
      NpsAPI.createNPS(grownUpId, data).then(() => {
        setShowNPSThanksMessage(true);
        setGrownUp(grownUpId, {
          ...grownUpAttributes,
          show_nps_form_in_backstage: false
        });
      });
    }
  };

  comment = () => {
    const { comment } = this.state;
    return (
      <Form.TextArea
        value={comment}
        onChange={e => this.setComment(e)}
        maxLength="254"
      />
    );
  };

  submitButton = () => (
    <center>
      <Button onClick={this.submitForm} className="primary">
        Send feedback
      </Button>
    </center>
  );

  selectRate = val => {
    this.setState({ rate: val });
  };

  sentFeedbackMessage = () => (
    <div>
      <br />
      <p>Thank you for taking the time to send your feedback.</p>
      <p>
        If you have any issues or concerns that need a quick reply or action
        from our team, please call us on
        {' '}
        <a href="tel:0330 113 0330">0330 113 0330</a>
        {' '}
        or email
        {' '}
        <a href="mailto:info@rocksteadymusicschool.com">
          info@rocksteadymusicschool.com
        </a>
        .
      </p>
      <p>
        While we read all feedback sent through this form, we can help more
        quickly with issues if you contact us directly.
      </p>
    </div>
  );

  npsForm = () => {
    const { rate } = this.state;
    return (
      <Form>
        <center>
          <h3>Where 1 is &lsquo;very unlikely&rsquo; and 5 &lsquo;very likely&rsquo;</h3>
          <span className='rating-key rating-key-unlikely'>Very Unlikely</span>
          <RatingButtons rate={rate} onSelectRate={this.selectRate} />
          <span className='rating-key'>Very Likely</span>
        </center>
        <div>
          <br />
          <strong>Please tell us why</strong>
          <br />
          {this.comment()}
          {this.submitButton()}
        </div>
      </Form>
    );
  };

  render() {
    const { showForm, showNPSThanksMessage } = this.props;
    if (showNPSThanksMessage || showForm) {
      return (
        <div>
          <Segment>
            {showNPSThanksMessage ? this.sentFeedbackMessage() : ''}
            {showForm ? this.npsForm() : ''}
          </Segment>
        </div>
      );
    }
    return null;
  }
}
export default NPSForm;
