import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Segment, Button, Grid, Message, List,
} from 'semantic-ui-react';
import { Prompt } from 'react-router-dom';
import MarketingField from './MarketingField';
import WindowUtils from '../../utils/WindowUtils';
import FormUtils from '../../utils/FormUtils';
import ObjectUtils from '../../utils/ObjectUtils';
import StringUtils from '../../utils/StringUtils';
import PageHeading from '../PageHeading/PageHeading';
import AutocompleteAddressForm from '../AutocompleteAddressForm/AutocompleteAddressForm';

class GrownUpForm extends Component {
  static propTypes = {
    grownUpObject: PropTypes.object.isRequired,
    grownUpId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleFormSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      grownUpObject: props.grownUpObject,
      originalObject: props.grownUpObject,
      loading: false,
      successful: false,
    };
  }

  get formUtils() {
    return new FormUtils({
      requiredFields: [
        'name',
        'phone',
        'email',
        'address_l1',
        'address_city',
        'address_postcode',
        'comms_marketing',
      ],
      uneditableFields: ['email'],
      updateCallback: this.updateAttribute,
    });
  }

  formField = ({ field, label, placeholder } = {}) => {
    const { grownUpObject } = this.state;
    return (
      <Form.Field key={field} error={this.requiredAndMissing(field)}>
        <Form.Input
          id={field}
          label={label}
          placeholder={placeholder}
          value={grownUpObject[field] || ''}
          onChange={this.formUtils.handleInputChange(field)}
          autoComplete={`grown-up-${field}`}
          disabled={this.formUtils.fieldNotEditable(field)}
        />
      </Form.Field>
    );
  };

  updateAttribute = (name, value) => {
    this.setState(prevState => ({
      grownUpObject: {
        ...prevState.grownUpObject,
        [name]: value,
      },
    }));
  };

  requiredAndMissing = (field) => {
    const { grownUpObject, showValidationErrors } = this.state;
    const value = grownUpObject[field];
    return showValidationErrors && this.formUtils.requiredAndMissing(field, value);
  };

  validateFormAndSubmit = () => {
    this.setState({
      showValidationErrors: true,
    });
    const { grownUpObject, originalObject } = this.state;
    if (this.formUtils.noFieldsMissing(grownUpObject)) {
      const { grownUpId, handleFormSubmit } = this.props;
      this.setState({ loading: true }, () => {
        const filteredObject = ObjectUtils.filterUpdatedValuesOnObject(
          originalObject,
          grownUpObject,
        );
        handleFormSubmit(grownUpId, filteredObject).then(() => {
          WindowUtils.scrollToTop();
          this.setState({ loading: false, successful: true, originalObject: grownUpObject });
        });
      });
    }
  };

  noFieldsUpdated = () => {
    const { grownUpObject, originalObject } = this.state;
    return ObjectUtils.objectsAreTheSame(originalObject, grownUpObject);
  };

  submitFormButton = () => (
    <Button positive disabled={this.noFieldsUpdated()} size="big" type="submit">
      Save Changes
    </Button>
  );

  contactDetails = () => (
    <Segment>
      {this.formField({ field: 'name', label: 'Name' })}
      {this.formField({ field: 'phone', label: 'Phone' })}
      {this.formField({ field: 'email', label: 'Email' })}
      {this.formField({ field: 'second_email_address', label: 'Second Email Address' })}
      {this.formField({ field: 'alternative_contact', label: 'Alternative Contact' })}
    </Segment>
  );

  directDebitDetails = () => {
    const { grownUpObject } = this.state;
    const { active_direct_debit_or_dd_info } = grownUpObject;
    return (
      active_direct_debit_or_dd_info && (
        <Segment>
          <h5>Direct Debit</h5>
          If you need to update anything regarding your Direct Debit please contact the office on
          0330 113 0330
        </Segment>
      )
    );
  };

  preferences = () => {
    const { grownUpObject } = this.state;
    return (
      <Segment>
        <MarketingField
          value={grownUpObject.comms_marketing}
          handleChange={this.formUtils.handleRadioOrDropdownChange(
            'comms_marketing',
            this.updateAttribute,
          )}
        />
      </Segment>
    );
  };

  missingFieldsList = fields => (
    <List bulleted>
      {fields.map(field => (
        <List.Item key={field}>{StringUtils.humanize(field)}</List.Item>
      ))}
    </List>
  );

  missingFieldsMessage = () => {
    const { showValidationErrors, grownUpObject } = this.state;
    const fields = this.formUtils.missingFields(grownUpObject);
    return (
      showValidationErrors
      && fields.length > 0 && (
        <Message negative>
          <Message.Header>Missing information</Message.Header>
          <Message.Content>{this.missingFieldsList(fields)}</Message.Content>
        </Message>
      )
    );
  };

  autocompleteAddressProps = () => {
    const { grownUpObject } = this.state;
    return ['address_l1', 'address_l2', 'address_city', 'address_county', 'address_postcode']
      .map(name => (
        {
          value: grownUpObject[name] || '',
          onChange: this.formUtils.handleInputChange(name),
          error: this.requiredAndMissing(name)
        }
      ))
  };

  autocompleteSetWholeAddress = (
    address_l1, address_l2, address_city, address_county, address_postcode,
  ) => {

    this.setState((prevState) => ({
      grownUpObject: {
        ...prevState.grownUpObject,
        address_l1,
        address_l2,
        address_city,
        address_county,
        address_postcode,
      },
    }));
  };

  formFields = () => (
    <Grid.Row columns={2}>
      <Grid.Column>
        {this.contactDetails()}
        {this.preferences()}
      </Grid.Column>
      <Grid.Column>
        <AutocompleteAddressForm
          addressProps={this.autocompleteAddressProps()}
          onAddressSelect={this.autocompleteSetWholeAddress}
        />
        {this.directDebitDetails()}
        {this.missingFieldsMessage()}
        {this.submitFormButton()}
      </Grid.Column>
    </Grid.Row>
  );

  successMessage = () => {
    const { successful } = this.state;
    return (
      successful && (
        <Message header="Thank you" content="Your details have been updated." />
      )
    );
  };

  render() {
    const { loading } = this.state;
    return (
      <div className="page-container">
        <PageHeading
          heading="Your Details"
          subHeading="Please check your details and update if anything changes"
        />
        <Form loading={loading} onSubmit={this.validateFormAndSubmit}>
          {this.successMessage()}
          <Prompt
            when={!this.noFieldsUpdated()}
            message="Are you sure you want to leave? You have unsaved changes"
          />
          <Grid stackable>{this.formFields()}</Grid>
        </Form>
      </div>
    );
  }
}

export default GrownUpForm;
