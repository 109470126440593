import React, { Component } from 'react';
import { Button, Form, Message, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import SchoolUserAPI from '../../../api/SchoolUserAPI';
import StringUtils from '../../../utils/StringUtils';
import WindowUtils from '../../../utils/WindowUtils';
import paths from '../../../consts/paths';
import warnings from '../../../consts/warnings';

class SignInForm extends Component {
  static propTypes = {
    onSigningIn: PropTypes.func.isRequired,
    signedIn: PropTypes.bool
  };

  static defaultProps = {
    signedIn: false
  };

  constructor(props) {
    super(props);
    this.state = this.initState();
  }

  initState = () => ({
    email: '',
    password: '',
    showValidationErrors: false,
    failedSignIn: false,
    loading: false
  });

  handleInputChange = key => event => {
    this.setState({ [key]: event.target.value, showValidationErrors: false });
  };

  signIn = async () => {
    this.setState(
      {
        showValidationErrors: true
      },
      () => {
        if (this.formValid()) {
          this.tryToSignIn();
        }
      }
    );
  };

  tryToSignIn = async () => {
    const { email, password } = this.state;
    const { onSigningIn } = this.props;
    const config = {
      data: {
        school_user: {
          email,
          password
        }
      }
    };
    this.setState({ loading: true });
    try {
      const response = await SchoolUserAPI.signIn(config);
      const { authorization } = response.headers;
      onSigningIn(authorization);
    } catch (err) {
      if (err.request.status === 401) {
        this.setState({ failedSignIn: true, loading: false });
      } else {
        throw err;
      }
    }
  };

  messages = () => {
    const urlParams = StringUtils.parseQueryString(WindowUtils.anyParams());
    const { passwordSet, signInTimedOut, signedOut } = urlParams;
    if (passwordSet) {
      return (
        <Message positive>
          Thanks for setting up a password, please log in.
        </Message>
      );
    }
    if (signInTimedOut) {
      return <Message negative>Please sign in again.</Message>;
    }
    if (signedOut) {
      return <Message className="signed-out" size="big">You are now signed out</Message>;
    }
    return null;
  };

  redirect = () => {
    const { signedIn } = this.props;
    return signedIn && <Redirect push to={`${paths.SCHOOLS_ROOT}`} />;
  };

  emailValid = () => {
    const { email } = this.state;
    return StringUtils.isEmailIsh(email);
  };

  passwordValid = () => {
    const { password } = this.state;
    return !!password;
  };

  formValid = () => this.emailValid() && this.passwordValid();

  warningMessage = () => {
    const { showValidationErrors } = this.state;
    const formNotValid = showValidationErrors && !this.formValid();
    return (
      formNotValid && <Message negative content={warnings.EMAIL_OR_PASSWORD} />
    );
  };

  errorMessage = () => {
    const { failedSignIn } = this.state;
    return (
      failedSignIn && (
        <Message negative>
          <Message.Header>Please check your email and password.</Message.Header>
          <Message.Content>
            <p>
              Excessive failed sign in attempts will result in the account being locked. If this happens
              we will email you with instructions on how to unlock your account.
              If this problem continues please contact the office on info@rocksteadymusicschool.com
            </p>
          </Message.Content>
        </Message>
      )
    );
  };

  render() {
    const { email, password, showValidationErrors } = this.state;
    const { loading } = this.state;

    const emailInvalid = showValidationErrors && !this.emailValid();
    const passwordInvalid = showValidationErrors && !this.passwordValid();

    return (
      <Grid relaxed padded stackable>
        <Grid.Row centered>{this.messages()}</Grid.Row>
        <Grid.Row columns={3} className="sign-in-form">
          <Grid.Column />
          <Grid.Column textAlign="center">
            <h3>Schools Login</h3>
            <Form onSubmit={this.signIn} loading={loading} size="big">
              {/* {this.redirect()} */}
              {this.errorMessage()}
              {this.warningMessage()}
              <Form.Field>
                <Form.Input
                  placeholder="Email..."
                  value={email}
                  onChange={this.handleInputChange('email')}
                  error={emailInvalid}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  type="password"
                  placeholder="Password..."
                  value={password}
                  onChange={this.handleInputChange('password')}
                  error={passwordInvalid}
                />
              </Form.Field>
              <Grid stackable centered padded>
                <Grid.Row>
                  <Button size="big" positive type="submit">
                    Sign In
                  </Button>
                </Grid.Row>
                <Grid.Row className="forgotten-password">
                  <a href={paths.SCHOOLS_FORGOTTEN_PASSWORD}>
                    Forgotten your password?
                  </a>
                </Grid.Row>
              </Grid>
            </Form>
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
      </Grid>
    );
  }
}

export default SignInForm;
