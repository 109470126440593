import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Dropdown, Grid, Header } from 'semantic-ui-react';

const MainHeading = ({ 
  heading, subHeading,
  description, dropdownPlaceholder,
  multipleOptions, dropdownOptions,
  handleDropdownChange, dropdownValue,
  checkboxText, handleCheckbox
}) => {

  const headingColumnWidth = () => {
    return dropdownOptions && handleDropdownChange ? 10 : 16
  }
  
  const headingColumn = () => {
    return (
      <Grid.Column width={headingColumnWidth()}>
        <Header as="h1">
          {heading}
          {subHeading ? <Header.Subheader>{subHeading}</Header.Subheader> : null}
        </Header>
        {description ? <p className="main-heading-description">{description}</p> : null}
      </Grid.Column>
    )
  }

  const displayCheckbox = () => {
    return checkboxText && handleCheckbox ? <Checkbox label={checkboxText} onChange={handleCheckbox} /> : null
  }

  const dropdownColumn = () => {
    return (
      <Grid.Column
        width={6}
        className="heading-dropdown"
      >
        <Dropdown
          placeholder={dropdownPlaceholder}
          selection
          multiple={multipleOptions}
          options={dropdownOptions}
          onChange={handleDropdownChange}
          value={dropdownValue}
        />
        {displayCheckbox()}
      </Grid.Column>
    )
  }

  return (
    <Grid stackable>
      <Grid.Row className="main-heading">
        {headingColumn()}
        {dropdownOptions && handleDropdownChange ? dropdownColumn() : null}
      </Grid.Row>
    </Grid>
  );
};

MainHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  description: PropTypes.string,
  dropdownPlaceholder: PropTypes.string,
  multipleOptions: PropTypes.bool,
  dropdownOptions: PropTypes.array,
  handleDropdownChange: PropTypes.func,
  handleCheckbox: PropTypes.func,
  dropdownValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  checkboxText: PropTypes.string,
};

MainHeading.defaultProps = {
  subHeading: null,
  description: null,
  dropdownPlaceholder: "Select an option",
  multipleOptions: false,
  dropdownOptions: null,
  handleDropdownChange: null,
  handleCheckbox: null,
  dropdownValue: '',
  checkboxText: '',
};

export default MainHeading;
