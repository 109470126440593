import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Image, Segment, Button } from 'semantic-ui-react';
import WindowUtils from '../../utils/WindowUtils';
import ddLogo from '../../dd_logo.png';

class DdSummary extends React.Component {
  static propTypes = {
    grownUpAttributes: PropTypes.object.isRequired,
    firstPaymentDate: PropTypes.string,
    accountName: PropTypes.string.isRequired,
    accountNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    accountSortCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    bacsReference: PropTypes.string.isRequired,
    bankAddress: PropTypes.string.isRequired,
  }

  static defaultProps = {
    firstPaymentDate: null,
  }

  headerSection = () => (
    <div>
      <h2> Enrolment Complete</h2>
      <p>
        Thank you for booking your child onto Rocksteady lessons. You will soon receive an email
        from us with all the information you need to get them started.
        <br />
        <br />
        If you don&lsquo;t see this email, please check your spam/junk folder and add
        info@rocksteadymusicschool.com to your email contacts.
      </p>
      <p>
        If you have any questions, please call us on 0330 113 0330.
      </p>
      <br />
    </div>
  );

  noKidHeader = () => (
    <div>
      <h2>Direct Debit Complete</h2>
      <p>
        Thank you for completing your Direct Debit.
      </p>
      <p>
        If you have any questions, please call us on 0330 113 0330.
      </p>
      <br />
    </div>
  )

  aboutSection = () => (
    <div>
      <h3>About your Direct Debit</h3>
      <p>Your Direct Debit Instruction set up is complete.</p>
      <p>
        Confirmation of the instruction will be sent to you within 3 working days,
        or received by you no later than ten working days before the first collection
        or by letter or by email if you have requested to use this method.
      </p>
      <p>
        Thank you very much for your Direct Debit instruction details.
        Here is the Direct Debit Guarantee for your information.
      </p>
      <br />
    </div>
  );

  guDetails = () => {
    const { grownUpAttributes, accountName } = this.props;

    return (
      <div>
        <h4>Name of Account Holder</h4>
        <p>{accountName}</p>
        <h4>Phone Number</h4>
        <span>{grownUpAttributes.phone}</span>
        <h4>Email Address for payment receipts</h4>
        <span>{grownUpAttributes.dd_email_address}</span>
        <br />
        <br />
      </div>
    )
  };

  bankDetails = () => {
    const {
      accountNumber, accountSortCode, bacsReference, bankAddress
    } = this.props;
    return(
      <div>
        <h4>Bank / Building Society Account Number</h4>
        <span>{accountNumber}</span>
        <h4>Branch Sort Code</h4>
        <span>{accountSortCode}</span>
        <h4>Name and Address of bank or building society</h4>
        <span>{bankAddress}</span>
        <h4>BACS Reference</h4>
        <span>{bacsReference}</span>
      </div>
    )
  }

  rsAddress = () => (
    <div>
      <h4>Rocksteady Music School</h4>
      <p>
        Bohunt Manor
        <br />
        Portsmouth Road
        <br />
        Liphook
        <br />
        GU30 7DL
      </p>
      <br />
      <br />
    </div>
  );

  instruction = () => (
    <Segment>
      <h4>Instruction to your bank or building society</h4>
      <p>
        Please pay PSL re Rocksteady Music School Direct
        Debits from the account detailed in this
        Instruction subject to the safeguards assured by the Direct Debit Guarantee.
        I understand that this Instruction may remain with PSL re Rocksteady
        Music School and, if so, details will be passed electronically
        to my Bank / Building Society.
      </p>
    </Segment>
  );

  guarantee = () => (
    <Segment>
      <h2>
        The Direct Debit Guarantee
        <Image src={ddLogo} size="tiny" floated="right" />
      </h2>
      <ul>
        <li>
          This Guarantee is offered by all banks and building societies that accept instructions
          to pay Direct Debits.
        </li>
        <li>
          If there are any changes to the amount, date or frequency of your Direct Debit, PSL re
          Rocksteady Music School will notify you 3 working days in advance of your account
          being debited or as otherwise agreed. If you request PSL re Rocksteady Music School to
          collect a payment, confirmation of the amount and date will be given to you at the
          time of the request.
        </li>
        <li>
          If an error is made in the payment of your Direct Debit, by PSL re Rocksteady Music
          School or your bank or building society you are entitled to a full and immediate
          refund of the amount paid from your bank or building society.
          <br />
          - If you receive a refund you are not entitled to, you must pay it back when PSL re
          Rocksteady Music School asks you to.
        </li>
        <li>
          You can cancel a Direct Debit at any time by simply contacting your bank or building
          society. Written confirmation may be required. Please also notify us.
        </li>
      </ul>
    </Segment>
  );

  dateOrTBC = () => {
    const { firstPaymentDate } = this.props

    if (!firstPaymentDate) return 'TBC';
    return moment(firstPaymentDate).format('Do MMM YYYY');
  };

  termsAndConditions = () => (
    <div>
      <h4>Terms and Conditions</h4>
      <p>
        <b>
          Bank details are saved to Smart Debit&lsquo;s database.
          Smart Debit collects payments on Rocksteady Music School&lsquo;s behalf.
        </b>
      </p>
      <p>
        The first collection will come out of your account
        on
        {' '}
        <b>{this.dateOrTBC()}</b>
        {' '}
        and the same day
        each month (depending on the frequency agreed) thereafter. If there are any changes
        to the date, amount or frequency of your Direct Debit, we will always give you 3 working
        days notice in advance of your account being debited.
      </p>
      <p>
        The company that will appear on your bank statement against
        the Direct Debit will be
        <b>{JSON.stringify('PSL re Rocksteady Music School')}</b>
        .
      </p>
    </div>
  );

  printBtn = () => (
    <div>
      <br />
      <p>
        <Button
          size="large"
          className="offline-dd-button"
          floated="left"
          onClick={() => WindowUtils.print('printArea')}
        >
          Print Direct Debit Instruction
        </Button>
      </p>
      <br />
    </div>
  );

  render() {
    const { firstPaymentDate } = this.props;
    return (
      <div>
        <br />
        {firstPaymentDate ? this.headerSection() : this.noKidHeader()}
        <div id="printArea">
          {this.aboutSection()}
          <hr style={{ borderTopStyle: 'dashed', borderTopWidth: 2 }} />
          <br />
          <Grid columns={2} stackable>
            <Grid.Column width={8}>
              {this.rsAddress()}
              {this.bankDetails()}
            </Grid.Column>
            <Grid.Column width={8}>
              {this.guDetails()}
              {this.instruction()}
            </Grid.Column>
          </Grid>
          <br />
          <hr style={{ borderTopStyle: 'dashed', borderTopWidth: 2 }} />
          {this.guarantee()}
          {this.termsAndConditions()}
        </div>
        {this.printBtn()}
      </div>
    )
  }
}

export default DdSummary;
