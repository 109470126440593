import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from 'semantic-ui-react';
import LessonHelper from '../../data/lessons/LessonHelper';
import playIcon from '../../images/portal/play-icon.svg';

// styles
import './MediaContentCard.css';

const MediaContentCard = ({imageSource, onClick}) => {
  return (
    <Button className="thumbnail" onClick={onClick}>
      <div className="stages-media-wrapper">
        <Image
          src={imageSource}
          className="slide-image"
          alt="thumbnail"
          onError={LessonHelper.setDefaultThumbnail}
        />
        <Image
          src={playIcon}
          className="play-icon"
        />
      </div>
    </Button>
  );
}

MediaContentCard.propTypes = {
  imageSource: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default MediaContentCard;
