export default class CookieUtils {
  static getCookies = () => {
    return Object.assign({}, ...document.cookie.split('; ').map(cookie => {
      const name = cookie.split('=')[0];
      const value = cookie.split('=')[1];

      return {[name]: value};
    }));
  }

  static analyticsConsentGiven = () => {
    const cookies = CookieUtils.getCookies();
    return cookies.analytics_consent === 'true';
  }

  static functionalityConsentGiven = () => {
    const cookies = CookieUtils.getCookies();
    return cookies.functionality_consent === 'true';
  }

  static cookiePreferencesSet = () => {
    const cookies = CookieUtils.getCookies();
    return cookies.analytics_consent !== undefined && cookies.functionality_consent !== undefined;
  }

  static saveAnalyticsPreferences = (consent) => {
    const expires = new Date(Date.now() + (86400*1000) * 180).toUTCString();
    if (!consent) {
      CookieUtils.removeExistingGACookies();
      CookieUtils.removeExistingVimeoCookies();
    }

    // If GA tag exists on the page (see index.html)
    // Send the latest preferences to Google
    if (typeof gtag === 'function') {
      const consentText = consent ? 'granted' : 'denied';
      // eslint-disable-next-line no-undef
      gtag('consent', 'update', {
        analytics_storage: consentText
      });
    }

    CookieUtils.setCookie('analytics_consent', consent, expires);
  }

  static saveFunctionalityPreferences = (consent) => {
    const expires = new Date(Date.now() + (86400*1000) * 180).toUTCString();
    CookieUtils.setCookie('functionality_consent', consent, expires)
  }

  static removeExistingGACookies = () => {
    ['_ga', '_gid', '_gat'].forEach(key => CookieUtils.deleteCookie(key));

    const cookies = CookieUtils.getCookies();
    // GA4 generates a cookie with a randome name _ga_<container-id>
    // so this will find and kill it
    Object.entries(cookies).forEach(([key]) => {
      if (key.startsWith('_ga')) {
        CookieUtils.deleteCookie(key);
      }
    });
  }

  static removeExistingVimeoCookies = () => {
    [
      '__qca',
      '__ssid',
      '_abexps',
      '_delighted_web',
      '_fbp',
      '_ga',
      '_gcl_au',
      '_mkto_trk',
      '_uetvid',
      'afUserId',
      'auth_redirect',
      'continuous_play_v3',
      'has_logged_in',
      'is_logged_in',
      'sd_client_id',
      'vimeo',
      'vimeo_gdpr_optin',
      'player',
      'vuid'
    ].forEach(key => CookieUtils.deleteCookie(key));
  }

  static setCookie = (key, value, expires) => {
    document.cookie = `${key}=${value};expires=${expires};path=/`;
  }

  static deleteCookie = (name) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
