import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Image, Label, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import menu from '../../consts/menu';
import KidMenuItem from './KidMenuItem';
import shirt from '../../images/portal/shirt-icon.svg';

class NavMenu extends Component {
  customIcons = { shirt };

  static propTypes = {
    allDetailsComplete: PropTypes.bool.isRequired,
    reservedKidsCount: PropTypes.number,
    unapprovedInstrumentChangesCount: PropTypes.number,
    kids: PropTypes.array,
  }  
  
  static defaultProps = {
    reservedKidsCount: 0,
    unapprovedInstrumentChangesCount: 0,
    kids: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      navIndex: -1
    };
  }

  closeMenu = () => {
    this.setState({
      navIndex: -1
    });
  };

  handleSidebarClick = (e, titleProps) => {
    const { index } = titleProps;
    const { navIndex } = this.state;
    const newIndex = navIndex === index ? -1 : index;

    this.setState({ navIndex: newIndex });
  };

  notificationsObject = () => {
    const { allDetailsComplete, reservedKidsCount, unapprovedInstrumentChangesCount } = this.props

    return ({
      user: allDetailsComplete ? 0 : 1,
      kids: reservedKidsCount + unapprovedInstrumentChangesCount
    })
  };
  
  notificationIcon = key =>
    key && this.notificationsObject()[key] > 0 && (
    <Label color="blue">{this.notificationsObject()[key]}</Label>
  );

  menuLinkIcon = menuItem => (
    menuItem.customIconName
      ? <Image as="img" className="custom-icon" src={this.customIcons[menuItem.customIconName]} ui inline />
      : <Icon name={menuItem.iconName} /> 
  )

  menuLink = menuItem => {
    return (
      <Link
        key={menuItem.text}
        className="item"
        to={{ pathname: menuItem.path }}
        target={menuItem.target || '_self'}
        rel={menuItem.target === '_blank' ? 'noopener noreferrer' : ''}
        onClick={this.closeMenu}
      >
        {this.menuLinkIcon(menuItem)}
        {' '}
        {menuItem.text}
        {this.notificationIcon(menuItem.notificationKey)}
      </Link>
    )
  };

  menuContents = () => {
    const { kids } = this.props;
    const menuItems = [...menu.MENU_ITEMS];
    const root = menuItems.splice(0, 1)[0];

    return (
      <Menu vertical fluid secondary>
        {this.menuLink(root)}
        {kids.map(kid => <KidMenuItem kid={kid} key={`sub-menu-${kid.id}`} />)}
        {menuItems.map(menuItem => this.menuLink(menuItem)) }
      </Menu>
    )
  };

  render() {
    return (
      <React.Fragment>
        {this.menuContents()}
      </React.Fragment>
    )
  }  
}

export default NavMenu;
