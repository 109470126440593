import defaulThumb from '../../images/portal/Default-Thumbnail.png';

class LessonHelper {
  constructor(lessons) {
    this.lessons = lessons;
  }

  static getThumbnailBaseUrl = () => {
    return `https://${process.env.REACT_APP_AWS_THUMBNAIL_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_FOLDER}/`
  }

  static setDefaultThumbnail = (e) => {
    e.target.src = defaulThumb
  }

  // Calculate the key for the video we want, given instrument, stage, and type
  static vimeoPath = (instrument, stage, type) => {
    if (instrument === "") {
      return "overview_vimeo_id"
    }

    if (type === "") {
      return `${instrument}_${stage}_vimeo_id`
    }

    return `${instrument}_${stage}_${type}_vimeo_id`
  }

  getLessonsForLessonSeriesId = (lessonSeriesId) =>{
    return this.lessons.filter(lesson => parseInt(lessonSeriesId, 10) === lesson.attributes.lesson_series_id)
  }

  // Provide instrument as empty string (and preferably all other params too) for the overview video
  // Provide "playalong" for the type if desired, or empty string for non-playalong videos
  // Provide android or ipad for the stage if the instrument is no_instrument
  getLessonVideoIds = (lessonSeriesId, instrument, stage, type) => {
    const lessonsInLessonSeries = this.getLessonsForLessonSeriesId(lessonSeriesId);
    const vimeoKey = LessonHelper.vimeoPath(instrument, stage, type)
    // Return the video Ids for the desired instrument / stage / type from the lessons
    return lessonsInLessonSeries.map(lesson => { return lesson.attributes[vimeoKey] || "" })
  }

  static noInstrumentLessonsAvailable = (lessons) => {
    if (lessons.length === 0) {
      return false;
    }

    const available = (lesson) => lesson.attributes.no_instrument_android_vimeo_id || lesson.attributes.no_instrument_ipad_vimeo_id;
    if (lessons.some(available)) { return true }

    return false;
  }

  static getNewestLessonSeriesId = (lessons) => {
    if (lessons) {
      const lessonsByLiveDate = lessons.concat().sort((a, b) => new Date(b.attributes.live_date) - new Date(a.attributes.live_date))
      return lessonsByLiveDate[0] && lessonsByLiveDate[0].attributes.lesson_series_id.toString()
    }

    return ''
  }
}

export default LessonHelper
