import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class NpsAPI {
  createNPS = (grownUpId, config) => (
    AuthenticatedHTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/grown_ups/${grownUpId}/nps.json`,
      config,
    })
  )
}
export default new NpsAPI();
