import React from 'react';
import { Modal, Form, Button, Checkbox } from 'semantic-ui-react';
import paths from '../../consts/paths';
import CookieUtils from '../../utils/CookiesUtils';

class CookieConsentModal extends React.Component {
  state = {
    analyticsConsentGiven: false,
    functionalityConsentGiven: false,
    open: false,
    onSettings: false,
  }

  componentDidMount() {
    if (CookieUtils.cookiePreferencesSet() || window.location.pathname === paths.COOKIE_POLICY) {
      return
    }

    CookieUtils.removeExistingGACookies();
    CookieUtils.removeExistingVimeoCookies();
    this.setState({ open: true });
  }

  toggleAnalyticsConsent = () => {
    this.setState((oldState) => {
      return { analyticsConsentGiven: !oldState.analyticsConsentGiven }
    })
  }

  toggleFunctionalityConsent = () => {
    this.setState((oldState) => {
      return { functionalityConsentGiven: !oldState.functionalityConsentGiven }
    })
  }


  saveAndClose = () => {
    const { analyticsConsentGiven, functionalityConsentGiven } = this.state;
    CookieUtils.saveAnalyticsPreferences(analyticsConsentGiven);
    CookieUtils.saveFunctionalityPreferences(functionalityConsentGiven);
    this.setState({ open: false }, () => window.location.reload());
  }

  acceptAll = () => {
    CookieUtils.saveAnalyticsPreferences(true);
    CookieUtils.saveFunctionalityPreferences(true);
    this.setState({ open: false }, () => window.location.reload());    
  }

  consentMessage = () => (
    <React.Fragment>
      <p>
        We use cookies on our website to help with your browsing experience.
        More information on our use of cookies can be found in our
        {' '}
        <a href="/cookie-policy" target="_self">cookie policy.</a>
        {' '}
        By clicking Accept All you consent to the use of all cookies on our website
        however you can click Settings to provide a controlled consent.
      </p>
      <div style={{ textAlign: 'end' }}>
        <Button onClick={() => this.setState({ onSettings: true })}>Settings</Button>
        <Button type='submit' positive onClick={this.acceptAll}>Accept All</Button>
      </div>
    </React.Fragment>
  )

  form = () => {
    const { functionalityConsentGiven, analyticsConsentGiven } = this.state;
    const essentialLabel = (
      <p>
        <b>Essential Functional Cookies:</b>
        {' '}
        These cookies are essential to ensure the website works and can not be disabled.
        Using this tool will set a cookie on your device to remember your preferences.
      </p>
    );
    const analyticsLabel = (
      <p>
        <b>Analytics Cookies:</b>
        {' '}
        Click this option to allow cookies that show us how the website
        is being used, which helps us improve it.
      </p>
    );
    const functionalLabel = (
      <p>
        <b>Optional Functional Cookies:</b>
        {' '}
        Click this option to allow cookies that improve your experience when using the website.
      </p>
    );

    return (
      <Form>
        <Form.Field
          control={Checkbox}
          checked
          label={{ children: essentialLabel }}
          disabled
        />
        <Form.Field
          control={Checkbox}
          checked={analyticsConsentGiven}
          label={{ children: analyticsLabel }}
          onChange={this.toggleAnalyticsConsent}
        />
        <Form.Field
          control={Checkbox}
          checked={functionalityConsentGiven}
          label={{ children: functionalLabel }}
          onChange={this.toggleFunctionalityConsent}
        />
        <div style={{ textAlign: 'end' }}>
          <Button type='submit' positive onClick={this.saveAndClose}>Save and Close</Button>
        </div>
      </Form>
    )
  }

  render() {
    const { open, onSettings } = this.state;
    const modalContent = onSettings ? this.form() : this.consentMessage();

    return (
      <Modal open={open} size="small">
        <Modal.Header>Cookie Consent</Modal.Header>
        <Modal.Content>
          { modalContent }
        </Modal.Content>
      </Modal>
    )
  }
}

export default CookieConsentModal;
