import React, { useCallback } from 'react';
import { Button, Checkbox, Segment, Table } from 'semantic-ui-react';
import paths from '../../consts/paths';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';
import DateUtils from '../../utils/DateUtils';
import StringUtils from '../../utils/StringUtils';
import TimetableUtils from '../../utils/TimetableUtils';
import WindowUtils from '../../utils/WindowUtils';
import PricingDisclaimer from '../PricingDisclaimer/PricingDisclaimer';
import Section from './Section';

export default function SummarySection() {
  const {
    kid: {
      kidName,
      kidSchoolYear,
      enrolmentInstrument,
      enrollingAndJoiningWaitlist,
      waitlistInstruments,
      resetKidInstrumentState,
      firstLessonDate,
    },
    timetable: { timetableId, timetableDisplayName, lessonsAreOn, handleEnrol, upcomingConcert },
    payment: {
      regularMonthlyAmount,
      firstPaymentDate,
      firstPaymentAmount,
      firstPaymentCoversPeriod,
      paysForSiblings,
      withSiblingsNextPaymentAmount,
      withSiblingsRegularPaymentAmount,
      directDebitInfoNeeded,
      outstandingBalance,
      withOutstandingBalanceNextPaymentAmount,
      withOutstandingBalanceRegularPaymentAmount,
    },
    page: { tsAndCsAgreed, handleTsAndCsToggle, handleContinueToDirectDebitInfo },
  } = useKidRegistration();

  const scrollToChildAndSchoolInfoSection = useCallback(() => WindowUtils.scrollToAnchor('kid-and-school-info'));
  const scrollToInstrumentChoiceSection = useCallback(() => WindowUtils.scrollToAnchor('instrument-choices'));

  const handleChangeInstrumentChoice = () => {
    resetKidInstrumentState();
    scrollToInstrumentChoiceSection();
  };

  const checkboxLabel = () => (
    <>
      I agree to the Rocksteady
      {' '}
      <span>
        <a href={paths.WWW_TERMS_CONDITIONS} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
      </span>
    </>
  );

  const termsAndConditionsCheckbox = () => (
    <Checkbox label={{ children: checkboxLabel() }} checked={tsAndCsAgreed} onChange={handleTsAndCsToggle} />
  );

  const delayedStartConcert = () => (
    <span>
      <br />
      <br />
      Please Note: All bands are now in final rehearsals for concerts.
      <br />
      <br />
      Your child&rsquo;s first lesson will now be after the concert. They&rsquo;ll learn a new song with their
      band, ready for their own moment to shine on stage next term!
    </span>
  );

  const firstLessonCell = () => (
    <>
      {firstLessonDate ? DateUtils.formatDate(firstLessonDate) : 'TBC'}
      {upcomingConcert && delayedStartConcert()}
    </>
  );

  const instrumentCell = () => {
    let inner;

    if (enrollingAndJoiningWaitlist) {
      inner = (
        <>
          Enrol on
          {' '}
          <strong>{enrolmentInstrument}</strong>
          {' '}
          & join the waitlist to swap to
          {' '}
          <strong>{waitlistInstruments[0]}</strong>
        </>
      );
    } else {
      inner = StringUtils.humanize(enrolmentInstrument);
    }

    return (
      <>
        {inner}
        <span>
          {' '}
          <Button size="mini" primary onClick={handleChangeInstrumentChoice}>Change</Button>
        </span>
      </>
    );
  };

  const tableValuesWithDescriptions = () => ([
    ['School', timetableDisplayName],
    ['Year', TimetableUtils.formattedYear(kidSchoolYear)],
    ['Instrument', instrumentCell()],
    ['Regular Monthly Amount', regularMonthlyAmount],
    ['Lessons day', lessonsAreOn],
    ['First Lesson Date', firstLessonCell()],
    ['First Payment Date', firstPaymentDate ? DateUtils.formatDate(firstPaymentDate) : 'TBC'],
    ['First Payment Amount', firstPaymentAmount || 'TBC'],
    ['First Payment Covers', firstPaymentCoversPeriod || 'TBC'],
  ]);

  const mainTable = () => (
    <Table basic="very" unstackable compact striped columns={2}>
      <Table.Body>
        {tableValuesWithDescriptions().map(([description, value]) => (
          <Table.Row key={description}>
            <Table.Cell width={6}>{description}</Table.Cell>
            <Table.Cell width={10}>{value}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  const paymentsTotalTable = () => {
    // If we have an outstanding balance, use the figures for that as it takes into account enrolled siblings.
    // If not fall back to the sibling pricing.
    const nextPayment = outstandingBalance ? withOutstandingBalanceNextPaymentAmount : withSiblingsNextPaymentAmount;
    const regularPayment = outstandingBalance ? withOutstandingBalanceRegularPaymentAmount : withSiblingsRegularPaymentAmount;
    return (
      <Table basic="very" unstackable compact striped columns={2}>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>Total Next Payment Amount</Table.Cell>
            <Table.Cell width={10}>{nextPayment}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Total Regular Monthly Amount</Table.Cell>
            <Table.Cell width={10}>{regularPayment}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  };

  const actionButton = () => {
    const onClick = directDebitInfoNeeded ? handleContinueToDirectDebitInfo : handleEnrol;
    return (
      <Segment basic>
        {termsAndConditionsCheckbox()}
        <Segment basic textAlign="center">
          <Button
            primary
            disabled={!tsAndCsAgreed}
            onClick={!kidName ? scrollToChildAndSchoolInfoSection : onClick}
          >
            {directDebitInfoNeeded ? 'Continue to Payment' : 'Enrol'}
          </Button>
        </Segment>
      </Segment>
    );
  };

  return(
    <Section id="summary" sectionNumber={3} headingText="summary">
      {mainTable()}
      <PricingDisclaimer timetableId={timetableId} kidName={kidName} otherSubscriptions={paysForSiblings} outstandingBalance={outstandingBalance} />
      {(paysForSiblings || outstandingBalance) && paymentsTotalTable()}
      {actionButton()}
    </Section>
  );
};
