const EVENT = 'purchase';
const AFFILIATION = 'Rocksteady Music School Paid Sign Up';
const ITEM_BRAND = 'Rocksteady';

const constructOnSignUpObject = ({
  kidId, kidSchoolYear, enrolmentInstrument, timetableDisplayName, lessonsAreOn, regularMonthlyAmount,
}) => ({
  event: EVENT,
  ecommerce: {
    transaction_id: kidId,
    value: Number(regularMonthlyAmount && regularMonthlyAmount.slice(1)),
    tax: 0.00,
    shipping: 0.00,
    currency: 'GBP',
    coupon: '',
    school: timetableDisplayName,
    year: kidSchoolYear,
    lessons_day: lessonsAreOn,
    items: [
      {
        item_name: enrolmentInstrument,
        affiliation: AFFILIATION,
        coupon: '',
        discount: 0.00,
        index: 0,
        item_brand: ITEM_BRAND,
        item_category: timetableDisplayName,
        item_category2: kidSchoolYear,
        item_category3: lessonsAreOn,
        item_category4: '',
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: Number(regularMonthlyAmount && regularMonthlyAmount.slice(1)),
        quantity: 1,
      },
    ],
  },
});

export default constructOnSignUpObject;
