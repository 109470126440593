import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { Modal, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import CookiesUtils from '../../utils/CookiesUtils';
import './VideoModal.css';

const videoContent = (videoId, onClose, onRePlay) => (
  <Vimeo
    video={videoId}
    placeholder={null}
    autoplay
    key={videoId}
    className="ui embed"
    onEnd={onClose}
    onPlay={onRePlay}
    dnt={!CookiesUtils.analyticsConsentGiven()}
  />
);

const VideoModal = ({ videoId, onClose, headerText, onRePlay }) => (
  <Modal open onClose={onClose} closeIcon className="video-modal">
    <Modal.Header>
      <Header as="h2" className="page-heading">
        {headerText}
      </Header>  
    </Modal.Header>
    <Modal.Content>
      {videoContent(videoId, onClose, onRePlay)}
    </Modal.Content>
  </Modal>
);

VideoModal.propTypes = {
  videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClose: PropTypes.func.isRequired,
  onRePlay: PropTypes.func,
  headerText: PropTypes.string,
}

VideoModal.defaultProps = {
  headerText: "",
  onRePlay: null,
}

export default VideoModal;
