import React, { Component } from 'react';
import {
  Icon, Modal, Header, Grid, Button
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InstrumentUtils from '../../../utils/InstrumentUtils';
import TimetableUtils from '../../../utils/TimetableUtils';
import './InstrumentChangeModal.scss';

class InstrumentChangeModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    kid: PropTypes.object.isRequired,
    oldTimetable: PropTypes.object.isRequired,
    oldBand: PropTypes.object.isRequired,
    newTimetable: PropTypes.object.isRequired,
    newBand: PropTypes.object.isRequired,
    newInstrument: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    newInstrument: null,
  }

  modalPrompt = () => {
    const { kid } = this.props;

    return (
      <React.Fragment>
        <p>This will change immediately and another child may be allocated to their current space.</p>
        <p>
          <Icon name="child" color="grey" size="large" />
          <b>Kid:&nbsp;</b>
          {kid.attributes.name}
        </p>
      </React.Fragment>
    )
  }

  detailsRow = (label, value) => (
    <Grid.Row>
      <Grid.Column width={6}><b>{`${label}: `}</b></Grid.Column>
      <Grid.Column width={10}><span className="details-cell-content">{value}</span></Grid.Column>
    </Grid.Row>
  )

  yearRange = (from, to) => `${TimetableUtils.formattedYear(from)} - ${TimetableUtils.formattedYear(to)}`

  detailsRows = (timetable, band, instrument) => (
    <Grid className="details-info">
      {this.detailsRow("Lesson Day", timetable.display_day_time_slot_long)}
      {this.detailsRow("Band Leader", timetable.band_leader_name)}
      {this.detailsRow("Band Name", band.name || '-')}
      {this.detailsRow("Year Group", this.yearRange(band.year_from, band.year_to))}                
      {this.detailsRow("Instrument", InstrumentUtils.humanize(instrument))}  
    </Grid>
  )

  detailsBox = () => {
    const { kid, oldTimetable, newTimetable, oldBand, newBand, newInstrument } = this.props;

    return (
      <Grid stackable className="details-box">
        <Grid.Column width={7} floated="left" className="details-column">
          <div className="original-timetable-header detail-header">Original timetable</div>
          {this.detailsRows(oldTimetable, oldBand, kid.attributes.assigned_instrument_type)}
        </Grid.Column>
        <Grid.Column className="mobile only center-column" textAlign="center" verticalAlign="middle">
          <Icon name="angle double down" size="big" />
        </Grid.Column>
        <Grid.Column className="tablet only center-column" textAlign="center" verticalAlign="middle">
          <Icon name="angle double right" size="big" />
        </Grid.Column>
        <Grid.Column className="computer only center-column" textAlign="center" verticalAlign="middle">
          <Icon name="angle double right" size="big" />
        </Grid.Column>
        <Grid.Column width={7} floated="right" className="details-column">
          <div className="new-timetable-header detail-header">New timetable</div>
          {this.detailsRows(newTimetable, newBand, newInstrument)}
        </Grid.Column>
      </Grid>
    )
  }

  extraInfo = () => {
    const { newTimetable, newBand } = this.props;

    return (
      newBand.out_of_hours && (
        <p className="out-of-hours-band-info">
          Please note, the only space on this new instrument is in a band that has their lessons outside of school hours.
          <br />
          Please talk to the school or your Band Leader 
          {newTimetable.band_leader_name ? `, ${newTimetable.band_leader_name} ` : ' '}
          about the specific arrangements in place for this band.
        </p>
      )
    )
  }

  render() {
    const { isOpen, onConfirm, onCancel } = this.props;

    return (
      <Modal
        open={isOpen}
        onClose={onCancel}
        className="instrument-change-modal"
      >
        <Modal.Header>
          <Header as="h2">Change Instrument</Header>  
        </Modal.Header>
        <Modal.Content>
          {this.modalPrompt()}
          {this.detailsBox()}
          {this.extraInfo()}
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Confirm" onClick={onConfirm} />
          <Button basic color="blue" content="Cancel" onClick={onCancel} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default InstrumentChangeModal;
