export default class StringUtils {
  static humanize = (str) => {
    if (str) {
      return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, m => m.toUpperCase());
    }
    return null;
  };

  static parseQueryString = (string) => {
    const query = {};

    if (!string) {
      return ''
    }

    const pairs = (string[0] === '?' ? string.substr(1) : string).split('&');
    for (let i = 0; i < pairs.length; i += 1) {
      const pair = pairs[i].split(/=(.*)/);
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  };

  static passwordIsNotComplexEnough = (string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
    return string.length < 8 || !string.match(regex);
  };

  static isEmailIsh = string => !!string && string.indexOf('.') > -1 && string.indexOf('@') > -1;

  static formatDigitsOnly = value => value.replace(/[^\d]/gi, '');

  static upperCaseFirstLetter = value => value.charAt(0).toUpperCase() + value.slice(1);

  static schoolYearAsString = (year) => {
    const yearIndex = ['R', '0', 0].indexOf(year)

    if (yearIndex >= 0) {
      return 'R';
    }
    return year;
  };

  static nounWithPossessiveS = (noun) => `${noun}${noun.endsWith('s') ? "' " : "'s "}`
}
