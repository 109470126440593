import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Form, Header, Radio, Button
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import StepHeader from './StepHeader';
import paths from '../../consts/paths';
import KidAPI from '../../api/KidAPI';
import DateUtils from "../../utils/DateUtils";
import ErrorMessage from '../ErrorMessage/ErrorMessage';

class Step3 extends Component {
  static propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    refreshAllTheThings: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      dateInput: '',
      cancellationDate: '',
      errors: [],
    };
  }

  handleChangeDate = (event, { value }) => {
    this.setState({ dateInput: value, cancellationDate: 'specific' });
  };

  onChangeCB = (e, { value, checked }) => {
    this.setState({ [value]: checked || undefined });
  };

  saveAndContinue = () => {
    const { dateInput, cancellationDate } = this.state;
    const { id, refreshAllTheThings } = this.props;

    if (cancellationDate === 'immediately') {
      this.setState({ errors: [] }, () => {
        KidAPI.cancelKid(id, {}).then(() => {
          refreshAllTheThings();
          window.location.href = paths.KID(id);
        })
        .catch((error) => {
          const { errors } = error.response.data
          this.setState({ errors })
        });
      });
    } else {
      const params = {
        kid: {
          delayed_cancellation_date: dateInput,
        },
      };
      this.setState({ errors: [] }, () => {
        KidAPI.updateKid(id, params).then(() => {
          refreshAllTheThings();
          window.location.href = paths.KID(id);
        })
        .catch((error) => {
          const { errors } = error.response.data
          this.setState({ errors })
        });
      });
    }
  };

  handleChange = (e, { value }) => {
    this.setState({ cancellationDate: value }, () => {
      if (value === 'immediately') {
        this.setState({ dateInput: '' });
      }
    });
  };

  valid = (cbOne, cbTwo) => {
    const { cancellationDate, dateInput } = this.state;
    if (!cbOne || !cbTwo) {
      return false;
    }
    if (cancellationDate === 'immediately') {
      return true;
    }
    // Reminder: Months are zero-indexed in JS!
    const dateParts = dateInput.split('-').reverse().map((p, i) => i === 1 ? p - 1 : p);
    if (cancellationDate === 'specific' && moment(dateParts).isValid()) {
      return true;
    }
    return false;
  };

  render() {
    const { id, name } = this.props;
    const {
      dateInput, firstCB, secondCB, cancellationDate, errors
    } = this.state;
    const step = localStorage.getItem(`step kid ${id}`);
    return (
      <Form>
        <h1 className="ui centered">
          Cancel&nbsp;
          {name}
        </h1>
        <StepHeader step={step || 3} />
        <ErrorMessage errors={errors} />
        <Header size="medium">When would you like your child&apos;s lessons to finish?</Header>
        <div className="radio-date-cont">
          <Radio
            label="Immediately"
            name="radioGroup"
            value="immediately"
            onChange={this.handleChange}
            checked={cancellationDate === 'immediately'}
          />
          <div className="date-p">
            <Radio
              label="On a specific date"
              name="radioGroup"
              value="specific"
              onChange={this.handleChange}
              className="date-radio"
              checked={cancellationDate === 'specific'}
            />
            <DateInput
              name="date"
              placeholder="Date"
              value={dateInput}
              iconPosition="left"
              onChange={this.handleChangeDate}
              className="date-input"
              minDate={DateUtils.today()}
            />
          </div>
        </div>
        <div className="radio-date-cont">
          <Checkbox
            label="I understand that my
                 child&apos;s place will be
                  released to the waiting list
                   and then online booking once
                    their cancellation is processed"
            className="cb-w-100"
            value="firstCB"
            onChange={this.onChangeCB}
          />
          <Checkbox
            label="Direct Debits are finalised on the 20th
                 day of each month. If you choose to cancel on or
                  before the 20th you won’t be charged any further fees
                   for the place you are cancelling. If you are cancelling
                    after the 20th you will be charged one further
                     monthly subscription. In either case you can opt
                      to cancel immediately or continue Rocksteady lessons
                       up to the last day of the month you have paid for."
            className="cb-w-100"
            value="secondCB"
            onChange={this.onChangeCB}
          />
        </div>
        <Button
          color="red"
          onClick={this.saveAndContinue}
          disabled={!this.valid(firstCB, secondCB)}
        >
          Confirm Cancellation of
          {' '}
          {name}
        </Button>
      </Form>
    );
  }
}
export default Step3;
