import React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from '../components/App';
import AdminApp from '../components/AdminApp';
import SchoolLogin from '../components/SchoolLogin';
import paths from '../consts/paths';
import SchoolsLandingPage from '../components/SchoolsLandingPage';
import RoundUp from '../components/RoundUp/RoundUp';

function AppView() {

  return (
    <div>
      <Switch>
        <Route exact path={paths.SCHOOLS_SIGN_IN} component={SchoolLogin} />
        <Route exact path={paths.SCHOOLS_SIGN_OUT} component={SchoolLogin} />
        <Route exact path={paths.SCHOOLS_FORGOTTEN_PASSWORD} component={SchoolLogin} />
        <Route exact path={paths.SCHOOLS_SET_PASSWORD} component={SchoolLogin} />
        <Route exact path={paths.SCHOOLS_SIGN_OUT} component={SchoolLogin} />
        <Route exact path={paths.SIGN_IN} component={AdminApp} />
        <Route exact path={paths.UNLOCK_ACCOUNT} component={AdminApp} />
        <Route exact path={paths.SIGN_UP} component={AdminApp} />
        <Route exact path={paths.SET_PASSWORD} component={AdminApp} />
        <Route exact path={paths.FORGOTTEN_PASSWORD} component={AdminApp} />
        <Route exact path={paths.SIGN_OUT} component={AdminApp} />
        <Route exact path={paths.COOKIE_POLICY} component={AdminApp} />
        <Route path={paths.SCHOOLS_ROOT} component={SchoolsLandingPage} />
        <Route path={paths.ROUND_UP_DIRECT} component={RoundUp} />
        <Route path={paths.ROOT} component={App} />
      </Switch>
    </div>
  )
}

export default AppView;
