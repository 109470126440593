import React, { useCallback, useMemo } from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import OFFERED_INSTRUMENTS from '../../consts/instruments';
import { useKidRegistration } from '../../contexts/KidRegistrationProvider';
import StringUtils from '../../utils/StringUtils';

export default function InstrumentPreferenceChoices() {
  const {
    kid: {
      waitlistInstruments,
      offerSecondPreference,
      firstPreferenceInstrument,
      firstPreferenceSet,
      setPreferenceInstrument,
      secondPreferenceInstrument,
    },
    consts: { NO_INSTRUMENT_PREFERENCE_VALUE }
  } = useKidRegistration();

  const formattedNoPreferenceString = useMemo(() => StringUtils.humanize(NO_INSTRUMENT_PREFERENCE_VALUE), []);
  const FORMATTED_INSTRUMENT_STRINGS = useMemo(() => (
    OFFERED_INSTRUMENTS.reduce((obj, instrumentName) => ({ ...obj, [instrumentName]: StringUtils.humanize(instrumentName) }), {})
  ), []);

  const NO_PREFERENCE_CHOICE = Object.freeze({ value: NO_INSTRUMENT_PREFERENCE_VALUE, text: formattedNoPreferenceString });
  const noPreferenceChoice = (key) => ({ ...NO_PREFERENCE_CHOICE, key });

  const firstPreferenceOptions = useCallback(() => ([
    noPreferenceChoice('first-pref-0'),
    ...waitlistInstruments
      .map((instrument, i) => ({ key: `first-pref-${i + 1}`, value: instrument, text: FORMATTED_INSTRUMENT_STRINGS[instrument] }))
  ]), [waitlistInstruments]);

  const secondPreferenceOptions = useCallback(() => ([
    noPreferenceChoice('second-pref-0'),
    ...waitlistInstruments
      .filter(instrument => instrument !== firstPreferenceInstrument)
      .map((instrument, i) => ({ key: `second-pref-${i + 1}`, value: instrument, text: FORMATTED_INSTRUMENT_STRINGS[instrument] }))
  ]), [waitlistInstruments, firstPreferenceInstrument]);

  const select = (prefNumber, options, value, label, disabled) => {
    const handlePreferenceSelect = (_e, { value: selectedValue }) => setPreferenceInstrument(prefNumber, selectedValue);

    return (
      <Form.Select
        label={label && StringUtils.humanize(label)}
        compact
        options={options}
        onChange={handlePreferenceSelect}
        value={value}
        disabled={disabled}
      />
    );
  };

  const firstPreferenceSelect = () => (
    select(1, firstPreferenceOptions(), firstPreferenceInstrument, offerSecondPreference ? 'first' : undefined)
  );

  const secondPreferenceSelect = () => (
    select(2, secondPreferenceOptions(), secondPreferenceInstrument, 'second', !firstPreferenceSet)
  );

  return (
    <Segment id="instrument-preferences" basic>
      <Header as="h4">What is your preference?</Header>
      <Form.Group widths="equal">
        {firstPreferenceSelect()}
        {offerSecondPreference && secondPreferenceSelect()}
      </Form.Group>
    </Segment>
  );
};
