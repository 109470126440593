import { Step } from 'semantic-ui-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StepHeader extends Component {
  static propTypes = {
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  renderSwitch = (step) => {
    const array_steps = [];
    switch (parseInt(step, 10)) {
      case 1:
        array_steps[0] = 'active step';
        array_steps[1] = 'disabled step';
        array_steps[2] = 'disabled step';
        return array_steps;
      case 2:
        array_steps[0] = 'completed step';
        array_steps[1] = 'active step';
        array_steps[2] = 'disabled step';
        return array_steps;
      case 3:
        array_steps[0] = 'completed step';
        array_steps[1] = 'completed step';
        array_steps[2] = 'active step';
        return array_steps;
      default:
        return array_steps;
    }
  };

  render() {
    const { step } = this.props;
    const array_steps = this.renderSwitch(step);
    return (
      <Step.Group ordered>
        <Step className={array_steps[0]}>
          <Step.Content>
            <Step.Title>Step 1</Step.Title>
          </Step.Content>
        </Step>

        <Step className={array_steps[1]}>
          <Step.Content>
            <Step.Title>Step 2</Step.Title>
          </Step.Content>
        </Step>

        <Step className={array_steps[2]}>
          <Step.Content>
            <Step.Title>Confirm</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}

export default StepHeader;
