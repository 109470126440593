import React from 'react';
import PropTypes from 'prop-types';
// Styles
import "./BassGuitarMessage.css"

const BassGuitarMessage = ({ showAdditionalContent, additionalContent }) => (
  <div className="guitar-info">
    <p>When you sign up for a guitar space, that could be an electric guitar or a bass guitar depending on availability.</p>
    <p>The electric guitar and the bass guitar both play an important role in the band, and the teacher will demonstrate this in the first lesson.</p>
    {showAdditionalContent && additionalContent}
  </div>
);

BassGuitarMessage.propTypes = {
  showAdditionalContent: PropTypes.bool,
  additionalContent: PropTypes.object,
}

BassGuitarMessage.defaultProps = {
  showAdditionalContent: false,
  additionalContent: null,
}

export default BassGuitarMessage;
