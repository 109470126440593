import HTTPWrapper from '../utils/HTTPWrapper';
import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class TimetableAPI {
  searchTimetables = query => HTTPWrapper.get({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/timetables/find?q=${query}`,
  });

  getPricingInfo = id => HTTPWrapper.get({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/timetables/${id}/pricing_info/`,
  });

  getPricingInfoForGrownUp = (id, params) => AuthenticatedHTTPWrapper.get({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/timetables/${id}/pricing_info_for_current_grown_up.json`,
    config: { params },
  });

  getAvailablePlaces = (id, year) => HTTPWrapper.get({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/places/timetable/${id}/year/${year}`,
  });
}

export default new TimetableAPI();
