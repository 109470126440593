import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class KidAPI {
  updateKid = (id, data) =>
    AuthenticatedHTTPWrapper.patch({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/kids/${id}.json`,
      data
    });

  deleteKid = id =>
    AuthenticatedHTTPWrapper.delete({
      url: `${
        process.env.REACT_APP_ABBEY_ROAD_URL
      }/staff/kids/${id}/destroy_from_portal.json`
    });

  cancelKid = (id, config) =>
    AuthenticatedHTTPWrapper.post({
      url: `${
        process.env.REACT_APP_ABBEY_ROAD_URL
      }/staff/kids/${id}/cancel.json`,
      config
    });

  getSlots = id =>
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/kids/${id}/slots.json`
    });

  getKid = id =>
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/staff/kids/${id}.json`
    });

  getRehearsalsAllDayEvents = id =>
    AuthenticatedHTTPWrapper.get({
      url: `${
        process.env.REACT_APP_ABBEY_ROAD_URL
      }/staff/kids/${id}/calendar.json`
    });

  updateSlotForKid = (id, slotId, data) =>
    AuthenticatedHTTPWrapper.patch({
      url: `${
        process.env.REACT_APP_ABBEY_ROAD_URL
      }/staff/kids/${id}/slots/${slotId}.json`,
      data
    });

  acceptInstrumentChange = (id, slotId, config) =>
    AuthenticatedHTTPWrapper.post({
      url: `${
        process.env.REACT_APP_ABBEY_ROAD_URL
      }/staff/kids/${id}/slots/${slotId}/accept_instrument_change.json`,
      config
    });

  rejectInstrumentChange = (id, slotId, config) =>
    AuthenticatedHTTPWrapper.post({
      url: `${
        process.env.REACT_APP_ABBEY_ROAD_URL
      }/staff/kids/${id}/slots/${slotId}/reject_instrument_change.json`,
      config
    });

  assignKidToBand = (id, config) =>
    AuthenticatedHTTPWrapper.post({
      url: `${
        process.env.REACT_APP_ABBEY_ROAD_URL
      }/staff/kids/${id}/assign_to_band.json`,
      config
    });
}

export default new KidAPI();
