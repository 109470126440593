/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Radio, GridColumn} from 'semantic-ui-react';

class DirectDebitQualifiers extends Component {
  static propTypes = {
    userIsOnlySignatory: PropTypes.string.isRequired,
    accountInUsersName: PropTypes.string.isRequired,
    onAccountInUsersNameChange: PropTypes.func.isRequired,
    onUserIsOnlySignatoryChange: PropTypes.func.isRequired,
  };

  infoBlock = () => {
    const { onAccountInUsersNameChange, onUserIsOnlySignatoryChange, userIsOnlySignatory, accountInUsersName } = this.props;

    return (
      <div>
        <div className="dd-qualifier-form ui form">
          <Grid>
            <Grid.Row columns={8}>
              <Grid.Column width={6} className="required field">
                <label>
                  <b className="checkbox">The account is in my name?</b>
                </label> 
              </Grid.Column>
              <Grid.Column width={3}>
                <Radio
                  label="Yes"
                  value={accountInUsersName}
                  checked={accountInUsersName === 'yes'}
                  onChange={onAccountInUsersNameChange}
                />
              </Grid.Column>
              <GridColumn width={3}>
                <Radio
                  label="No"
                  value={accountInUsersName}
                  checked={accountInUsersName === 'no'}
                  onChange={onAccountInUsersNameChange}
                />
              </GridColumn>
            </Grid.Row>
            <Grid.Row columns={8}>
              <Grid.Column width={6} className="required field">
                <label>
                  <b className="checkbox">I am able to authorise a Direct Debit for this bank account without other signatures?</b>
                </label>
              </Grid.Column>
              <Grid.Column width={3}>
                <Radio
                  label="Yes"
                  value={userIsOnlySignatory}
                  checked={userIsOnlySignatory === 'yes'}
                  onChange={onUserIsOnlySignatoryChange}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Radio
                  label="No"
                  value={userIsOnlySignatory}
                  checked={userIsOnlySignatory === 'no'}
                  onChange={onUserIsOnlySignatoryChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.infoBlock()}
      </div>
    );
  }
}
export default DirectDebitQualifiers;
