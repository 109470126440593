import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import KidView from './KidView';
import StringUtils from '../../utils/StringUtils';
import WindowUtils from '../../utils/WindowUtils';

class KidDetails extends Component {
  static propTypes = {
    kids: PropTypes.instanceOf(Array).isRequired,
    approvedInstrumentChanges: PropTypes.array,
  };

  static defaultProps = {
    approvedInstrumentChanges: [],
  };

  kidView = (kid) => {
    // A kid should normally have a timetable, even cancelled kids, but in case the TT was deleted
    let timetableBLAttributes = {}

    // If the kid has a timetable with band leader, we always show the band leader link, even if there's no profile.
    // In the no profile scenario, AR sends across an empty profile that contains the email address
    // This way, if the profile is missing, the GU can still email the BL.
    if (kid.attributes.timetable_bl && kid.attributes.timetable_bl.data) {
      timetableBLAttributes = kid.attributes.timetable_bl.data.attributes
    }

    return (
      timetableBLAttributes && (
        <KidView
          key={kid.id}
          kidAttributes={kid.attributes}
          id={kid.id}
          changing={this.kidIsChanging(kid.id)}
          timetableAttributes={timetableBLAttributes}
        />
      )
    );
  };

  kidDetails = () => {
    const { kids } = this.props;
    return (
      kids.map((kid) => {
        return this.kidView(kid);
      })
    );
  };

  kidIsChanging = (id) => {
    const { approvedInstrumentChanges } = this.props;
    return approvedInstrumentChanges.some(change => change.kidId === id);
  };

  messages = () => {
    const urlParams = StringUtils.parseQueryString(WindowUtils.anyParams());
    const { newEnrolment, instrumentChanged, instrumentChangeRejected } = urlParams;
    const messages = [];
    if (newEnrolment) {
      messages.push(
        <Message key="enrol-mes" positive>
          Enrolment successful. You should receive a welcome email soon
        </Message>,
      );
    }
    if (instrumentChanged) {
      messages.push(
        <Message key="ic-mes" positive>
          Instrument changed.
        </Message>,
      );
    }
    if (instrumentChangeRejected) {
      messages.push(
        <Message key="ic-reject-mes" positive>
          Thank you. Instrument change has been rejected
        </Message>,
      );
    }
    return messages;
  };

  render() {
    return (
      <div className="child-summaries">
        <h3>
          Your Children
        </h3>
        {this.messages()}
        {this.kidDetails()}
      </div>
    );
  }
}

export default KidDetails;
