import React from 'react';
import { Message, Container } from 'semantic-ui-react';
import SiteFooter from '../SiteFooter/SiteFooter';
import SiteHeader from '../SiteHeader/SiteHeader';
import contacts from '../../consts/contacts';

const SomethingWentWrong = () => (
  <div>
    <SiteHeader />
    <Container className="site-container">
      <Message negative style={{ marginTop: 50 }}>
        <p>
          <strong>We are really sorry but something went wrong.</strong>
        </p>
        <p>
          Sometimes, this is due to using an older phone or computer. If you want to try again on
          another device (phone/computer/tablet) that can often solve the problem.
        </p>
        <p>Alternatively, our team in the office are happy to help with your question.</p>
        <p>
          {`Call ${contacts.SUPPORT_TEL_HUMANIZED} (${contacts.SUPPORT_HOURS}) or email us at `}
          <a href={`mailto:${contacts.SUPPORT_EMAIL}`}>{contacts.SUPPORT_EMAIL}</a>
          .
        </p>
      </Message>
      <a href="/" className="ui primary button">Home</a>
    </Container>
    <SiteFooter />
  </div>
);

export default SomethingWentWrong;
