import React, { Component } from 'react'; 
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import BassGuitarMessage from '../../BassGuitarMessage/BassGuitarMessage';

class WouldDoOptions extends Component {
  static propTypes = {
    onChangingWouldDo: PropTypes.func.isRequired,
    wouldDoOptions: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super();
    this.state = {
      showSpecificGuitarChoices: (props.wouldDoOptions.wouldDoBass !== props.wouldDoOptions.wouldDoGuitar),
    };
  }

  wouldDoOption = ({ name, checked, label } = {}) => {
    const { disabled } = this.props;
    return (
      <Grid.Column mobile={16} tablet={8} computer={8}>
        <Form.Field>
          <Form.Checkbox
            onChange={this.changeWouldDo(name)}
            name={name}
            checked={checked || false}
            label={label}
            disabled={disabled}
            className="would-do-option"
          />
        </Form.Field>
      </Grid.Column>
    );
  }

  changeWouldDo = name => (e, { checked }) => {
    const { onChangingWouldDo } = this.props;
    if (name === 'would_do_guitar_any') {
      onChangingWouldDo('would_do_bass')(e, { checked });
      onChangingWouldDo('would_do_guitar')(e, { checked });
      return;
    }
    onChangingWouldDo(name)(e, { checked });
  }

  guitarChoiceOptions = () => {
    const { wouldDoOptions } = this.props;
    return (
      <>
        {this.guitarChoiceOption({
          name: 'would_do_guitar_any',
          checked: (wouldDoOptions.wouldDoBass && wouldDoOptions.wouldDoGuitar) || false,
          label: 'Any Guitar',
        })}
        {this.guitarChoiceOption({
          name: 'would_do_bass',
          checked: (wouldDoOptions.wouldDoBass === true && wouldDoOptions.wouldDoGuitar === false) || false,
          label: 'Bass Guitar Only',
        })}
        {this.guitarChoiceOption({
          name: 'would_do_guitar',
          checked: (wouldDoOptions.wouldDoBass === false && wouldDoOptions.wouldDoGuitar === true) || false,
          label: 'Electric Guitar Only',
        })}
      </>
    );
  }

  guitarChoiceOption = ({ name, checked, label }) => (
    <Grid.Column mobile={16} tablet={8} computer={8}>
      <Form.Field>
        <Form.Radio
          onChange={this.setGuitarPreferences(name)}
          name={name}
          checked={checked}
          label={label}
          className="would-do-guitar-choice"
        />
      </Form.Field>
    </Grid.Column>
  );

  setGuitarPreferences = preference => (e, { checked }) => {
    const { onChangingWouldDo } = this.props;
    if (preference === 'would_do_bass') {
      onChangingWouldDo('would_do_bass')(e, { checked });
      onChangingWouldDo('would_do_guitar')(e, { checked: false });
    };
    if (preference === 'would_do_guitar') {
      onChangingWouldDo('would_do_bass')(e, { checked: false });
      onChangingWouldDo('would_do_guitar')(e, { checked });
    };
    if (preference === 'would_do_guitar_any') {
      onChangingWouldDo('would_do_bass')(e, { checked: true });
      onChangingWouldDo('would_do_guitar')(e, { checked: true });
    };
  }

  guitarInfoMessage = () => {
    const { disabled, wouldDoOptions } = this.props;
    return (
      !disabled && (wouldDoOptions.wouldDoBass || wouldDoOptions.wouldDoGuitar) && (
      <BassGuitarMessage
        showAdditionalContent={wouldDoOptions.wouldDoBass || wouldDoOptions.wouldDoGuitar}
        additionalContent={this.specificGuitarChoiceContent()}
      />
    ))
  }

  specificGuitarChoiceContent = () => {
    const { showSpecificGuitarChoices } = this.state;
    return (
      <>
        <p>
          If you have a strong preference for electric guitar or bass guitar&nbsp;
          <button type="button" className="button-link" onClick={() => this.setState({ showSpecificGuitarChoices: true })}>
            click here
          </button>
          .
        </p>
        {showSpecificGuitarChoices && this.guitarChoiceOptions()}
      </>
    )
  }

  render() {
    const { wouldDoOptions } = this.props;
    return (
      <Grid.Row>
        {this.wouldDoOption({
          name: 'would_do_drums',
          label: 'Drums',
          checked: wouldDoOptions.wouldDoDrums,
        })}
        {this.wouldDoOption({
          name: 'would_do_guitar_any',
          label: 'Guitar',
          checked: (wouldDoOptions.wouldDoBass || wouldDoOptions.wouldDoGuitar),
        })}
        {this.wouldDoOption({
          name: 'would_do_keyboard',
          label: 'Keyboard',
          checked: wouldDoOptions.wouldDoKeyboard,
        })}
        {this.wouldDoOption({
          name: 'would_do_vocals',
          label: 'Vocals',
          checked: wouldDoOptions.wouldDoVocals,
        })}
        {this.guitarInfoMessage()}
      </Grid.Row>
    );
  }
}

export default WouldDoOptions;
