import React, { Component } from 'react';
import { Segment, Icon, Accordion } from 'semantic-ui-react';
import FaqAPI from '../../api/FaqAPI';
import PageHeading from '../PageHeading/PageHeading';

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      faqs: [],
      activeIndex: 0
    };
  }

  componentDidMount() {
    FaqAPI.getFaq().then(response => {
      this.setState({
        loading: false,
        faqs: response.data
      });
    });
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  faq = () => {
    const { loading, faqs, activeIndex } = this.state;
    if (!loading) {
      return faqs.map(faq => (
        <div key={faq.id}>
          <Accordion.Title
            key={`accordian-key-${faq.id}`}
            index={faq.id}
            active={activeIndex === faq.id}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            {faq.question}
          </Accordion.Title>
          <Accordion.Content
            key={`accordian-content-key-${faq.id}`}
            index={faq.id}
            active={activeIndex === faq.id}
          >
            <div style={{ whiteSpace: 'pre-line' }}>{faq.answer}</div>
          </Accordion.Content>
        </div>
      ));
    }
    return '';
  };

  render() {
    return (
      <div className="page-container">
        <PageHeading heading="Frequently asked questions" />

        <Segment textAlign="left">
          <Accordion fluid styled className="faq-list">
            {this.faq()}
          </Accordion>
        </Segment>
      </div>
    );
  }
}

export default FAQ;
