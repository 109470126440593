import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Dropdown,
  Header,
  TextArea,
  Message
} from 'semantic-ui-react';
import _ from 'lodash';
import StepHeader from './StepHeader';
import getOption from './reasons';
import KidAPI from '../../api/KidAPI';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

class Step1 extends Component {
  options = [
    {
      key: 1,
      text: 'Not having fun',
      value: 'not_having_fun'
    },
    {
      key: 2,
      text: 'Not progressing on their instrument',
      value: 'not_learning_enough'
    },
    {
      key: 3,
      text: 'Cost',
      value: 'cost'
    },
    {
      key: 4,
      text: 'Moving schools',
      value: 'moving_school'
    },
    { key: 5, text: 'Too many missed lessons', value: 'too_many_missed_rocksteady_lessons' },
    { key: 6, text: 'Alternative instrument not available', value: 'instrument_not_available' },
    { key: 7, text: 'Worried about missing other school lessons', value: 'worried_about_missing_other_school_lessons' },
    {
      key: 8,
      text: 'Friend has left the band',
      value: 'friend_left_the_band'
    },
  ];

  shuffledOptions = _.shuffle(this.options);

  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    kid: PropTypes.object.isRequired,
    nextStep: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      reasonMessage: null,
      errors: [],
    };
  }

  saveAndContinue = () => {
    const { id, nextStep } = this.props;
    const { dropdown, textarea } = this.state;

    const params = {
      kid: {
        reason_for_cancelling: dropdown,
        reason_for_cancelling_notes: textarea
      }
    };
    this.setState({ errors: [] }, () => {
      KidAPI.updateKid(id, params).then(
        nextStep(1)
      )
      .catch((error) => {
        const { errors } = error.response.data;
        this.setState({ errors })
      });
    })
  };

  onChangeReason = (event, reasonData) => {
    const { id, kid } = this.props;
    const { timetable_bl: { data: {attributes: { band_leader_id } } } }= kid.attributes;

    this.setState({
      reasonMessage: getOption({ id, reasonData, band_leader_id}),
      dropdown: reasonData.value
    });
  };

  valid = () => {
    const { dropdown } = this.state;
    if (!dropdown) {
      return false;
    }
    return true;
  };

  render() {
    const { id, name } = this.props;
    const step = localStorage.getItem(`step kid ${id}`);
    const { reasonMessage, textarea, errors } = this.state;
    return (
      <Form>
        <h1 className="ui centered">
          Cancel&nbsp;
          {name}
        </h1>
        <StepHeader step={step || 1} />
        <ErrorMessage errors={errors} />
        <p>
          We&apos;re sorry to see you go but before you move on,
          please let us know why below and we will understand.
          We&apos;ll be here for you if you want your child to come back to music lessons with us in the future.
        </p>
        <Header size="medium" className="step1-header">
          What is your reason for cancelling today?
        </Header>
        <Dropdown
          placeholder="Please Select"
          fluid
          search
          selection
          options={this.shuffledOptions}
          onChange={this.onChangeReason}
        />
        {reasonMessage ? <Message positive>{reasonMessage}</Message> : ''}
        <Header size="medium">Could you tell us more about this?</Header>
        <TextArea
          onChange={(e, { value }) => this.setState({ textarea: value })}
          value={textarea}
          maxLength="254"
        />
        <br />
        <br />
        <Button
          onClick={this.saveAndContinue}
          disabled={!this.valid()}
          primary
          type="submit"
        >
          Next Step
        </Button>
      </Form>
    );
  }
}
export default Step1;
