import React, { useEffect, useState } from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import StringUtils from '../../utils/StringUtils';
import TimetableAPI from '../../api/TimetableAPI';

const PricingDisclaimer = ({ kidName, otherSubscriptions, outstandingBalance, timetableId }) => {
  const [expectedNextTTPrice, setExpectedNextTTPrice] = useState();
  const [regularMonthlyCost, setRegularMonthlyCost] = useState();

  useEffect(() => {
    if (timetableId) {
      TimetableAPI.getPricingInfo(timetableId)
        .then(({ data }) => {
          setExpectedNextTTPrice(data.expected_next_tt_price);
          setRegularMonthlyCost(data.regular_monthly_cost);
        });
    }
  }, []);

  const regularIsBelowExpected = () => {
    if (regularMonthlyCost && expectedNextTTPrice) {
    const regularFloat = parseFloat(regularMonthlyCost.replace('£', ''));
    const expectedFloat = parseFloat(expectedNextTTPrice.replace('£', ''));
    return regularFloat < expectedFloat;
    }
    return null;
  };

  const otherSubscriptionsMsg = () =>
    `As you have other children already enrolled with Rocksteady,
  ${StringUtils.nounWithPossessiveS(kidName)}
  subscription will be added to your regular Direct Debit payment (no payment in August), as shown below.`;

  const disclaimerMsg =
    `If  ${StringUtils.nounWithPossessiveS(kidName)} first lesson is later in the month or you have an existing unused credit on your account, this can sometimes mean there is a difference between your next payment and your regular monthly payment.`

  const outstandingBalanceMsg = `There is an outstanding balance of ${outstandingBalance} on your account and this will be added to your next monthly payment. This is included in the price shown below.`

  const priceChangeMsg = 'Prices are subject to change on 1st September, in line with our T&Cs.'

  const siblingContent = () => (
    <>
      {otherSubscriptionsMsg()}
      <br />
      <br />
    </>
  );

  const outstandingBalanceContent = () => (
    <>
      {outstandingBalanceMsg}
      <br />
      <br />
    </>
  )

  const priceChangeContent = () => (
    <>
      {priceChangeMsg}
      <br />
      <br />
    </>
  )

  return (
    <Message info>
      <Message.Content>
        {otherSubscriptions && siblingContent()}
        {outstandingBalance && outstandingBalanceContent()}
        {regularIsBelowExpected() && priceChangeContent()}
        {disclaimerMsg}
      </Message.Content>
    </Message>
  );
};

export default PricingDisclaimer;

PricingDisclaimer.propTypes = {
  kidName: PropTypes.string.isRequired,
  otherSubscriptions: PropTypes.bool.isRequired,
  outstandingBalance: PropTypes.string,
  timetableId: PropTypes.number.isRequired,
}

PricingDisclaimer.defaultProps = {
  outstandingBalance: null,
}
