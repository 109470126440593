import translateToSlide from './RoundUpDataLayout';

export const RoundUpHelper = async (responseData) => {
  if (!responseData) return null;
  const translated = await translateToSlide(responseData);
  return translated;
}

export const RoundUpTrackingSetUp = async (data, grown_up_id, round_up_id) => {
  if (!data) return null;
  const { slide_designators, slides_count } = data;
  const formatted = slide_designators.toString();
  
  const round_up_viewing = {
    grown_up_id,
    round_up_id,
    "slides_count": slides_count,
    "slide_designators": formatted,
    "last_viewed_slide_designator": 'intro',
    "last_viewed_slide_number": 1 // 1 as its not index
  }
  return round_up_viewing;
}


export const RoundUpTrackingUpdate = (slide_designator, slide_number) => {

  const round_up_viewing = {
    "last_viewed_slide_designator": slide_designator,
    "last_viewed_slide_number": slide_number
  }

  return round_up_viewing;
}
