import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Message, Button, Container, Form, Dimmer, Loader, Segment,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import TimetableUtils from '../../utils/TimetableUtils';
import WindowUtils from '../../utils/WindowUtils';
import GrownUpAPI from '../../api/GrownUpAPI';
import TimetableAPI from '../../api/TimetableAPI';
import TimetableSummary from './TimetableSummary/TimetableSummary';
import MessagePrompt from '../MessagePrompt';
import paths from '../../consts/paths';
import PageHeading from '../PageHeading/PageHeading';
import PricingDisclaimer from '../PricingDisclaimer/PricingDisclaimer';
import TotalAmountOnEnrolment from '../TotalAmountOnEnrolment/TotalAmountOnEnrolment';

class EnrolReservedKid extends Component {
  static propTypes = {
    kid: PropTypes.object.isRequired,
    instrument: PropTypes.string.isRequired,
    school: PropTypes.string.isRequired,
    grownUpAttributes: PropTypes.object.isRequired,
    grownUpId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    allDetailsComplete: PropTypes.bool.isRequired,
    refreshAllTheThings: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      enrolmentComplete: false,
      loading: true,
      formIsValid: this.grownUpHasDdInfo(),
      timetable: {},
      continueToPayment: false,
    };
  }

  async componentDidMount() {
    const { kid, school } = this.props;
    const timetableId = kid.relationships.timetable.data.id

    const timetableInfo = await TimetableAPI.getPricingInfoForGrownUp(timetableId, { kid_id: kid.id });
    const poundAmount = kid.attributes.subscription_amount / 100.0;

    const timetable = {
      first_lesson_date: kid.attributes.first_lesson_date,
      first_payment_amount: kid.attributes.first_payment_amount,
      first_payment_date: kid.attributes.first_payment_date,
      first_payment_covers_period: kid.attributes.first_payment_covers_period,
      regular_monthly_cost: `£${poundAmount}`,
      name: school,
      lessons_are_on: timetableInfo.data.timetable_pricing_info.lessons_are_on,
      pricingWithSiblings: timetableInfo.data.pricing_with_enrolled_siblings,
      pricingWithOutstandingBalance: timetableInfo.data.pricing_with_outstanding_balance,
    }
    WindowUtils.scrollToTop();

    this.setState({
      timetable,
      loading: false
    });
  };

  updateAttribute = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  grownUpHasDdInfo = () => {
    const { grownUpAttributes } = this.props;
    const { active_direct_debit_or_dd_info } = grownUpAttributes;

    return active_direct_debit_or_dd_info;
  };

  updateValidity = value => this.setState({ formIsValid: value });

  formIsValid = () => {
    const { formIsValid } = this.state;
    return formIsValid;
  };

  priceBreakdownNeeded = () => {
    const { timetable } = this.state;
    const { pricingWithOutstandingBalance, pricingWithSiblings } = timetable;
    return Boolean(pricingWithOutstandingBalance || pricingWithSiblings);
  }

  totalDDAmount = () => {
    const { timetable } = this.state;
    let pricingToUse;
    // If we get here then we will have either one of the below pricing results.
    // Pricing with outstanding balance includes the amount for siblings so it
    // id okay to use that here when it is present.
    if (timetable.pricingWithOutstandingBalance) {
      pricingToUse = timetable.pricingWithOutstandingBalance;
    } else if (timetable.pricingWithSiblings) {
      pricingToUse = timetable.pricingWithSiblings;
    }
    const { next_payment_amount, regular_monthly_amount } = pricingToUse;

    return (
      <TotalAmountOnEnrolment nextPaymentAmount={next_payment_amount} regularMonthlyAmount={regular_monthly_amount} />
    )
  }

  timetableSummary = () => {
    const { kid, instrument } = this.props
    const { timetable } = this.state

    const { year } = kid.attributes;
    const formattedYear = TimetableUtils.formattedYear(year);

    return (
      <TimetableSummary
        timetable={timetable}
        formattedYear={formattedYear}
        chosenInstrument={instrument}
      />
    );
  };

  handlePaymentDetails = () => {
    this.setState({ continueToPayment: true })
  }

  enrolNow = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.enrolAKid();
      },
    );
  };

  enrolAKid = async () => {
    const { kid, grownUpId, refreshAllTheThings } = this.props;

    const config = {
      data: {
        kid_action: 'enrol',
        kid: {
          as_of_sep: new Date().getFullYear(),
        },
      },
    };

    await GrownUpAPI.enrolReservedKid(kid.id, grownUpId, config);
    WindowUtils.scrollToTop();
    refreshAllTheThings();
    this.setState({ enrolmentComplete: true });
  };

  enrolmentCompleteMessage = () => {
    const { kid } = this.props

    const { name } = kid.attributes;
    return (
      <Message positive>
        <Message.Header>{`Thanks for enrolling ${name}`}</Message.Header>
        <Message.Content>
          You’ll receive an email from us by the end of the next working day with all the
          information you need to get them started.
        </Message.Content>
      </Message>
    );
  };

  payOrEnrolButton = () => {
    if (this.grownUpHasDdInfo()) {
      return (
        <div>
          <Message positive>
            <Message.Content>
              As you have already provided your direct debit details you don’t need to
              provide this again - we will add the new cost shown to your existing
              amount.
            </Message.Content>
          </Message>

          <Form onSubmit={this.enrolNow}>
            <Form.Button positive size="huge">
              Enrol
            </Form.Button>
          </Form>
        </div>
      )
    }

    return (
      <Button positive onClick={this.handlePaymentDetails}>
        Continue to Payment
      </Button>
    )
  }

  render() {
    const { allDetailsComplete, kid } = this.props;
    const { enrolmentComplete, loading, continueToPayment, timetable } = this.state;
    const { name, reserved_until: reservedUntil } = kid.attributes;
    const { pricingWithSiblings } = timetable;
    const { pricingWithOutstandingBalance } = timetable;
    const outstandingBalance = pricingWithOutstandingBalance && pricingWithOutstandingBalance.outstanding_balance;
    const timetableId = parseInt(kid.relationships.timetable.data.id, 10);

    if (enrolmentComplete) {
      return this.enrolmentCompleteMessage();
    }
    if (continueToPayment) {
      const state = { kidParams: { id: kid.id, reservedUntil, name, timetableId: kid.relationships.timetable.data.id } };
      return <Redirect push to={{ pathname: paths.DIRECT_DEBITS, state }} />;
    }
    if (loading) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }
    if (allDetailsComplete) {
      return (
        <Container>
          <PageHeading
            heading={`We have a space available for ${name}`}
            subHeading="Please check details below and complete your enrolment"
          />

          {this.timetableSummary()}
          <PricingDisclaimer timetableId={timetableId} kidName={name} otherSubscriptions={Boolean(pricingWithSiblings)} outstandingBalance={outstandingBalance} />

          {this.priceBreakdownNeeded() && this.totalDDAmount()}
          {this.payOrEnrolButton()}
        </Container>
      );
    }
    return (
      <Segment>
        <MessagePrompt
          linkCopy="Update"
          linkPath={paths.MY_DETAILS}
          heading="We do not currently have all of your details"
          copy="Please complete your profile before continuing"
          icon="id card"
        />
      </Segment>
    );
  }
}

export default EnrolReservedKid;
