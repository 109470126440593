import moment from 'moment';

export default class DateUtils {
  static formatDate = date => moment(date).format('D MMM YYYY');

  static formatDateAsIso = date => moment(date).format('YYYY-MM-DD');

  static today = () => new Date();

  static thirtyDaysFromNow = () => {
    const future = new Date();
    future.setDate(future.getDate() + 30);
    return future;
  };

  // Expects month nums as per calendar month number, rather than indices ala standard javascript
  // e.g., 3 === March.
  static isBetweenMonths = (earlierMonthNum, laterMonthNum, referenceDate = new Date()) => {
    const referenceMonthIndex = referenceDate.getMonth();
    return referenceMonthIndex > (earlierMonthNum - 1) && referenceMonthIndex < (laterMonthNum - 1);
  };
}
